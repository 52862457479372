import axios from 'axios';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ReactComponent as Backarrow } from '../../assests/images/icons/Arrow_rleft.svg';
import Camera from '../../assests/images/icons/camera.png';
import CountdownImg from '../../assests/images/icons/Countdown.png';
import Profile from '../../assests/images/icons/image.png';
import Shape from '../../assests/images/icons/shape.png';
import MainSidebar from '../../Components/MainSidebar';

import weburls from '../../Weburls/weburls';

const Merchantdetails = () => {
  const [merchantsTransactionData, setMerchantsTransactionData] = useState();
  const [merchantsData, setMerchantsData] = useState();
  const sidebarRef = useRef(null);
  const { uuid, merchantsId } = useParams();

  const getMerchantsByClientBy = useCallback(async () => {
    try {
      const response = await axios.get(`${weburls.Get_Transaction_By_Merchants_Id}${uuid}/${merchantsId}/100/1`);
      const mergedTransactions = response?.data?.data.transactions.thisMonth.concat(
        response?.data?.data.transactions.remainingMonths,
      );
      setMerchantsTransactionData(mergedTransactions);
      setMerchantsData(response?.data?.data);
    } catch (err) {
      toast.error(err);
    }
  }, [merchantsId, uuid]);

  useEffect(() => {
    getMerchantsByClientBy();
  }, [getMerchantsByClientBy]);

  const calculateSpendSum = (timeFrame) => {
    const filteredTransactions = merchantsTransactionData?.filter((transaction) => {
      const transactionDate = new Date(transaction.dateTime);
      return transactionDate >= timeFrame;
    });

    const spendSum = filteredTransactions?.reduce((sum, transaction) => {
      return sum + parseFloat(transaction.amount);
    }, 0);

    return spendSum;
  };

  const now = new Date();
  const startOfWeek = new Date(now);
  startOfWeek.setDate(now.getDate() - now.getDay());

  const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);

  const startOfQuarter = new Date(now.getFullYear(), Math.floor(now.getMonth() / 3) * 3, 1);

  const startOfYear = new Date(now.getFullYear(), 0, 1);

  const thisWeekSpend = calculateSpendSum(startOfWeek);
  const thisMonthSpend = calculateSpendSum(startOfMonth);
  const thisQuarterSpend = calculateSpendSum(startOfQuarter);
  const thisYearSpend = calculateSpendSum(startOfYear);
  return (
    <>
 
            <div className="app-main__outer merchant-details-wrapp">
              <div className="row header-row">
                <div className="col-lg-4 col-md-12 top-heading-merchant">
                  <div className="fs-3 bold big-head-new">Merchant details</div>
                  <div className="fst-normal setting_text_color">
                    Group your spending categories to get an more confidence with your expenses
                  </div>
                </div>

                <div className="col-lg-8 col-sm-12 right-section back-to-portal-financial">
                  <div className="setting_background_color right-section">
                    <Link to={`/Merchats/${uuid}`}>
                      <div className="edit-btn-col">
                        <div
                          className="edit-btn"
                          href="#"
                          style={{ width: '300px', height: '60px', marginRight: '32px' }}
                        >
                          <div className="right">
                            <Backarrow />
                          </div>
                          <div className="left">
                            <h4 className="bold">Back to merchants</h4>
                          </div>
                        </div>
                      </div>
                    </Link>
                    <div className="profile-dashboard">
                      <img src={Shape} alt="Mortage_img" style={{ width: '20px', marginLeft: '20px' }} />
                    </div>

                    <div className="profile-dashboard">
                      <div className="profile-img">
                        <img src={Profile} alt="profile" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row merchats-detail-box">
                <div className="col-lg-12">
                  <div className="d-inline-block merchant-wrp ">
                    <img
                      src={merchantsData?.merchantLogo}
                      alt=""
                      style={{
                        width: '100%',
                        borderRadius: '100%',
                      }}
                    />
                    <div className="small-icons-camera">
                      <img src={Camera} alt="camera-icon" />
                    </div>
                  </div>
                  <div className="bold heading mb-2">{merchantsData?.merchantName}</div>
                  <div
                    style={{
                      marginBottom: '10px',
                    }}
                  >
                    <span className="count-number">$150.65</span> | 1 Transaction
                  </div>

                  <div className="button-blck">
                    <div className="icon-tag">
                      <div
                        className="icon-transaction-img  "
                        style={{
                          backgroundColor: merchantsData?.colour,
                        }}
                      >
                        {merchantsData?.catogryLogo}
                      </div>
                      <div className="content">
                        <h6 className="icon-content ">{merchantsData?.catogeryName}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-lg-3 col-md-12 mb-3">
                  <div className="smal-trans bold black">
                    <h5>Your spend history</h5>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-sm-6 mt-3">
                      <div className="your-spend-box">
                        <div className="img-box">
                          <img src={CountdownImg} alt="" className="count-down-img" />
                        </div>
                        <div className="this-week">This week</div>
                        <div className="this-week-number">${thisWeekSpend}</div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-6 mt-3">
                      <div className="your-spend-box">
                        <div className="img-box">
                          <img src={CountdownImg} alt="" className="count-down-img" />
                        </div>
                        <div className="this-week">This month</div>
                        <div className="this-week-number">${thisMonthSpend}</div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-sm-6 mt-3">
                      <div className="your-spend-box">
                        <div className="img-box">
                          <img src={CountdownImg} alt="" className="count-down-img" />
                        </div>
                        <div className="this-week">This quarter</div>
                        <div className="this-week-number">${thisQuarterSpend}</div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-6 mt-3">
                      <div className="your-spend-box">
                        <div className="img-box">
                          <img src={CountdownImg} alt="" className="count-down-img" />
                        </div>
                        <div className="this-week">This year</div>
                        <div className="this-week-number">${thisYearSpend}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-9">
                  <div className="smal-trans bold black">
                    <h5>Recent Transactions</h5>
                  </div>
                  <div className="row table-horizontal">
                    {merchantsTransactionData &&
                      merchantsTransactionData?.length > 0 &&
                      merchantsTransactionData.map((it) => (
                        <div className="col-lg-12 ">
                          <div className="merchant-box mt-3 transactions-table table-transaction merchant-detail-table">
                            <div
                              className="d-flex"
                              style={{
                                width: '20%',
                              }}
                            >
                              <div className="img-box">
                                <img src={it?.merchantLogo} alt="" />
                              </div>
                              <div className="ms-3">
                                <div className="text-merchant bold">{it?.transactionName}</div>
                                <div className="setting_text_color content">{it?.masterCategoryName}</div>
                              </div>
                            </div>
                            <div className="transactions-text bold">
                              {moment(it.dateTime).format('DD/MM/YYYY')} &nbsp;&nbsp; | &nbsp;&nbsp;
                              {moment(it.dateTime).format('hh:mma')}
                            </div>
                            <div className={`transactions-text ${it.status === 'Completed' ? 'done' : 'pending'}`}>
                              {it?.status}
                            </div>
                            <div className="this-week-number light-red-trans">{it?.amount}</div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
    </>
  );
};
export default Merchantdetails;
