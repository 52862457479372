import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ReactComponent as Search } from "../../assests/images/icons/Search.svg";
import { ReactComponent as PLusIcon } from "../../assests/images/plus.svg";
import { ReactComponent as BulkIcon } from "../../assests/images/bulk-icon.svg";
import { ReactComponent as RulesIcon } from "../../assests/images/rules-icon.svg";
import { ReactComponent as SelectAllIcon } from "../../assests/images/icons/selectall.svg";
import PageTitle from "../../Components/PageTitle";
import SectionTitle from "../../Components/SectionTitle.jsx";
import UserImageInfoToggle from "../../Components/UserImageInfoToggle";
import AddTransactionModal from "../../ModalForm/TransactionModals/AddTransactionModal";
import {
  groupTransactionsByDate,
  groupTransactionsByMonth,
  parseYourAccountBalancesData,
} from "../../utilities/helper";
import { TransactionListing } from "./TransactionListing";
import OverlayContainer from "../../Components/OverlayContainer/OverlayContainer";
import { Loading } from "../../Components/Loading/Loading";
import TransactionViewModal from "../../ModalForm/TransactionModals/TransactionViewModal";
import useDebounce from "../../hooks/useDebouce";
import { dateOptions } from "../../utilities/Statics";
import { isEmpty } from "lodash";
import NoDataFound from "../../Components/NoDataFound/index.jsx";
import FilterTags from "../../Components/FilterTags/index.jsx";
import AddTransactionRuleModel from "../../ModalForm/TransactionModals/AddTransactionRuleModel.jsx";
import weburls from "../../Weburls/weburls.jsx";

const Transactions = () => {
  const { uuid } = useParams();
  const token = localStorage.getItem("budget-token");
  const startDateTrans = localStorage.getItem("startDate");
  const [category, setCategory] = useState([]);
  const [icomecat, setIcomCat] = useState([]);
  const [oneOffCategory, setOneOffCategory] = useState([]);
  const [filterByDate, setFilterByDate] = useState("");
  const [isPopupOpenInfo, setPopupOpenInfo] = useState(false);
  const [selectedIncomeCategories, setSelectedIncomeCategories] = useState([]);
  const togglePopupInfo = () => setPopupOpenInfo(!isPopupOpenInfo);
  const [accountData, setAccountData] = useState([]);
  const [currentMonthGrouped, setCurrentMonthGrouped] = useState({});
  const [remainingMonthsGrouped, setRemainingMonthsGrouped] = useState({});
  const [allTransactionData, setAllTransactionData] = useState({});
  const [loading, setLoading] = useState(false);
  const [spliModalData, setSplitModalData] = useState();
  const [totalIncomes, setTotalIncomes] = useState(0);
  const [totalExpense, setTotalExpense] = useState(0);
  const [search, setSearch] = useState("");
  // const debouncedSearch = useDebounce(search, 500);
  const [isSelectionMode, setSelectionMode] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [unPress, setUnpress] = useState(false);
  const [categorizedTransactionCount, setCategorizedTransactionCount] =
    useState(false);
  const [unCategorizedTransactionCount, setUnCategorizedTransactionCount] =
    useState(false);
  const [categorizedFilter, setCategorizedFilter] = useState("");
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const scrollContainerRef = useRef(null);
  const [resetDone, setResetDone] = useState(false);
  const [seeAll, setSeeAll] = useState(false);
  const [seeAllShow, setSeeAllShow] = useState(false);
  const [fetchedMoreTransaction, setFetchedMoreTransactions] = useState(false);
  const [selectedForBulkUpdate, setSelectedForBulkUpdate] = useState({
    Expense: [],
    Income: [],
  });
  const [selectedExpenseCategories, setSelectedExpenseCategories] = useState(
    []
  );
  const [modals, setModals] = useState({
    addTransaction: false,
    viewTransaction: false,
    changePassword: false,
    addTransactionRules: false,
  });
  const [isRulePopupOpen, setIsRulePopupOpen] = useState(false);

  const handleSearch = (e) => {
    setSearch(e.target.value);
    // handleResetStatesAfterChange();
  };

  useEffect(() => {
    searchTransaction();
  }, [search, allTransactionData]);


  const searchTransaction = () => {
    setLoading(true);
    if (search) {
      const filterTransactions = (transactions) => {
        return transactions.filter(
          (transaction) =>
            transaction.transactionName?.toLowerCase().includes(search) ||
            transaction.merchantName?.toLowerCase().includes(search)
        );
      };

      const thisMonthTransactions = filterTransactions(
        allTransactionData?.thisMonth || []
      );
      const remainingMonthsTransactions = filterTransactions(
        allTransactionData?.remainingMonths || []
      );
      const groupedCurrentMonth = groupTransactionsByDate(
        thisMonthTransactions
      );
      const groupedRemainingMonths = groupTransactionsByMonth(
        remainingMonthsTransactions
      );
      setCurrentMonthGrouped(groupedCurrentMonth.data);
      setRemainingMonthsGrouped(groupedRemainingMonths.data);
    } else {
      
      const groupedCurrentMonth = groupTransactionsByDate(
        allTransactionData?.thisMonth || []
      );
      const groupedRemainingMonths = groupTransactionsByMonth(
        allTransactionData?.remainingMonths || []
      );

      setCurrentMonthGrouped(groupedCurrentMonth.data);
      setRemainingMonthsGrouped(groupedRemainingMonths.data);
    }
    setLoading(false);
  };

  const SelectAll = () => {
    if (selectAll) {
      return (
        <span
          className="select-all"
          onClick={() => handleSelectAllForBulkCategoryUpdate(false, true)}
        >
          Unselect all
        </span>
      );
    } else {
      return (
        <span
          className="select-all"
          onClick={() => handleSelectAllForBulkCategoryUpdate(true, false)}
        >
          Select all
        </span>
      );
    }
  };

  const handleSelectAllForBulkCategoryUpdate = (select, unSelect) => {
    const allTransactions = {
      Expense: [...selectedForBulkUpdate.Expense],
      Income: [...selectedForBulkUpdate.Income],
    };
    const data = {
      ...currentMonthGrouped,
      ...remainingMonthsGrouped,
    };
    if (select) {
      Object.values(data).forEach((transactions) => {
        transactions.forEach((transaction) => {
          const type = transaction.amount < 0 ? "Expense" : "Income";
          if (!allTransactions[type].includes(transaction.transactionUuid)) {
            allTransactions[type].push(transaction.transactionUuid);
          }
        });
      });
    } else if (unSelect) {
      setSelectedForBulkUpdate({
        Expense: [],
        Income: [],
      });
      return;
    }
    setSelectedForBulkUpdate(allTransactions);
  };

  const handleResetStatesAfterChange = () => {
    setPage(1);
    setTotalIncomes(0);
    setTotalExpense(0);
    setCurrentMonthGrouped([]);
    setRemainingMonthsGrouped([]);
    setResetDone(true);
  };

  const openModal = (modal, data) => {
    if (modal === "viewTransaction") {
      setSplitModalData(data);
    }
    setModals({ ...modals, [modal]: true });
  };
  const closeModal = (modal) => {
    setModals({ ...modals, [modal]: false });
  };

  const handleDoneWithBulkUpdate = () => {
    setSelectionMode(false);
    setSelectAll(false);
    setSelectedForBulkUpdate({
      Expense: [],
      Income: [],
    });
  };
  const getCategory = useCallback(async () => {
    try {
      const response = await axios.get(`${weburls.Get_All_Categories}${uuid}`, {
        headers: {
          authorization: `Basic ${token}`,
        },
      });
      setCategory(response?.data?.data?.transaction?.Expense);
      setIcomCat(response?.data?.data?.transaction?.INCOME);
      setOneOffCategory(response?.data?.data?.transaction?.ONEOFF);
    } catch (err) {
      toast.error(err);
    }
  }, [uuid]);

  const fetchTransactions = async (page) => {
    setLoading(true);
    const data = {
      expensecategoryIds: [...selectedExpenseCategories],
      incomecategoryIds: [...selectedIncomeCategories],
      dateRageFilter: filterByDate,
    };
    try {
      await axios.get(`${weburls.Get_Latest_Transactions}${uuid}`, {
        headers: {
          authorization: `Basic ${token}`,
        },
      });
    } catch (error) {
      console.log(error);
    }

    try {
      const response = await axios.post(
        `${weburls.Get_Transaction_By_ClientId}${uuid}/100000/1?name=`,
        data,
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );
      const groupedCurrentMonth = groupTransactionsByDate(
        response?.data?.data?.transactions?.thisMonth,
        currentMonthGrouped
      );
      const groupedRemainingMonths = groupTransactionsByMonth(
        response?.data?.data?.transactions?.remainingMonths,
        remainingMonthsGrouped
      );
      const transactionsThisMonth =
        response?.data?.data?.transactions?.thisMonth || [];
      const transactionsRemainingMonths =
        response?.data?.data?.transactions?.remainingMonths || [];
      const allTransactions = [
        ...transactionsThisMonth,
        ...transactionsRemainingMonths,
      ];
      setCurrentMonthGrouped(groupedCurrentMonth.data);
      setRemainingMonthsGrouped(groupedRemainingMonths.data);
      setAllTransactionData(response?.data?.data?.transactions);
      searchTransaction();
      setTotalIncomes(
        totalIncomes +
          groupedCurrentMonth.incomeCount +
          groupedRemainingMonths.incomeCount
      );
      setTotalExpense(
        totalExpense +
          groupedCurrentMonth.expenseCount +
          groupedRemainingMonths.expenseCount
      );
      if (page > 1) {
        setFetchedMoreTransactions(true);
        setSelectAll(false);
      }
      setHasMore(allTransactions > 0);
      setLoading(false);
    } catch (error) {
      toast.error("Something went wrong, please try again.", {
        position: "top-right",
        autoClose: 2000,
      });
      setLoading(false);
      setHasMore(false);
    }
  };
  const getTrasnsactionRileCategorized = useCallback(async () => {
    try {
      await axios.get(`${weburls.Get_Transaction_rule_categorized}${uuid}`, {
        headers: {
          authorization: `Basic ${token}`,
        },
      });
    } catch (err) {
      toast.error(err);
    }
  }, [uuid]);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (scrollContainerRef.current) {
  //       const { scrollTop, scrollHeight, clientHeight } =
  //         scrollContainerRef.current;
  //       if (scrollTop + clientHeight >= scrollHeight - 1) {
  //         setPage((prev) => prev + 1);
  //         fetchTransactions(page + 1);
  //       }
  //     }
  //   };
  //   const container = scrollContainerRef.current;
  //   if (container) {
  //     container.addEventListener("scroll", handleScroll);
  //   }

  //   return () => {
  //     if (container) {
  //       container.removeEventListener("scroll", handleScroll);
  //     }
  //   };
  // }, [loading, hasMore, page]);

  useEffect(() => {
    getCategory();
    fetchCashAccount();
    fetchAllTransactionsCount();
    getTrasnsactionRileCategorized();
  }, [getCategory]);

  useEffect(() => {
    if (resetDone) {
      fetchTransactions(1);
      setResetDone(false);
      setCategorizedFilter("");
    }
  }, [resetDone]);

  useEffect(() => {
    if (
      categorizedFilter === "Categorised" ||
      categorizedFilter === "Uncategorised"
    ) {
      fetchTransactionAfterAnyUpdate(page);
      setSeeAllShow(true);
      setSelectedExpenseCategories([]);
      setSelectedIncomeCategories([]);
    }
  }, [categorizedFilter]);

  useEffect(() => {
    if (seeAll) {
      fetchTransactionAfterAnyUpdate(page);
      setSeeAll(false);
      setSeeAllShow(false);
      setCategorizedFilter("");
    }
  }, [seeAll]);

  useEffect(() => {
    handleResetStatesAfterChange();
  }, [
    // debouncedSearch,
    filterByDate,
    selectedExpenseCategories,
    selectedIncomeCategories,
  ]);

  const fetchCashAccount = useCallback(async () => {
    try {
      const response = await axios.get(`${weburls.Get_Cash_Account}${uuid}`, {
        headers: {
          authorization: `Basic ${token}`,
        },
      });
      if (response?.data) {
        const parsedData = parseYourAccountBalancesData(
          response?.data?.accounts
        );
        setAccountData(parsedData);
      }
    } catch (error) {
      console.error("error", error);
      toast.error("Something went wrong, please try again.", {
        position: "top-right",
        autoClose: 2000,
      });
    }
  }, [uuid]);

  const fetchAllTransactionsCount = async () => {
    try {
      const response = await axios.get(
        `${weburls.Get_ALL_Transactions_Count}${uuid}`,
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );
      setCategorizedTransactionCount(response?.data?.data?.categorized);
      setUnCategorizedTransactionCount(response?.data?.data?.uncategorized);
    } catch (error) {}
  };

  const addTransaction = async (data) => {
    const payload = {
      userUuid: uuid,
      trans_name: data.name,
      trans_date: data.date,
      trans_amount: data.amount,
      accound_id: data.id,
      avatar: data.avatar,
      trans_type: data.transactionType.toUpperCase(),
    };
    try {
      const response = await axios.post(`${weburls.Add_Transaction}`, payload, {
        headers: {
          authorization: `Basic ${token}`,
        },
      });

      if (response?.data.code === 1) {
        toast.success(response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
      }
      if (response?.data.code === 0) {
        toast.error(response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
      }
      fetchTransactionAfterAnyUpdate();
    } catch (error) {
      toast.error("Somthing went wrong...");
    }
  };

  const splitTransaction = async (splits, transactionId, type) => {
    try {
      const response = await axios.post(
        `${weburls.Split_Transaction}${uuid}/${transactionId}/${type}`,
        splits,
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );
      if (response?.data?.code === 1) {
        toast.success(`${response?.data?.message}`);
      }
      fetchTransactionAfterAnyUpdate();
      closeModal("viewTransaction");
    } catch (error) {
      toast.error("Something went wrong while spliting transaction");
    }
  };

  const deleteCustomTransaction = async (id) => {
    try {
      const response = await axios.delete(
        `${weburls.Delete_Custom_Transactions}${uuid}/${id}`,
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );
      if (response?.data?.code === 1) {
        toast.success(`${response?.data?.message}`);
      }
      fetchTransactionAfterAnyUpdate();
      closeModal("viewTransaction");
    } catch (error) {
      toast.error("Something went wrong while spliting transaction");
    }
  };

  const addNewCategory = (data, type) => {
    if (type === "Expense") {
      addNewExpenseCategory(data);
    } else {
      addNewIncomeCategory(data);
    }
  };

  const editCategory = (data, categoryId) => {
    editIncomeExpensesCategory(data, categoryId);
  };

  const deleteCategory = (selectedCategory) => {
    if (selectedCategory.isDefalutCategory) {
      deleteExpenseCategoryAndBudget(selectedCategory);
    } else {
      removeCategoryByUserUuidAndCategoryId(selectedCategory);
    }
  };

  const deleteExpenseCategoryAndBudget = async (selectedCategory) => {
    try {
      const response = await axios.delete(
        `${weburls.Delete_Expense_Category_And_Budget}${uuid}/${selectedCategory.userCategoryId}`,
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );

      if (response?.data.code === 1) {
        toast.success(response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
      }
      if (response?.data.code === 0) {
        toast.error(response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
      }
      getCategory();
      fetchTransactionAfterAnyUpdate(page);
    } catch (error) {
      toast.error("Somthing went wrong...");
    }
  };

  const removeCategoryByUserUuidAndCategoryId = async (selectedCategory) => {
    try {
      const response = await axios.post(
        `${weburls.Remove_Category_By_UserUuid_And_CategoryId}${uuid}/${selectedCategory.userCategoryId}`,
        {},
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );

      if (response?.data.code === 1) {
        toast.success(response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
      }
      if (response?.data.code === 0) {
        toast.error(response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
      }
      getCategory();
      fetchTransactionAfterAnyUpdate(page);
    } catch (error) {
      toast.error("Somthing went wrong...");
    }
  };

  const editIncomeExpensesCategory = async (data, categoryId) => {
    try {
      const response = await axios.post(
        `${weburls.Edit_Category}${uuid}/${categoryId}`,
        data,
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );
      if (response?.data?.code === 1) {
        toast.success(`${response?.data?.message}`);
      }
      if (response?.data?.code === 0) {
        toast.error(`${response?.data?.message}`);
      }
      getCategory();
    } catch (error) {
      toast.error("Somthing went wronge while edit category");
    }
  };

  const addNewExpenseCategory = async (data) => {
    try {
      const response = await axios.post(
        `${weburls.Add_Expense_Category}${uuid}`,
        data,
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );
      if (response?.data?.code === 1) {
        toast.success(`${response?.data?.message}`);
      }
      if (response?.data?.code === 0) {
        toast.error(`${response?.data?.message}`);
      }
      getCategory();
    } catch (error) {
      toast.error("Somthing went wronge while adding expense category");
    }
  };

  const addNewIncomeCategory = async (data) => {
    try {
      const response = await axios.post(
        `${weburls.Add_Income_Category}${uuid}`,
        data,
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );
      if (response?.data?.code === 1) {
        toast.success(`${response?.data?.message}`);
      }
      if (response?.data?.code === 0) {
        toast.error(`${response?.data?.message}`);
      }
      getCategory();
    } catch (error) {
      toast.error("Somthing went wronge while adding expense category");
    }
  };

  const updateTransactionCategory = async (transactionId, categoryId, type) => {
    const data = {
      transactionUuid: transactionId,
      categoryId: categoryId,
      type: type,
    };
    try {
      const response = await axios.post(
        `${weburls.Update_Category}${uuid}`,
        data,
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );
      toast.success(`${response?.data?.message}`);
      fetchTransactionAfterAnyUpdate(page);
    } catch (error) {}
  };

  const updateSingleCategory = async (categoryData) => {
    const data = {
      transactionUuid: categoryData.transactionId,
      categoryId: categoryData.userCategoryId,
      type: categoryData.type,
    };
    try {
      const response = await axios.post(
        `${weburls.Update_Category}${uuid}`,
        data,
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );
      toast.success(`${response?.data?.message}`);
      setSplitModalData((prev) => ({
        ...prev,
        masterCategoryColour: categoryData.colour,
        masterCategoryEmoji: categoryData.emoji,
        masterCategoryName: categoryData.name,
      }));
      fetchTransactionAfterAnyUpdate(page);
    } catch (error) {}
  };

  const splitbackToMainTransaction = async (transactionUUid) => {
    try {
      const response = await axios.post(
        `${weburls.Split_Back_To_Main_Transaction}${uuid}/${transactionUUid}`,
        {},
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );
      toast.success(`${response?.data?.message}`);
      closeModal("viewTransaction");
      fetchTransactionAfterAnyUpdate(page);
    } catch (error) {}
  };

  const splitSingleBackToMainTransaction = async (transactionUUid) => {
    try {
      const response = await axios.post(
        `${weburls.Split_Single_Back_To_Main_Transaction}${uuid}/${transactionUUid}`,
        {},
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );
      toast.success(`${response?.data?.message}`);
      closeModal("viewTransaction");
      fetchTransactionAfterAnyUpdate(page);
    } catch (error) {}
  };

  const fetchTransactionAfterAnyUpdate = async () => {
    setLoading(true);
    let data = {
      expensecategoryIds: [...selectedExpenseCategories],
      incomecategoryIds: [...selectedIncomeCategories],
      dateRageFilter: filterByDate,
    };
    if (
      categorizedFilter === "Categorised" ||
      categorizedFilter === "Uncategorised"
    ) {
      data = {
        expensecategoryIds: [],
        incomecategoryIds: [],
        dateRageFilter: "",
        categoryFilter: categorizedFilter,
      };
    }
    try {
      const response = await axios.post(
        `${weburls.Get_Transaction_By_ClientId}${uuid}/${
          totalExpense + totalIncomes
        }/${1}?name=`,
        data,
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );
      const groupedCurrentMonth = groupTransactionsByDate(
        response?.data?.data?.transactions?.thisMonth
      );
      const groupedRemainingMonths = groupTransactionsByMonth(
        response?.data?.data?.transactions?.remainingMonths
      );
      setCurrentMonthGrouped(groupedCurrentMonth.data);
      setRemainingMonthsGrouped(groupedRemainingMonths.data);
      setAllTransactionData(response?.data?.data?.transactions);
      searchTransaction();
      setLoading(false);
    } catch (error) {
      toast.error("Something went wrong, please try again.", {
        position: "top-right",
        autoClose: 2000,
      });
      setLoading(false);
      setHasMore(false);
    }
  };

  const updateCategoriesInBulk = async (categoryId, type) => {
    setLoading(true);
    const data = {
      expenseTransactionUuids: [...selectedForBulkUpdate.Expense],
      incomeTransactionUuids: [...selectedForBulkUpdate.Income],
      categoryId: categoryId,
      type: type,
    };
    try {
      const response = await axios.post(
        `${weburls.Update_Category_In_Bulk}${uuid}`,
        data,
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );
      if (response?.data.code === 1) {
        toast.success(`${response?.data?.message}`);
      }
      fetchTransactionAfterAnyUpdate();
      setSelectedForBulkUpdate({
        Expense: [],
        Income: [],
      });
      setSelectionMode(false);
      setSelectAll(false);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleTagClick = (id, type) => {
    if (type === "expense") {
      setSeeAllShow(false);
      setSelectedExpenseCategories((prev) => {
        const newSelection = prev.includes(id)
          ? prev.filter((tag) => tag !== id) // Deselect category
          : [...prev, id]; // Select category

        // Ensure [0] if no selection remains
        return newSelection.length > 0 ? newSelection : [0];
      });

      // Only clear income categories if no income categories are selected AND it's the first expense selection
      if (
        (selectedIncomeCategories.length === 0 ||
          selectedIncomeCategories[0] === 0) &&
        selectedExpenseCategories.length === 0
      ) {
        setSelectedIncomeCategories([0]); // Clear income categories only on the first expense selection
      }
    } else if (type === "income") {
      setSeeAllShow(false);
      setSelectedIncomeCategories((prev) => {
        const newSelection = prev.includes(id)
          ? prev.filter((tag) => tag !== id) // Deselect category
          : [...prev, id]; // Select category

        // Ensure [0] if no selection remains
        return newSelection.length > 0 ? newSelection : [0];
      });

      // Only clear expense categories if no expense categories are selected AND it's the first income selection
      if (
        (selectedExpenseCategories.length === 0 ||
          selectedExpenseCategories[0] === 0) &&
        selectedIncomeCategories.length === 0
      ) {
        setSelectedExpenseCategories([0]); // Clear expense categories only on the first income selection
      }
    }
  };

  const loadAllAkahuTransactions = async (startDate) => {
    try {
      const response = await axios.post(
        `${weburls.Akahu_All_Transactions_Load}${uuid}`,
        { startDate: startDate },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data && response.data.data.length > 0) {
        const newStartDate = response.data.startDate;
        localStorage.setItem('startDate', response.data.startDate);
        loadAllAkahuTransactions(newStartDate);
      } 
    } catch (error) {
      console.error("Error fetching transactions:", error);
    }
  };

  useEffect(() => {
    loadAllAkahuTransactions(startDateTrans);
  }, []);

  return (
    <>
      <div
        className="app-main__outer your-merchants-main-wrapper"
        ref={scrollContainerRef}
      >
        <div className="row">
          <PageTitle
            title={"Your Transactions"}
            description={"Here’s all of your transactions from all accounts"}
          />
          <UserImageInfoToggle />
        </div>
        <div className="d-flex gap-4 mt-5">
          <div className="account-detail-left">
            <div className="d-flex align-items-center justify-content-between">
              <SectionTitle
                title={
                  isSelectionMode
                    ? `${
                        selectedForBulkUpdate.Expense.length +
                        selectedForBulkUpdate.Income.length
                      } Selected`
                    : "Transactions"
                }
              />
              <div className="d-flex gap-1">
                {!isSelectionMode ? (
                  <>
                    {seeAllShow && (
                      <span
                        className="see-all cursor-pointer"
                        onClick={() => setSeeAll(true)}
                      >
                        See all
                      </span>
                    )}

                    <span
                      className="transaction-count transaction-count-green d-flex align-items-center justify-content-center"
                      onClick={() => setCategorizedFilter("Categorised")}
                    >
                      {categorizedTransactionCount}
                    </span>
                    <span
                      className="transaction-count transaction-count-red  d-flex align-items-center justify-content-center"
                      onClick={() => setCategorizedFilter("Uncategorised")}
                      style={{ marginRight: "10px" }}
                    >
                      {unCategorizedTransactionCount}
                    </span>
                    <span
                      onClick={() => setSelectionMode(true)}
                      className="cursor-pointer"
                      style={{ marginRight: "5px" }}
                    >
                      <BulkIcon />
                    </span>
                    <span
                      onClick={() => openModal("addTransaction")}
                      className="cursor-pointer"
                      style={{ marginRight: "5px" }}
                    >
                      <PLusIcon />
                    </span>
                    <span
                      onClick={() => openModal("addTransactionRules")}
                      className="cursor-pointer"
                      style={{ marginRight: "5px" }}
                    >
                      <RulesIcon />
                    </span>
                  </>
                ) : (
                  <div className="selection-flex">
                    <div
                      className="d-flex align-items-center gap-1 cursor-pointer"
                      onClick={() => setSelectAll(!selectAll)}
                    >
                      <SelectAllIcon />
                      <SelectAll />
                      {/* <span className="select-all">Select all</span> */}
                    </div>
                    <span
                      className="select-done cursor-pointer"
                      onClick={handleDoneWithBulkUpdate}
                    >
                      Done
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className="account-detail-left-wrapper">
              {!isEmpty(currentMonthGrouped) ||
              !isEmpty(remainingMonthsGrouped) ? (
                <TransactionListing
                  data={{
                    ...currentMonthGrouped,
                    ...remainingMonthsGrouped,
                  }}
                  modalOpen={openModal}
                  updateTransactionCategory={updateTransactionCategory}
                  addNewCategory={addNewCategory}
                  editCategory={editCategory}
                  expenseCategories={category}
                  incomeCategories={icomecat}
                  setSelectionMode={setSelectionMode}
                  oneOffCategory={oneOffCategory}
                  isSelectionMode={isSelectionMode}
                  setSelectedForBulkUpdate={setSelectedForBulkUpdate}
                  selectedForBulkUpdate={selectedForBulkUpdate}
                  updateCategoriesInBulk={updateCategoriesInBulk}
                  selectAll={selectAll}
                  unPress={unPress}
                  deleteCategoryConfirm={deleteCategory}
                />
              ) : (
                <NoDataFound />
              )}
            </div>
          </div>
          <div className="account-detail-right sticky-sidebar">
            <div
              className="
                 search-spacing filter-merchants-wrp-side"
            >
              <div className="row">
                <div className="col-md-7 col-lg-12">
                  <SectionTitle title={"Search for transactions"} />

                  <div>
                    <div className="search-merchant your-merchant-search">
                      <form>
                        <input
                          type="text"
                          value={search}
                          className="search-box-input py-2 inter add-goal full-width-transaction"
                          onChange={(e) => handleSearch(e)}
                        />
                        <Search className="search-merchant" />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between mt-3 search-mb">
                <SectionTitle title={"Filter transactions"} />
              </div>

              <div className="merchants-background-color">
                <FilterTags
                  title={"Expense categories"}
                  categories={category}
                  selectedCategories={selectedExpenseCategories}
                  setSelectedCategories={setSelectedExpenseCategories}
                  handleTagClick={handleTagClick}
                  type={"expense"}
                  oneOffCategory={oneOffCategory}
                />

                <FilterTags
                  title={"Income categories"}
                  categories={icomecat}
                  selectedCategories={selectedIncomeCategories}
                  setSelectedCategories={setSelectedIncomeCategories}
                  handleTagClick={handleTagClick}
                  type={"income"}
                  oneOffCategory={[]}
                />

                <div className="bold live-connection mt-3">Date range</div>
                <div className="account-date-group green-btn">
                  {dateOptions.map((option) => (
                    <button
                      key={option}
                      className={`account-date-btn ${
                        filterByDate === option ? "green" : ""
                      }`}
                      onClick={() =>
                        setFilterByDate(filterByDate === option ? "" : option)
                      }
                    >
                      {option}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {modals.addTransaction && (
        <AddTransactionModal
          modalOpen={modals.addTransaction}
          closeModal={closeModal}
          addTransaction={addTransaction}
          accountData={accountData}
        />
      )}
      {modals.addTransactionRules && (
        <AddTransactionRuleModel
          modalOpen={modals.addTransactionRules}
          closeModal={closeModal}
          expenseCategories={category}
          incomeCategories={icomecat}
        />
      )}
      {modals.viewTransaction && (
        <TransactionViewModal
          data={spliModalData}
          modalOpen={modals.viewTransaction}
          closeModal={closeModal}
          addTransaction={addTransaction}
          accountData={accountData}
          splitTransaction={splitTransaction}
          expenseCategories={category}
          incomeCategories={icomecat}
          oneOffCategory={oneOffCategory}
          updateSingleCategory={updateSingleCategory}
          deleteCustomTransaction={deleteCustomTransaction}
          splitbackToMainTransaction={splitbackToMainTransaction}
          splitSingleBackToMainTransaction={splitSingleBackToMainTransaction}
        />
      )}
      {loading && (
        <OverlayContainer>
          <div>
            <Loading />
          </div>
        </OverlayContainer>
      )}
    </>
  );
};

export default Transactions;
