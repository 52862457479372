import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as yup from 'yup';
import { ReactComponent as Upload } from '../../assests/images/icons/upload.svg';
import Visa from '../../assests/images/icons/visa.png';
import Header from '../../Components/Header/Header';
import Sidebar from '../../Components/Sidebar/Sidebar';
import weburls from '../../Weburls/weburls';
import Settingadvisor from './Settingadvisor';
const Settings = () => {
  const [avatar, setAvatar] = useState();
  const [CompanyLogo, setCompanyLogo] = useState();
  const id = JSON.parse(localStorage.getItem('userData'));
  const user_id = JSON.parse(localStorage.getItem('userData'));
  const [edit, setEdit] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    userUuid: user_id.user_uuid,
    advisorId: id.advisor_id,
    companyWesbite: '',
    companyName: '',
  });
  const schema = yup.object().shape({
    firstName: yup.string().min(3, '*Required').required('*Required'),
    lastName: yup.string().required('*Required'),
    phoneNumber: yup.string().required('*Required'),
    email: yup.string().email('*Please enter a valid email').required('*Required'),
    groupName: yup.string().required('*Required'),
    selectPlan: yup.string().min(3, '*Required').required('*Required'),
    billingFrequency: yup.string().required('*Required'),
    billTo: yup.string().required('*Required'),
    advisorName: yup.string().required('*Required'),
    advisorName2: yup.string().required('*Required'),
  });
  const {
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEdit((prev) => ({ ...prev, [name]: value }));
  };

  const settingApi = useCallback(async () => {
    const response = await axios.get(weburls.Advisor_Details + `${id.userUuid}`);
    if (response?.data) {
      setEdit(response?.data);
    } else {
      toast.error(response?.data?.errors?.detail || 'No Role Found', {
        position: 'top-right',
        autoClose: 2000,
      });
    }
  }, [id.userUuid]);

  const submitAdvisor = async (event) => {
    event.preventDefault();
    const updatedData = {
      ...edit,
      firstName: event.target.firstName.value,
      lastName: event.target.lastName.value,
      email: event.target.email.value,
      phoneNumber: event.target.phoneNumber.value,
      companyWebsite: event.target.companyWebsite.value,
      companyName: event.target.companyName.value,
      avatar,
    };
    const formData = new FormData();
    formData.append('firstName', updatedData.firstName);
    formData.append('lastName', updatedData.lastName);
    formData.append('email', updatedData.email);
    formData.append('phoneNumber', updatedData.phoneNumber);
    formData.append('companyWebsite', updatedData.companyWebsite);
    formData.append('companyName', updatedData.companyName);
    formData.append('avatar', avatar);
    formData.append('advisorId', updatedData.advisorId);
    formData.append('roleId', updatedData.roleId);
    formData.append('roleName', updatedData.roleName);
    formData.append('userId', updatedData.userId);
    formData.append('userUuid', updatedData.userUuid);
    formData.append('companyLogo', CompanyLogo);

    const update = await axios.put(weburls.Advisor_Details_Update, formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // Make sure to set this content type for file uploads
      },
    });
    if (update.status === 200) {
      settingApi();
      toast.success(update.data.message, {
        position: 'top-right',
        autoClose: 1000, // Auto close after 5 seconds
      });
    } else {
      toast.error('Error in updating', {
        position: 'top-right',
        autoClose: 1000,
      });
    }
  };

  const upload = (file) => {
    setAvatar(file.target.files[0]);
  };

  const handleChangeCompanyLogo = (file) => {
    setCompanyLogo(file.target.files[0]);
  };
  useEffect(() => {
    settingApi();
  }, [settingApi]);
  return (
    <>
      <div className="container-fluid">
        <Header edit={edit} />
        <div className="appmain">
          <Sidebar />
          <div className="app-main__outer main-page-details-settings">
            <ToastContainer position="top-right" autoClose={5000} closeOnClick />
            <form onSubmit={submitAdvisor}>
              <div className="setting_background_color">
                <div className="d-flex justify-content-between">
                  <div className="">
                    <h2 className="bold black big-top-heading">Your details & settings</h2>
                    <p className="setting_text_color">Edit your personal and company details & settings.</p>
                  </div>
                  <div
                    className="super-advice"
                    style={{
                      width: '130px',
                      height: '90px',
                      overflow: 'hidden',
                    }}
                  >
                    <img
                      src={
                        edit.companyLogo === null || edit.companyLogo === ''
                          ? 'https://i.pinimg.com/736x/71/b3/e4/71b3e4159892bb319292ab3b76900930.jpg'
                          : `${edit.companyLogo}`
                      }
                      alt="advice"
                      className="p-2"
                      style={{
                        objectFit: 'contain',
                      }}
                    />
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-md-5 col-sm-12 ">
                    <h5 className="bold black heading-new">Your contact details </h5>
                    <div className="setting_text_color">This is who to contact about their plan</div>
                  </div>
                  <div className="col-md-12 col-xl-7 col-sm-12 your-contact-details-side">
                    <div className="row">
                      <div className="col-md-12 col-xl-6">
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            class="form-control input_border"
                            placeholder="First name"
                            defaultValue={edit?.firstName}
                            onChange={() => handleChange}
                            {...register('firstName')}
                            name="firstName"
                          />
                          <p className="error-message">{errors.firstName?.message}</p>
                        </div>
                      </div>
                      <div className="col-md-12 col-xl-6">
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            class="form-control input_border"
                            placeholder="Last name"
                            defaultValue={edit?.lastName}
                            onChange={() => handleChange}
                            name="lastName"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-12 col-xl-7">
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            class="form-control input_border "
                            placeholder="Email"
                            defaultValue={edit?.email ?? ''}
                            onChange={() => handleChange}
                            name="email"
                          />
                        </div>
                      </div>
                      <div className="col-md-12 col-xl-5">
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            class="form-control input_border "
                            placeholder="Phone number"
                            defaultValue={edit?.phoneNumber ?? ''}
                            onChange={() => handleChange}
                            name="phoneNumber"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2 profile-div-wrp" style={{ alignItems: 'center' }}>
                      <div className="col-md-10 col-xl-10 profile-image-side">
                        <div class="input-group">
                          <label id="file-input-label" htmlFor="file-input" class="form-control input_border ">
                            Profile image
                            <input
                              type="file"
                              class="form-control input_border "
                              placeholder="Profile Image"
                              id="file-format"
                              name="file-input"
                              onChange={upload}
                            />
                            <div className="upload">
                              <Upload className="upload-logo" />
                            </div>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 profile-logo-side">
                        <div className="profile-img settings-logos-main">
                          <img
                            src={
                              edit.avatar === null || edit.avatar === ''
                                ? 'https://i.pinimg.com/736x/71/b3/e4/71b3e4159892bb319292ab3b76900930.jpg'
                                : `${edit.avatar}`
                            }
                            alt="profile"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-md-5 col-sm-12">
                    <h5 className="bold black heading-new">Company details</h5>
                    <div className="setting_text_color">Enter in the details of the group</div>
                  </div>
                  <div className="col-md-12 col-xl-7 col-sm-12 your-contact-details-side">
                    <div className="row mt-2">
                      <div className="col-xl-7 col-md-12">
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            class="form-control input_border"
                            placeholder="Company website"
                            defaultValue={edit?.companyWebsite ?? ''}
                            name="companyWebsite"
                            onChange={() => handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-xl-5 col-md-12">
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            class="form-control input_border "
                            placeholder="Company name"
                            name="companyName"
                            defaultValue={edit?.companyName ?? ''}
                            onChange={() => handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2 profile-div-wrp" style={{ alignItems: 'center' }}>
                      <div className="col-md-10 profile-image-side">
                        <div class="input-group">
                          <label id="file-input-label" htmlFor="file-input" class="form-control input_border ">
                            Select a File
                            <input
                              type="file"
                              class="form-control input_border "
                              placeholder="Profile Image"
                              id="file-format"
                              name="file-input"
                              onChange={handleChangeCompanyLogo}
                            />
                            <div className="upload">
                              <Upload className="upload-logo" />
                            </div>
                          </label>
                        </div>
                      </div>
                      <div
                        className="col-md-2"
                        style={{
                          width: '110px',
                          height: '38px',
                          overflow: 'hidden',
                        }}
                      >
                        <div className="">
                          <img
                            src={
                              edit.companyLogo === null || edit.companyLogo === ''
                                ? 'https://i.pinimg.com/736x/71/b3/e4/71b3e4159892bb319292ab3b76900930.jpg'
                                : `${edit.companyLogo}`
                            }
                            alt="profile"
                            style={{
                              objectFit: 'contain',
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-md-5 col-sm-12">
                    <h5 className="bold black heading-new">Plan details</h5>
                    <div className="setting_text_color">Enter in the type of plan the group is using</div>
                  </div>
                  <div className="col-md-12 col-xl-7 col-sm-12 mb-5 plan-details">
                    <div className="row mt-2">
                      <div className="col-xl-6 col-md-12 mb-3 ">
                        <select class="form-select input_border ">
                          <option selected>Select a plan</option>
                        </select>
                      </div>
                      <div className="col-xl-6 col-md-12 mb-3">
                        <select class="form-select input_border">
                          <option selected> Billing Frequency</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-md-5 col-sm-12 ">
                    <h5 className="bold black heading-new">Your payment method</h5>
                    <div className="setting_text_color">Enter in the details of the group</div>
                  </div>
                  <div className="col-xl-7 col-md-12 col-sm-12 your-payment-methods-wrp your-contact-details-side">
                    <div>
                      <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                        <li class="nav-item" role="presentation">
                          <button
                            class="nav-link active"
                            id="pills-home-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-home"
                            type="button"
                            role="tab"
                            aria-controls="pills-home"
                            aria-selected="true"
                          >
                            Credit card
                          </button>
                        </li>
                        <li class="nav-item" role="presentation">
                          <button
                            class="nav-link"
                            id="pills-profile-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-profile"
                            type="button"
                            role="tab"
                            aria-controls="pills-profile"
                            aria-selected="false"
                          >
                            Invoice
                          </button>
                        </li>
                      </ul>
                    </div>
                    <div className="row mt-2 profile-div-wrp" style={{ alignItems: 'center' }}>
                      <div className="col-xl-4 col-md-12 profile-image-side">
                        <div class="input-group">
                          <input type="text" class="form-control input_border " placeholder="Name on card" />
                        </div>
                      </div>
                      <div className="col-xl-6 col-md-10 profile-image-side">
                        <div class="input-group">
                          <input type="text" class="form-control input_border " placeholder="Card number" />
                        </div>
                      </div>
                      <div className="col-md-2 profile-logo-side">
                        <div className="profile-img setting-upld">
                          <img src={Visa} alt="visa" />
                        </div>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-md-7">
                        <div class="input-group mb-3">
                          <input type="text" class="form-control input_border " placeholder="Expiry date" />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div class="input-group mb-3">
                          <input type="text" class="form-control input_border " placeholder="CCV" />
                        </div>
                      </div>
                      <div className="col-md-2"></div>
                    </div>
                    <div className="button button-new mb-4">
                      <button type="button" className="comn-btn button_white">
                        Cancel
                      </button>
                      {/* <div> */}
                      <button type="submit" value="Save details" className="comn-btn common-btn-style">
                        Save details
                        <i class="fa-solid fa-plus plus-icon-style"></i>
                      </button>
                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* </div> */}
            </form>
            <ToastContainer
              position="top-right"
              autoClose={5000} // Auto close after 5 seconds
              closeOnClick
            />
            <Settingadvisor />
          </div>
        </div>
      </div>
    </>
  );
};
export default Settings;
