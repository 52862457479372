import React from "react";
import ListingCategories from "../../Views/Transactions/ListingCategories";
import AddCategoriesModal from "../../ModalForm/TransactionModals/AddCategoriesModal";
import { Bar } from "react-chartjs-2";
import "./Moneyinsights.css";

const BarChartSection = ({
  activeTab,
  transaction,
  togglePopover,
  expenseCategories,
  incomeCategories,
  updateTransactionCategory,
  popoverOpen,
  showAddCategory,
  handleAddCategory,
  data,
  options,
}) => {
  return (
    <div
      className="chart-container"
      style={{
        height: "570px",
        width: "100%",
      }}
    >
      <div
        className="expense-summary-section"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <h2 style={{ margin: 0 }}>February 2024</h2>
        </div>
        <div
          style={{
            fontWeight: "bold",
            fontSize: "22px",
            fontweight: "500",
          }}
        >
          Expenses:{" "}
          <span
            style={{
              color: activeTab === "Expenses" ? "#d04f4f" : "#479b88",
            }}
          >
            $1,890
          </span>
        </div>
      </div>
      <div className="row mt-2">
        <div className="modal-input-group">
          <span
            id={`Popover-${transaction.transactionUuid}`} // Add id for Popover target
            onClick={() => togglePopover(transaction.transactionUuid)}
          >
            Select a category
          </span>
        </div>

        <ListingCategories
          categories={
            transaction?.amount < 0 ? expenseCategories : incomeCategories
          }
          updateTransactionCategory={updateTransactionCategory}
          transactionId={transaction.transactionUuid}
          togglePopover={togglePopover}
          popoverOpen={popoverOpen}
          showAddCategory={showAddCategory}
          handleChange={() => handleAddCategory(transaction.transactionUuid)}
          transactionType={transaction.amount < 0 ? "Expense" : "Income"}
        />

        {showAddCategory && (
          <AddCategoriesModal
            modalOpen={showAddCategory}
            addNewCategory={() => {
              /* Add logic to handle adding new category */
            }}
            transactionType={transaction.amount < 0 ? "Expense" : "Income"}
            closeModal={handleAddCategory}
          />
        )}
      </div>
      {/* Chart goes here */}
      <div
        className="bar-chart"
        style={{
          height: "430px",
          width: "100%",
        }}
      >
        <Bar data={data} options={options} />
      </div>
    </div>
  );
};

export default BarChartSection;
