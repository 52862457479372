import React, { useEffect, useRef, useState } from "react";
import { Popover, PopoverBody } from "reactstrap";
import { ColorPicker } from "react-pick-color";
import custom_colour_icon from "../../assests/images/icons/custom_colour_icon.svg";
import { selectionColor } from "../../utilities/Statics/index";

const PopupColorModal = ({
  isOpen,
  onClose,
  targetId,
  title,
  options,
  selectedOption,
  handleOptionClick,
}) => {
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [customColor, setCustomColor] = useState("#fff");
  const [showMainPopover, setShowMainPopover] = useState(true);

  const colorPickerRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        colorPickerRef.current &&
        !colorPickerRef.current.contains(event.target)
      ) {
        setShowColorPicker(false);
        handleOptionClick(customColor);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [customColor, handleOptionClick]);

  const handleCustomColorClick = (e) => {
    e.preventDefault();
    setShowMainPopover(false);
    setShowColorPicker(true);
  };

  const handleColorChange = ({ hex }) => {
    setCustomColor(hex);
  };

  return (
    <>
      {showMainPopover && (
        <Popover
          placement="bottom"
          isOpen={showMainPopover}
          target={targetId}
          toggle={onClose}
          className="custom-popover-w-30"
        >
          <PopoverBody>
            <div className="transaction-categories">
              <div className="d-flex justify-content-between">
                <div className="fs-3">
                  <span className="bold black popup-top-heading">{title}</span>
                </div>
                <button onClick={onClose} className="close-modal-button">
                  <i className="fa fa-times"></i>
                </button>
              </div>

              <div
                className="popover-live mt-3 mb-2"
                style={{ float: "left", fontSize: "14px" }}
              >
                Colour
              </div>
              <div className="categories mb-3" style={{ float: "left" }}>
                {options.map((option) => (
                  <div key={option} className="button-blck-color mb-2">
                    <a
                      href="#"
                      className={`group-button`}
                      onClick={() => handleOptionClick(option)}
                      style={{
                        backgroundColor: selectionColor(option),
                        color: "white",
                      }}
                    >
                      {option}
                    </a>
                  </div>
                ))}
                <div className="button-blck-color mb-2">
                  <div
                    className="group-button custom-color-option"
                    style={{
                      paddingLeft: "4px",
                      paddingRight: "-2px",
                      border: "1px solid #CACACA",
                    }}
                  >
                    <img
                      src={custom_colour_icon}
                      alt="custom_colour_icon"
                      style={{ width: "25px", height: "25px" }}
                    />

                    <button
                      id="customColorButton"
                      className="group-button "
                      onClick={handleCustomColorClick}
                      style={{
                        border: "none",
                        paddingLeft: "5px",
                        paddingRight: "0px",
                      }}
                    >
                      Custom Colour
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </PopoverBody>
        </Popover>
      )}

      <div id="colorPickerTarget" style={{ display: "inline-block" }} />
      {showColorPicker && (
        <Popover
          placement="bottom"
          isOpen={showColorPicker}
          target={"colorPickerTarget"}
          className="custom-popover-w-30"
          innerRef={colorPickerRef}
        >
          <PopoverBody>
            <div className="color-picker mt-3">
              <ColorPicker color={customColor} onChange={handleColorChange} />
            </div>
          </PopoverBody>
        </Popover>
      )}
    </>
  );
};

export default PopupColorModal;
