export const AutoCategorise = ({ handleYes, handleNo }) => {
  return (
    <div
      className="popup-container modal-background open-modal"
      id="auto_categorise"
    >
      <div className="delete-popup-content">
        <h2 className="delete-popup-text"> Auto-categorise?</h2>
        <p className="delete-popup-sub-text">
          Would you like to automatically categorise merchants & transactions
          with this category? This will override any that are already
          categorised.
        </p>
        <div className="delete-popup-buttons">
          <button className="btn-delete" onClick={handleNo}>
            No
          </button>
        </div>
        <div className="delete-popup-buttons mt-2">
          <button className="btn-confirm" onClick={handleYes}>
            Yes
          </button>
        </div>
      </div>
    </div>
  );
};

export const PopUpForCustomBudget = ({ handleConfirm, handleCancel }) => {
  return (
    <div
      className="popup-container modal-background open-modal"
      id="popup_Update"
    >
      <div className="delete-popup-content">
        <h2 className="delete-popup-text"> Are you sure?</h2>
        <p className="delete-popup-sub-text">
          This will update the date range for all other income & expenses within
          this custom budget.
        </p>
        <div className="delete-popup-buttons">
          <button className="btn-delete" onClick={handleCancel}>
            Go back
          </button>
        </div>
        <div className="delete-popup-buttons mt-2">
          <button className="btn-confirm" onClick={handleConfirm}>
            Confirm change
          </button>
        </div>
      </div>
    </div>
  );
};
