import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import '../../../assests/css/style.css';
import Arrowright from '../../../assests/images/Expand_right.png';
import ArrowWhite from '../../../assests/images/icons/white-right.svg';
import PopupModal from '../../../ModalForm/Popup/PopupModel';
import weburls from '../../../Weburls/weburls';
import Piechart2 from '../Donutpiechart/Piechart2';
import AddOneOffBudget from '../oneOffBudget/AddOneOffBudget';
import './spendbudget.css';
import { colorCss, progressbarColor } from '../../../utilities/Statics';
export function calculatePercentageCompletion(completedValue, totalValue) {
  const percentageCompletion = (completedValue / totalValue) * 100;
  return percentageCompletion;
}
const Spendbudget = ({ uuid, monthly }) => {
  const [oneOffBudgetApiData, setOneOffBudgetApiData] = useState([]);
  const [SpendVsBudgetData, setSpendVsBudgetData] = useState();
  const [expenseBreakDownData, setExpenseBreakDownData] = useState();
  const [isPopupOpenExpensesBreakdown, setPopupOpenExpensesBreakdown] = useState(false);
  const [selectedExpensesBreakdownOption, setSelectedExpensesBreakdownOption] = useState('This Week');
  const [expensesBreakdownFilterData, setExpensesBreakdownFilterData] = useState();
  const [selectedExpensesBreakdown, setSelectedExpensesBreakdown] = useState({
    label: '',
    amount: 0,
  });
  const [modalOpen, setModalOpen] = useState(false);

  const handleModalForm = () => {
    setModalOpen(true);
  };

  function calculateBudget(expenses) {
    let totalExpenses = 0;
    let totalRemaining = 0;
    let totalAdjusted = 0;

    expenses?.forEach((item) => {
      totalExpenses += item.total_transaction_amount;
      totalRemaining += item.adjusted_expense_amount - item.total_transaction_amount;
      totalAdjusted += item.adjusted_expense_amount;
    });

    return {
      totalExpenses: totalExpenses.toFixed(2),
      totalRemaining: totalRemaining.toFixed(2),
      totalAdjusted: totalAdjusted.toFixed(2),
    };
  }
  const budgetCalculation = calculateBudget(SpendVsBudgetData);

  const togglePopupExpensesBreakdown = () => setPopupOpenExpensesBreakdown(!isPopupOpenExpensesBreakdown);
  const onHandleSelectedExpensesBreakdown = (label, value) => {
    setSelectedExpensesBreakdown({
      label: label,
      amount: value,
    });
  };
  const handleOptionExpensesBreakdownClick = async (option) => {
    setSelectedExpensesBreakdownOption(option);
    let data = [];
    switch (option) {
      case 'This Week':
        let totalThisweek = 0;
        await expenseBreakDownData?.thisWeek?.map((item) => {
          totalThisweek += item.total_income_amount;
        });
        const thisWeekExpensesBreakdown = await expenseBreakDownData?.thisWeek.map((item) => {
          const totalAmount = parseFloat(totalThisweek.toFixed(2));
          const amount = 100 + (item.total_income_amount / totalAmount) * 100;
          return {
            label: item.name,
            value: parseFloat(amount.toFixed(2)),
            width: 40,
          };
        });
        if (thisWeekExpensesBreakdown.length > 0) {
          setSelectedExpensesBreakdown({
            label: thisWeekExpensesBreakdown[0].label,
            amount: thisWeekExpensesBreakdown[0].value,
          });
        }
        data = thisWeekExpensesBreakdown ?? [];
        break;
      case 'Last Week':
        let totalLastWeek = 0;
        await expenseBreakDownData?.lastWeek.map((item) => {
          totalLastWeek += item.total_income_amount;
        });
        const lastWeekExpensesBreakdown = await expenseBreakDownData?.lastWeek.map((item) => {
          const totalAmount = parseFloat(totalLastWeek.toFixed(2));
          const amount = 100 + (item.total_income_amount / totalAmount) * 100;
          return {
            label: item.name,
            value: parseFloat(amount.toFixed(2)),
            width: 40,
          };
        });
        if (lastWeekExpensesBreakdown.length > 0) {
          setSelectedExpensesBreakdown({
            label: lastWeekExpensesBreakdown[0].label,
            amount: lastWeekExpensesBreakdown[0].value,
          });
        }
        data = lastWeekExpensesBreakdown ?? [];
        break;
      case 'This Month':
        let totalThisMonth = 0;
        await expenseBreakDownData?.thisMonth.map((item) => {
          totalThisMonth += item.total_income_amount;
        });
        const thisMonthExpensesBreakdown = await expenseBreakDownData?.thisMonth.map((item) => {
          const totalAmount = parseFloat(totalThisMonth.toFixed(2));
          const amount = 100 + (item.total_income_amount / totalAmount) * 100;
          return {
            label: item.name,
            value: parseFloat(amount.toFixed(2)),
            width: 40,
          };
        });
        if (thisMonthExpensesBreakdown.length > 0) {
          setSelectedExpensesBreakdown({
            label: thisMonthExpensesBreakdown[0].label,
            amount: thisMonthExpensesBreakdown[0].value,
          });
        }
        data = thisMonthExpensesBreakdown ?? [];
        break;
      case 'Last Month':
        let totalLastMonth = 0;
        await expenseBreakDownData?.lastMonth.map((item) => {
          totalLastMonth += item.total_income_amount;
        });
        const lastMonthExpensesBreakdown = await expenseBreakDownData?.lastMonth.map((item) => {
          const totalAmount = parseFloat(totalLastMonth.toFixed(2));
          const amount = 100 + (item.total_income_amount / totalAmount) * 100;
          return {
            label: item.name,
            value: parseFloat(amount.toFixed(2)),
            width: 40,
          };
        });
        if (lastMonthExpensesBreakdown.length > 0) {
          setSelectedExpensesBreakdown({
            label: lastMonthExpensesBreakdown[0].label,
            amount: lastMonthExpensesBreakdown[0].value,
          });
        }
        data = lastMonthExpensesBreakdown ?? [];
        break;
      case 'This Year':
        let totalThisYear = 0;
        await expenseBreakDownData?.thisYear.map((item) => {
          totalThisYear += item.total_income_amount;
        });
        const thisYearExpensesBreakdown = await expenseBreakDownData?.thisYear.map((item) => {
          const totalAmount = parseFloat(totalThisYear.toFixed(2));
          const amount = 100 + (item.total_income_amount / totalAmount) * 100;
          return {
            label: item.name,
            value: parseFloat(amount.toFixed(2)),
            width: 40,
          };
        });
        if (thisYearExpensesBreakdown.length > 0) {
          setSelectedExpensesBreakdown({
            label: thisYearExpensesBreakdown[0].label,
            amount: thisYearExpensesBreakdown[0].value,
          });
        }
        data = thisYearExpensesBreakdown ?? [];
        break;
      default:
        data = [];
        break;
    }
    setExpensesBreakdownFilterData(data);
    setPopupOpenExpensesBreakdown(false);
  };

  const fetchOneOffBudget = useCallback(async () => {
    try {
      const response = await axios.get(`${weburls.Get_OneOffBudget}${uuid}`);
      if (response?.data) {
        setOneOffBudgetApiData(response?.data?.oneOffBudget?.monthly);
      }
    } catch (error) {
      console.error('error', error);
      toast.error('Something went wrong please try again.', {
        position: 'top-right',
        autoClose: 2000,
      });
    }
  }, [uuid, setOneOffBudgetApiData]);

  const fetchSpendVsBudgetData = useCallback(async () => {
    try {
      const response = await axios.get(`${weburls.Get_Spend_Vs_Budget}${uuid}`);
      if (response?.data) {
        if (monthly) {
          setSpendVsBudgetData(response?.data?.expenses?.monthly);
        } else {
          setSpendVsBudgetData(response?.data?.expenses?.quarter);
        }
      }
    } catch (error) {
      console.error('error', error);
      toast.error('Something went wrong please try again.', {
        position: 'top-right',
        autoClose: 2000,
      });
    }
  }, [monthly, uuid]);
  const ExpenseBreakDownData = useCallback(async () => {
    try {
      const response = await axios.get(`${weburls.Get_Expense_Break_Down}${uuid}`);
      if (response?.data) {
        setExpenseBreakDownData(response?.data?.expenseBreakDown);
      }
    } catch (error) {
      console.error('error', error);
      toast.error('Something went wrong please try again.', {
        position: 'top-right',
        autoClose: 2000,
      });
    }
  }, [uuid]);

  useEffect(() => {
    fetchOneOffBudget();
    fetchSpendVsBudgetData();
    ExpenseBreakDownData();
  }, [ExpenseBreakDownData, fetchOneOffBudget, fetchSpendVsBudgetData]);

  useEffect(() => {
    ExpenseBreakDownData();
  }, [ExpenseBreakDownData]);

  return (
    <>
      <div className="row second-section">
        <div className="col-md-8 col-xl-8 col-sm-12 top-section mb-4 spend-vs-budget-top-side">
          <div className="col-md-6">
            <h5 className="title main-title">Spend vs Budget</h5>
          </div>
          <div class="col-md-6 right-section">
            <i class="fa-solid fa-plus " style={{ marginLeft: '264px', width: '40px', fontSize: '20px' }}></i>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-8 col-lg-8 col-md-8 spend-vs-budget-wrapper">
          <div className="wrp">
            {SpendVsBudgetData &&
              SpendVsBudgetData?.length > 0 &&
              SpendVsBudgetData?.map((item) => {
                return (
                  //  <div>
                  <Link className="groceries-main" to={`/BudgetEdit/${uuid}/${item.expense_uuid}`}>
                    <div className="income-merchant col-lg-5 col-xl-5 col-sm-12 mb-3">
                      <div className="inner-blck">
                        <div className="merchantSummary">
                          <div className={`icon-img ${colorCss(item.colour)}`}>{item.emoji}</div>
                          <div className="content">
                            <h6 className="small-content">{item.expense_name}</h6>
                            <h5 className="bold black">{item.total_transaction_amount}</h5>
                          </div>
                        </div>
                        <div className="arrow">
                          <img src={Arrowright} />
                        </div>
                      </div>
                      <div className="left-block name-merchant mb-2">
                        <h6>{(item.adjusted_expense_amount - item.total_transaction_amount).toFixed(2)} remaining</h6>
                      </div>
                      <div
                        className={'progress red-progress'}
                        style={{
                          height: '12px',
                        }}
                      >
                        <div
                          className={`progress-bar ${progressbarColor(item.colour)}`}
                          role="progressbar"
                          aria-valuenow={calculatePercentageCompletion(
                            item.total_transaction_amount,
                            item.adjusted_expense_amount,
                          ).toFixed(2)}
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style={{
                            width: `${calculatePercentageCompletion(
                              item.total_transaction_amount,
                              item.adjusted_expense_amount,
                            ).toFixed(2)}%`,
                            opacity: '2 !important',
                          }}
                        ></div>
                      </div>
                    </div>
                  </Link>
                );
              })}
          </div>
        </div>
        <div className="col-xl-4 col-md-12 col-sm-12 mt-6 income-vs-budget">
          <div className="right-top-blck">
            <div className="new-blck">
              <div className="content">
                <h3 className="title">Total expenses vs budget</h3>
                <p className="common-content">See how you’re tracking</p>
              </div>
              <div className="right">
                <h3 className="per-head bold">
                  {calculatePercentageCompletion(
                    budgetCalculation.totalExpenses,
                    budgetCalculation.totalAdjusted,
                  ).toFixed(2)}
                  %
                </h3>
              </div>
            </div>
            <div className="pie-blck first-circular-block ">
              <Piechart2
                percentage={calculatePercentageCompletion(
                  budgetCalculation.totalExpenses,
                  budgetCalculation.totalAdjusted,
                ).toFixed(2)}
              />
            </div>
          </div>
        </div>

        <div className="col-xl-8">
          <div className="wrp">
            <div
              className="col-lg-8 col-xl-8 col-sm-12 merchant-blck"
              style={{
                width: '100%',
              }}
            >
              <div className="inner-blck">
                <div className="merchantSummary">
                  <div className="content">
                    <h6 className="small-content little-big-font">Total expenses vs budget</h6>
                    <h5 className="bold">${budgetCalculation.totalExpenses}</h5>
                  </div>
                </div>
              </div>
              <div className="left-block name-merchant">
                <h6 className="mb-2">${budgetCalculation.totalRemaining} remaining</h6>
              </div>
              <div class="progress total-exp-budget-color" style={{ height: '16px' }}>
                <div
                  className={`progress-bar total-exp-budget-color-progress`}
                  role="progressbar"
                  aria-valuenow={calculatePercentageCompletion(
                    budgetCalculation.totalExpenses,
                    budgetCalculation.totalAdjusted,
                  ).toFixed(2)}
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={{
                    width: `${calculatePercentageCompletion(
                      budgetCalculation.totalExpenses,
                      budgetCalculation.totalAdjusted,
                    ).toFixed(2)}%`,
                    opacity: '2 !important',
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-md-4 col-sm-4 mt-6 income-vs-budget">
          <div className="right-top-blck mt-3 new-bottom-blck">
            <div className="new-blck">
              <div className="content">
                <h3 className="title">Budgeted expenses</h3>

                <h3 className="per-head bold dark-blue">${selectedExpensesBreakdown.amount}</h3>
              </div>
              <div className="right">
                <div className="dropdown">
                  <button
                    className="group-button green"
                    onClick={togglePopupExpensesBreakdown}
                    aria-haspopup="true"
                    aria-expanded={isPopupOpenExpensesBreakdown}
                  >
                    {selectedExpensesBreakdownOption}
                  </button>
                  {isPopupOpenExpensesBreakdown && (
                    <PopupModal
                      isOpen={isPopupOpenExpensesBreakdown}
                      onClose={togglePopupExpensesBreakdown}
                      title="Date Range"
                      options={['This Week', 'Last Week', 'This Month', 'Last Month', 'This Year']}
                      selectedOption={selectedExpensesBreakdownOption}
                      handleOptionClick={handleOptionExpensesBreakdownClick}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="chart-section-vertical">
              <ul className="bar-chart-budget">
                {expensesBreakdownFilterData &&
                  expensesBreakdownFilterData?.length > 0 &&
                  expensesBreakdownFilterData.map((item, index) => (
                    <li
                      key={index}
                      className={item.label === selectedExpensesBreakdown.label ? 'power-wifi-bar' : 'bar-budget'}
                      style={{ height: `${item.value}px`, width: `${item.width}px` }}
                      onClick={() => {
                        onHandleSelectedExpensesBreakdown(item.label, item.value);
                      }}
                    >
                      <span className="label-budget">{item.label}</span>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
        <div>
          <div
            className="col-lg-4 col-xl-4 col-sm-4 goals-wrp content"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <h5 className="title main-title">One off Budgets</h5>
            <i class="fa-solid fa-plus cursor-pointer" onClick={handleModalForm}></i>
          </div>

          {oneOffBudgetApiData &&
            oneOffBudgetApiData?.length > 0 &&
            oneOffBudgetApiData.map((it) => (
              <div className="col-xl-4 mt-2">
                <Link to={`/EditOneOffBudget/${it.uuid}/${uuid}`}>
                  <div className={`one-off-budget  col-lg-5 col-xl-5 col-sm-12 white ${colorCss(it.colour)} `}>
                    <div className="inner-blck">
                      <div className="merchantSummary">
                        <div className="icon-img red-img goal-1-progress-color">{it.emoji}</div>
                        <div className="content">
                          <h6 className="small-content white">{it.onoffbudget_name}</h6>
                          <h5 className="bold">${it.total_transaction_amount}</h5>
                        </div>
                      </div>
                      <div className="arrow">
                        <img src={ArrowWhite} alt="goal-emoji" />
                      </div>
                    </div>
                    <div className="left-block name-merchant">
                      <h6 className="white mb-2">${(it.amount - it.total_transaction_amount).toFixed(2)} remaining</h6>
                    </div>
                    <div className="progress goal-1-progress-color" style={{ height: '12px' }}>
                      <div
                        className={`progress-bar ${progressbarColor(it.colour)}`}
                        role="progressbar"
                        aria-valuenow={calculatePercentageCompletion(it.total_transaction_amount, it.amount).toFixed(2)}
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{
                          width: `${calculatePercentageCompletion(it.total_transaction_amount, it.amount).toFixed(2)}%`,
                          opacity: '2 !important',
                        }}
                      ></div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
        </div>
      </div>
      {modalOpen === true && (
        <div>
          <AddOneOffBudget
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            fetchExpense={fetchOneOffBudget}
            uuid={uuid}
          />
        </div>
      )}
    </>
  );
};
export default Spendbudget;
