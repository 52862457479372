import React from "react";
import { Route, Routes } from "react-router-dom";
import "../../../src/assests/bootstrap/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../App.css";
import "../../assests/css/style.css";
import EditClient from "../../Components/ClientDetails/index";
import DetailsPage from "../../Components/DetailPage/index";
import Account from "../ConnectedAccounts/Account.jsx";
import AccountBalances from "../Accounts/index";
import AddFinancial from "../AddNewGoal/AddNewGoals.jsx";
import Addwidgets from "../../Components/Innerpanel/Addwdigets/Addwidgets";

import CashFlow from "../../Components/Innerpanel/CashFlow";
import Cashflowcart from "../../Components/Innerpanel/Cashflowcalender/Cashflowcalender";
import Dashboardinner from "../../Components/Innerpanel/Dashboardinner";
import Editbudget from "../../Components/Innerpanel/Editbudget";
import EditGoalBudget from "../../Components/Innerpanel/EditGoalBudget";
import Financial from "../GoalsList/Financial.jsx";
import GoalDetails from "../GoalDetails/GoalDetails.jsx";
import Groceriesedit from "../../Components/Innerpanel/Groceriesedit/Groceriesedit";
import NetWorth from "../NetWorth/NetWorth.jsx";
import EditOneOffBudget from "../../Components/Innerpanel/oneOffBudget/EditOneOfBudget";
import Retirement from "../Retirement/Retirement.jsx";
import SpendingsAccount from "../../Components/Innerpanel/SpendingsAccount";
import Merchantdetails from "../../Views/Merchats/MerchatsDetail.jsx";
import OAuthCallbackHandler from "../../Components/OAuthCallbackHandler";
import Activepage from "../../Components/pages/Activepage";
import Clients from "../../Components/pages/Clients";
import Dashboard from "../../Components/pages/Dashboard";
// import EmailForgrtPassword from '../../Components/pages/EmailForgrtPassword';
// import ForgotPassword from '../../Components/pages/ForgotPassword';
import GroupDetail from "../../Components/pages/GroupDetail";
import Groups from "../../Components/pages/Groups";
import Login from "../../Components/pages/Login";
// import ManageAccountLogin from '../../Components/pages/ManageAccountLogin';
// import NotFoundPage from '../../Components/pages/NotFoundPage';
import Newclient from "../../Components/pages/Newclient";
import Newgroup from "../../Components/pages/Newgroup";
// import PayementCancel from '../../Components/pages/PayementCancel';
// import PayementSuccess from '../../Components/pages/PayementSuccess';
// import PaymentsUpgradeSuccess from '../../Components/pages/PaymentsUpgradeSuccess';
// import Register from '../../Components/pages/Register';
// import ResetPassword from '../../Components/pages/ResetPassword';
import Setting from "../../Components/pages/Settings";
// import SuccessPaymentDetail from '../../Components/pages/SuccessPaymentDetail';
// import TestPlan from '../../Components/pages/TestPlan';
// import AccountSetup from '../../Components/Plans/AccountSetup';
// import AddPayments from '../../Components/Plans/AddPayments';
// import CancelPlan from '../../Components/Plans/CancelPlan';
// import CancelFeedback from '../../Components/Plans/CancelFeedback';
// import CancelPlanMessage from '../../Components/Plans/CancelPlanMessage';
// import ManagePlan from '../../Components/Plans/ManagePlan';
// import SelectPlans from '../../Components/Plans/SelectPlans';
// import SelectNewPlan from '../../Components/Plans/SelectNewPlan';
// import ChangePlan from '../../Components/Plans/ChangePlan';
// import UpdatePlan from '../../Components/Plans/UpdatePlan';
import PrivateRoutes from "../../Components/Private";
import Email2FAVerifaction from "../../Views/Setting/Email2FAVerifaction";
import Email2FAVerifactionDone from "../../Views/Setting/Email2FAVerifactionDone.jsx";

import Email2FAVerifactionOTP from "../../Views/Setting/Email2FAVerifactionOTP";
import SettingPage from "../../Views/Setting/index";
import EditCompany from "../../Components/SuperAdminDashboard/EditSuperAdmin/EditCompany";
import EditPlans from "../../Components/SuperAdminDashboard/EditSuperAdmin/EditPlans";
import NewCompany from "../../Components/SuperAdminDashboard/NewCompany";
import NewPlans from "../../Components/SuperAdminDashboard/NewPlans";
import SuperAdminDashboard from "../../Components/SuperAdminDashboard/SuperAdminDashboard";
import CompaniesList from "../../Components/SuperAdminDashboard/SuperAdminLists/CompaniesList";
import SuperAdminPlans from "../../Components/SuperAdminDashboard/SuperAdminLists/SuperAdminPlans";
// import AddTransaction from "../../Components/Transactions/AddTransaction";
import AddTransaction from "../../Views/Transactions/AddTransaction.jsx";
import BudgetEdit from "../../Components/BudgetEdit";
import { ToastContainer } from "react-toastify";
import AccountDetails from "../AccountDetails/index.jsx";
import BudgetDetails from "../../Components/BudgetDetails/BudgetDetails";
import Moneyinsights from "../../Components/Moneyinsights/Moneyinsights";
import NetWorthDetails from "../../Views/NetWorthDetails";
import Transactions from "../../Views/Transactions";
import Merchats from "../../Views/Merchats/index";
import AdminLayout from "../../Layout/AdminLayout.jsx";
import BudgetSummary from "../../Components/Innerpanel/BudgetSummery/BudgetSummary.jsx";
import MerchantDetail from "../Merchats/MerchantDetail.jsx";
import NotFoundPage from "../../Components/pages/NotFoundPage.jsx";
import AkahuCallback from "../AkahuCallback/index.jsx";
// import EmailVerifactionOTP from './Components/pages/EmailVerifactionOTP';
const Content = () => {
    return (
        <Routes>
            <Route element={<PrivateRoutes allowedRoles={[2, 3, 4, 5]} />}>
                <Route path="/dashboard" element={<Dashboard />}></Route>
                <Route path="/Clients" element={<Clients />}></Route>
                <Route path="/Groups" element={<Groups />}></Route>
                <Route path="/Setting" element={<Setting />}></Route>
                <Route path="/Clients/Newclient" element={<Newclient />}></Route>
                <Route
                    path="/Clients/Activepage/:uuid"
                    element={<Activepage />}
                ></Route>
                <Route path="/Clients/Edit/:id" element={<EditClient />}></Route>
                <Route path="/Clients/Clientdetails" element={<Activepage />}></Route>
                <Route path="/Groups/GroupDetail/:id" element={<GroupDetail />}></Route>
                <Route path="/Groups/Details" element={<DetailsPage />}></Route>
                <Route path="/Groups/Newgroup" element={<Newgroup />}></Route>
                <Route
                    path="/Dashbaordpanel/:uuid"
                    element={<Dashboardinner />}
                ></Route>
                <Route path="/merchants/:uuid" element={<Merchats />}></Route>
                <Route
                    path="/Merchatsdetails/:uuid/:merchantsId"
                    element={<Merchantdetails />}
                ></Route>
                <Route
                    path="/merchantdetails/:uuid"
                    element={<MerchantDetail />}
                ></Route>
                <Route path="/transactions/:uuid" element={<Transactions />}></Route>
                <Route
                    path="/AddTransaction/:uuid"
                    element={<AddTransaction />}
                ></Route>
                <Route
                    path="/accounts/:uuid"
                    element={<AccountBalances />}
                ></Route>

                <Route path="/accountdetails/:uuid" element={<AccountDetails />} />
                <Route path="/settings/:uuid" element={<SettingPage />}></Route>
                <Route path="/akahu" element={<AkahuCallback />}></Route>
                <Route
                    path="/Email2FAVerifaction/:uuid"
                    element={<Email2FAVerifaction />}
                ></Route>
                <Route
                    path="/Email2FAVerifactionOTP/:uuid"
                    element={<Email2FAVerifactionOTP />}
                ></Route>
                <Route
                    path="/Email2FAVerifactionDone/:uuid"
                    element={<Email2FAVerifactionDone />}
                ></Route>
                <Route path="/budget/:uuid" element={<BudgetSummary />}></Route>
                <Route path="/MoneyInsights/:uuid" element={<Moneyinsights />}></Route>
                <Route
                    path="/budget/:uuid/budgetdetails/:category_type/:budgetId"
                    element={<BudgetDetails />}
                />

                {/* <Route
                    path="/BudgetSummary/:uuid/budget-insights/:insightsUuid"
                    element={<Moneyinsights />}
                /> */}

                <Route path="/Editbudget/:uuid" element={<Editbudget />}></Route>
                <Route path="/Groceriesedit" element={<Groceriesedit />}></Route>
                {/* <Route path="/networth/:uuid" element={<NetWorth />}></Route> */}
                {/* <Route
                    path="/networth/addasset/:uuid"
                    element={<NetWorthDetails />}
                ></Route> */}

{/* <Route
                    path="/networth/addliability/:uuid"
                    element={<NetWorthDetails />}
                ></Route> */}



                {/* <Route path="/goals/:uuid" element={<Financial />}></Route>
                <Route
                    path="/goals/:uuid/goaldetails/:goalUuid"
                    element={<GoalDetails />}
                ></Route> */}
                {/* <Route path="/retirement/:uuid" element={<Retirement />}></Route> */}
                {/* <Route path="/cashflow/:uuid" element={<CashFlow />}></Route> */}
                <Route
                    path="/SpendingsAccount/:uuid"
                    element={<SpendingsAccount />}
                ></Route>
                <Route path="/connectaccounts/:uuid" element={<Account />}></Route>
                <Route
                    path="/Clients/Cashflowcalender"
                    element={<Cashflowcart />}
                ></Route>
                {/* <Route
                    path="/goals/addnewgoal/:uuid"
                    element={<AddFinancial />}
                ></Route> */}

                {/* <Route path="/EditGoal/:goalUuid" element={<EditGoalBudget />}></Route> */}
                <Route
                    path="/EditOneOffBudget/:oneOfBudgetUuid/:uuid"
                    element={<EditOneOffBudget />}
                ></Route>
                <Route path="/Addwidgets" element={<Addwidgets />}></Route>
                <Route path="/akahu" element={<OAuthCallbackHandler />}></Route>
                <Route
                    path="/BudgetEdit/:uuid/:expenseUuid"
                    element={<BudgetEdit />}
                ></Route>
                {/* Super admin side */}
            </Route>
            <Route element={<PrivateRoutes allowedRoles={[1]} />}>
                <Route path="/SuperAdmin" element={<SuperAdminDashboard />} />
                <Route path="/Companies" element={<CompaniesList />}></Route>
                <Route path="/Companies/NewCompany" element={<NewCompany />}></Route>
                <Route path="/Plans" element={<SuperAdminPlans />}></Route>
                <Route path="/Plans/NewPlans" element={<NewPlans />}></Route>
                <Route path="/Companies/EditCompany" element={<EditCompany />}></Route>
                <Route path="/Plans/EditPlans/:id" element={<EditPlans />}></Route>
            </Route>
            <Route path="*" element={<NotFoundPage />}/> 
            {/* <Route path="/ForgotPassword" element={<ForgotPassword />}></Route>
    <Route path="/" element={<Register />}></Route>
    <Route path="/emailForgetPassword/:email" element={<EmailForgrtPassword />}></Route>
    <Route path="/resetPassword/:email" element={<ResetPassword />}></Route>
    <Route path="/selectPlans" element={<SelectPlans />}></Route>
    <Route path="/selectNewPlan" element={<SelectNewPlan />}></Route>
    <Route path="/changePlans" element={<ChangePlan />}></Route>
    <Route path="/updatePlan" element={<UpdatePlan />}></Route>
    <Route path="/payments" element={<AddPayments />}></Route>
    <Route path="/accountSetup" element={<AccountSetup />}></Route>
    <Route path="/managePlan" element={<ManagePlan />}></Route>
    <Route path="/cancelPlan" element={<CancelPlan />}></Route>
    <Route path="/cancelPlanFeedback" element={<CancelFeedback />}></Route>
    <Route path="/testPlan" element={<TestPlan />}></Route>
    <Route path="/success" element={<PayementSuccess />}></Route>
    <Route path="/successPaymentDetail" element={<SuccessPaymentDetail />}></Route>
    <Route path="/paymentSuccess" element={<PaymentsUpgradeSuccess />}></Route>
    <Route path="/cancel" element={<PayementCancel />}></Route> */}
            {/* <Route path="/login" element={<Login />}></Route> */}
            {/* <Route path="/manageAccountLogin" element={<ManageAccountLogin />}></Route>
    <Route path="/emailVerifaction" element={<EmailVerifactionOTP />}></Route>
    <Route path="/cancelPlanMessage" element={<CancelPlanMessage />}></Route>
    <Route path="*" element={<NotFoundPage />} /> */}
        </Routes>
    );
};

export default Content;
