import React, { useEffect, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "../AddIncome/addIncome.css";
import "../../ModalForm/ChangePassword/index.css";
import "../TransactionModals/index.css";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams } from "react-router-dom";
import { accountTypeArray } from "../../assests/mock/AccountType.js";
import { ReactComponent as UploadImageIcon } from "../../assests/images/upload-img.svg";
import { ReactComponent as CrossIcon } from "../../assests/images/icons/cross-icon.svg";
import { toast } from "react-toastify";

const schema = yup.object().shape({
  accountName: yup.string().required("*Please enter account name"),
  value: yup.string().required("*Please enter value"),
});

const UpdateCustomAccountModel = ({
  modalOpen,
  closeModal,
  updateHandleChanges,
  name,
  amount,
  type,
}) => {
  const { uuid } = useParams();
  const [errors, setErrors] = useState({});
  const [avatar, setAvatar] = useState();
  const [cashAccountData, setCashAccountData] = useState({
    accountName: name,
    value: amount,
    avatar: "",
    accountType: type,
  });

  const { register, reset } = useForm({
    resolver: yupResolver(schema),
  });

  const fileInputRef = useRef(null);
  const [imageName, setImageName] = useState("");

  useEffect(() => {
    reset({
      accountName: name || "",
      value: amount || "",
      accountType: type || "",
    });
    setCashAccountData({
      accountName: name || "",
      value: amount || "",
      avatar: "",
      accountType: type || "",
    });
  }, [name, amount, type, reset]);

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCashAccountData({ ...cashAccountData, userUuid: uuid, [name]: value });
    const newErrors = { ...errors };
    if (name === "accountName" && value.length < 3) {
      newErrors.name = "Account Name must be at least 3 characters long";
    } else if (name === "accountType" && !value) {
      newErrors.accountType = "Please enter account type";
    } else if (name === "value" && !value) {
      newErrors.accountBalance = "Please enter account balance";
    } else {
      newErrors.name = "";
      newErrors.accountType = "";
      newErrors.accountBalance = "";
      delete newErrors[name];
    }
    setErrors(newErrors);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setImageName(file?.name);
    setAvatar(file);
  };

  const clearFileInput = () => {
    const fileInput = document.querySelector('input[type="file"]');
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    try {
      if (
        cashAccountData.accountName &&
        cashAccountData.value &&
        cashAccountData.accountType
      ) {
        const data = {
          name: cashAccountData.accountName,
          amount: cashAccountData.value,
          type: cashAccountData.accountType,
          avatar: avatar,
        };
        updateHandleChanges(data);
        clearFileInput();
      } else {
        handleChange({
          target: { name: "accountName", value: cashAccountData.accountName },
        });
        handleChange({
          target: { name: "accountType", value: cashAccountData.accountType },
        });
        handleChange({
          target: { name: "value", value: cashAccountData.value },
        });
      }
    } catch (error) {
      console.error("error", error);
      toast.error("Something went wrong please try again.", {
        position: "top-right",
        autoClose: 2000,
      });
    }
  };

  return (
    <>
      <div className={`modal-background ${modalOpen ? "open-modal" : ""}`}>
        <div className="modal-content add-rule-width">
          <button
            onClick={() => closeModal()}
            className="add-account-close-button"
          >
            <CrossIcon/>
          </button>
          <form>
            <div className="">
              <div className="d-flex justify-content-between">
                <div className="fs-3">
                  <span className="bold black password-heading">
                    Edit account
                  </span>
                </div>
              </div>

              <div className="add-rules-box shadow mt-3">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="fw-500 fs-16">Name</div>
                  <div className="account-input">
                    <input
                      type="text"
                      defaultValue={name}
                      onChange={handleChange}
                      {...register("accountName", {
                        onChange: (e) => {
                          handleChange(e);
                        },
                      })}
                      style={{
                        width: "226px",
                        height: "35px",
                        textAlign: "center",
                      }}
                    />
                  </div>
                </div>
                {errors.accountName && (
                  <>
                    <br />
                    <p className="error-message">{errors.accountName}</p>
                  </>
                )}

                <div className="d-flex justify-content-between align-items-center mt-3">
                  <div className="fw-500 fs-16">Account type</div>
                  <div className="account-input">
                    <select
                      className="account-select"
                      name="accountType"
                      defaultValue={type}
                      {...register("accountType", {
                        onChange: (e) => {
                          handleChange(e);
                        },
                      })}
                    >
                      <option value="">Select</option>
                      {accountTypeArray &&
                        accountTypeArray.length > 0 &&
                        accountTypeArray.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.title}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                {errors.accountType && (
                  <>
                    <br />
                    <p className="error-message">{errors.accountType}</p>
                  </>
                )}

                <div className="d-flex align-items-center justify-content-between mt-3">
                  <div className="fw-500 fs-16">Account&nbsp;balance</div>
                  <div className="account-input small-account">
                    <input
                      type="text"
                      defaultValue={amount}
                      onChange={handleChange}
                      {...register("value", {
                        onChange: (e) => {
                          handleChange(e);
                        },
                      })}
                      style={{
                        width: "148px",
                        height: "35px",
                        textAlign: "center",
                      }}
                    />
                  </div>
                </div>
                {errors.accountBalance && (
                  <>
                    <br />
                    <p className="error-message">{errors.accountBalance}</p>
                  </>
                )}

                <div className="d-flex align-items-center justify-content-between mt-3">
                  <div className="fw-500 fs-16">Image</div>
                  <div className="account-input">
                    <div className="upload">
                      <div className="account-img-upload">
                        <input
                          type="file"
                          accept="image/*"
                          ref={fileInputRef}
                          style={{ display: "none" }}
                          onChange={handleFileChange}
                        />
                        <UploadImageIcon
                          className="upload-icon"
                          onClick={handleClick}
                        />
                        {imageName}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <button
                type="submit"
                onClick={submitHandler}
                className="add-transaction-rules-btn cursor-pointer mt-4"
              >
                Save changes
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default UpdateCustomAccountModel;
