import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { ReactComponent as Plus } from "../../assests/images/icons/Add_round.svg";
import Profile from "../../assests/images/icons/image.png";
import { ReactComponent as Arrow } from "../../assests/images/icons/New_Arrow_right.svg";
import Shape from "../../assests/images/icons/shape.png";
import MainSidebar from "../MainSidebar";
import AddIncomeForm from "../../ModalForm/AddIncome/AddIncomeForm";
import EditIncome from "../../ModalForm/AddIncome/EditIncome";
import PopupHowOftenModal from "../../ModalForm/Popup/PopupHowOften";
import PopupInfoModal from "../../ModalForm/Popup/PopupInfoModel";
import weburls, { BudgetBuddie } from "../../Weburls/weburls";
import Accountransfer from "./Accounttransfer/Accountransfer";
import OneOffBudget from "./oneOffBudget";
import Reoccuring from "./Recoccuringexpenses/Reoccuring";

const Editbudget = () => {
  const { uuid } = useParams();
  const [incomeApiData, setIncomeApiData] = useState([]);

  const [modalOpen, setModalOpen] = useState(false);
  const [editIncome, setEditIncome] = useState(false);
  const [incomeUuid, setIncomeUuid] = useState(null);
  const [selectedIncomeOption, setSelectedIncomeOption] = useState("Weekly");
  const [accountData, setAccountData] = useState([]);
  const [totalIncomeData, setTotalIncomeData] = useState();
  const [isPopupOpenInfo, setPopupOpenInfo] = useState(false);
  const [isPopupOpenHowOften, setPopupOpenHowOften] = useState(false);

  const togglesPopupOpenHowOften = () =>
    setPopupOpenHowOften(!isPopupOpenHowOften);
  const togglePopupInfo = () => setPopupOpenInfo(!isPopupOpenInfo);

  const handleModalForm = () => {
    setModalOpen(true);
    setEditIncome(false);
  };

  const handleModalEditForm = (id) => {
    setIncomeUuid(id);
    setModalOpen(true);
    setEditIncome(true);
  };

  const fetchIncome = useCallback(async () => {
    try {
      const response = await axios.get(`${weburls.Get_Income}${uuid}`);
      if (response?.data) {
        setIncomeApiData(response?.data.incomes);
      }
    } catch (error) {
      toast.error(`Something went wrong, please try again. ${error}`, {
        position: "top-right",
        autoClose: 2000,
      });
    }
  }, [uuid]);

  const formatDate = (datetimeString) => {
    const date = new Date(datetimeString);
    return date.toLocaleDateString();
  };

  const fetchCashAccount = useCallback(async () => {
    try {
      const response = await axios.get(`${weburls.Get_Cash_Account}${uuid}`);
      if (response?.data) {
        setAccountData(response?.data?.accounts);
      }
    } catch (error) {
      toast.error("Something went wrong, please try again.", {
        position: "top-right",
        autoClose: 2000,
      });
    }
  }, [uuid]);
  const handleDeleteIncome = async (id) => {
    try {
      const response = await axios.delete(
        `${weburls.Delete_income}${uuid}/${id}`
      );
      if (response.status === 200) {
        toast.success(response?.data?.message || "Income Delete Successfully", {
          position: "top-right",
          autoClose: 1000,
        });
        fetchIncome();
      } else if (response.status === 500) {
        toast.error("Something went wrong please try again.");
      } else {
        console.error("Delete request failed please try again", response);
      }
    } catch (error) {
      toast.error("Something went wrong please try again.", {
        position: "top-right",
        autoClose: 2000,
      });
    }
  };
  useEffect(() => {
    fetchIncome();
    fetchCashAccount();
  }, [fetchCashAccount, fetchIncome]);

  const handleOptionChange = (option) => {
    setSelectedIncomeOption(option);
    setPopupOpenHowOften(false);
  };

  const fetchTotalIncome = useCallback(async () => {
    try {
      const response = await axios.get(
        `${BudgetBuddie}api/budgetSummary/getAllIncome/${uuid}/?option=${selectedIncomeOption}`
      );
      setTotalIncomeData(response?.data);
    } catch (error) {
      console.error("error", error);
      toast.error("Something went wrong, please try again.", {
        position: "top-right",
        autoClose: 2000,
      });
    }
  }, [selectedIncomeOption, uuid]);

  useEffect(() => {
    fetchTotalIncome();
  }, [fetchTotalIncome]);
  return (
    <>
      <div className="app-main__outer edit-your-budget-main-wrapper">
        <ToastContainer position="top-right" autoClose={5000} closeOnClick />

        <div className="row top-nav">
          <div className="col-md-4 col-lg-3 col-xl-4 edit-your-budget-heading-wrp">
            <div className="bold top-head big-head-new">Edit your Budget</div>
            <div className="fst-normal setting_text_color">
              Work out your income & expenses or let us to the hard work for
              you.
            </div>
          </div>

          <div className="col-md-12 col-xl-8 col-lg-8 col-sm-12 right-section back-to-portal-top">
            <div className=" right-section">
              <div>
                <Link to="/BudgetSummary">
                  <div
                    className="edit-btn"
                    href-="#"
                    style={{ width: "327px", height: "66px" }}
                  >
                    <div className="left">
                      <h4>View budget summary</h4>
                      <p style={{ color: "#04baff" }}>Get the full overview.</p>
                    </div>

                    <div className="right">
                      <Link to={`/BudgetSummary/${uuid}`}>
                        <Arrow />
                      </Link>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="profile-dashboard">
                <img
                  src={Shape}
                  alt="Mortage_img"
                  onClick={togglePopupInfo}
                  style={{ width: "20px", marginLeft: "20px" }}
                />
              </div>
              {isPopupOpenInfo && (
                <PopupInfoModal
                  isOpen={isPopupOpenInfo}
                  onClose={togglePopupInfo}
                  title="Editing your budget 💸"
                  description={[
                    "Editing your budget is nice and easy. Enter the details about your regular income, like the amount, name, when you last got paid, and how often you get paid.",
                    "Now enter your details about your expenses. Same as income, enter in the amount and how often. To make things super simple, hit auto expense, and we’ll do the work for you.",
                    "NOTE: To make auto expense work correctly & accurately, you need to have all merchants & transactions categorised.",
                    "You can add one off budgets down the very bottom for things that you don’t ant to include in reoccurring.",
                    "Remove an income or expense in your budget by clicking the cross.",
                  ]}
                />
              )}
              <div className="profile-dashboard">
                <div className="profile-img">
                  <img src={Profile} alt="profile" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row middle-seciton">
          <div className="col-xl-12 top-head-section">
            <h3 className="semi-heading-edit-budget bold">
              Your income after tax
            </h3>
            <p>
              Regular income is what you consistently get paid after tax each
              week, fortnight or month.{" "}
            </p>
          </div>
          <div className="mt-2">
            <div className="col-lg-12 new-table first-table-edit-budget">
              <div>
                <div className="table-horizontal">
                  <div class="col-12 table-horizontal">
                    <table class="table-image new-table edit-table">
                      <thead>
                        <tr>
                          <th scope="col">Income type</th>
                          <th scope="col">Income name</th>
                          <th scope="col">Income amount</th>
                          <th scope="col">Last payment date</th>
                          <th scope="col">How Often?</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody className="space-table">
                        {incomeApiData?.length > 0 &&
                          incomeApiData &&
                          incomeApiData.map((item, index) => {
                            return (
                              <tr className="cursor-pointer">
                                <th>
                                  <div className="air-image">
                                    <h3
                                      className="table-content display"
                                      style={{ fontSize: "20px" }}
                                      onClick={() =>
                                        handleModalEditForm(item.incomeUuid)
                                      }
                                    >
                                      {item.incomeType}
                                    </h3>
                                  </div>
                                </th>
                                <td className="table-text-color">
                                  <div className=" budgetIncome">
                                    {item.incomeName}
                                  </div>
                                </td>
                                <td className="table-text-color">
                                  <div className="planner">
                                    {item.incomeAmount}
                                  </div>
                                </td>
                                <td className="table-text-color">
                                  <div className="planner">
                                    {formatDate(item.lastPaymentDate)}
                                  </div>
                                </td>
                                <td className="table-text-color">
                                  <div className="budgetHowOften">
                                    {item.howOften}
                                  </div>
                                </td>

                                <td className="table-text-color">
                                  <i
                                    class="fa fa-times"
                                    aria-hidden="true"
                                    onClick={() =>
                                      handleDeleteIncome(item.incomeUuid)
                                    }
                                    style={{
                                      fontSize: "24px",
                                    }}
                                  ></i>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                    <div className="income-btn-wrp col-xl-12">
                      <button className="new-btn" onClick={handleModalForm}>
                        Add Income <Plus />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-5 col-xl-9 col-md-12 total-income d-flex edit-your-budget-total-income-wrapper">
                <div className="col-md-12 col-xl-12">
                  <div className="col-md-9 col-xl-9 total-income-block-section">
                    <div className="inline col-md-3 col-xl-4">
                      <h4 className="table-content bold">Total income</h4>
                    </div>
                    <div className="inline col-md-4 col-xl-6 total-inc-first">
                      <div
                        className="content-blck"
                        style={{ alignItems: "center" }}
                      >
                        ${totalIncomeData?.totalIncome ?? 0}
                      </div>
                    </div>

                    <div className="inline col-md-4 col-xl-6 total-inc-first">
                      <div
                        className=" planner-for-weekly edit-table"
                        style={{
                          marginLeft: "60px",
                        }}
                      >
                        <button
                          style={{
                            borderRadius: "30px",
                            width: "250px",
                            height: "34px",
                            backgroundColor: "#469B88",
                          }}
                          type="button"
                          className={`group-button popoup-color`}
                          onClick={togglesPopupOpenHowOften}
                          aria-haspopup="true"
                          aria-expanded={isPopupOpenHowOften}
                        >
                          {selectedIncomeOption
                            ? selectedIncomeOption
                            : "Weekly"}
                        </button>
                        {isPopupOpenHowOften && (
                          <PopupHowOftenModal
                            isOpen={isPopupOpenHowOften}
                            onClose={togglesPopupOpenHowOften}
                            options={[
                              "Weekly",
                              "Fortnightly",
                              "Monthly",
                              "Quarterly",
                              "Annually",
                            ]}
                            handleOptionClick={handleOptionChange}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Reoccuring uuid={uuid} accountData={accountData} />
        <Accountransfer uuid={uuid} accountData={accountData} />
        <OneOffBudget uuid={uuid} />
      </div>
      {modalOpen === true && (
        <div>
          {editIncome === true ? (
            <EditIncome
              modalOpen={modalOpen}
              setModalOpen={setModalOpen}
              incomeUuid={incomeUuid}
              fetchIncome={fetchIncome}
              accountData={accountData}
              uuid={uuid}
              // fetchTotalIncome={fetchTotalIncome()}
            />
          ) : (
            <AddIncomeForm
              modalOpen={modalOpen}
              setModalOpen={setModalOpen}
              fetchIncome={fetchIncome}
              accountData={accountData}
              uuid={uuid}
            />
          )}
        </div>
      )}
    </>
  );
};
export default Editbudget;
