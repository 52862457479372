import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";
import { colorCss } from "../../../utilities/Statics";
import PopupColorModal from "../../../ModalForm/Popup/PopupColorModel";
import weburls from "../../../Weburls/weburls";

const schema = yup.object().shape({
  name: yup.string().required("*Please your name"),
  amount: yup
    .string()
    .required("*Please enter your amount")
    .matches(/^[0-9]+$/, "*Please enter number only"),
  colour: yup.mixed(),
  emoji: yup.mixed(),
});

const AddOneOffBudget = ({ modalOpen, fetchExpense, setModalOpen, uuid }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [isPopupOpenColor, setPopupOpenColor] = useState(false);

  const [oneOffBudget, setOneOffBudget] = useState({
    name: "",
    amount: "",
    colour: "",
    emoji: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOneOffBudget({ ...oneOffBudget, [name]: value });
  };

  const handleClose = () => {
    setModalOpen(!modalOpen);
  };
  const togglePopupColor = () => setPopupOpenColor(!isPopupOpenColor);
  const handleOptionColorClick = (option) => {
    setOneOffBudget({ ...oneOffBudget, colour: option });
    setPopupOpenColor(false);
  };
  const onSubmitHandler = async () => {
    try {
      const response = await axios.post(
        weburls.Add_OneOffBudget + uuid,
        oneOffBudget
      );
      if (response.status === 200) {
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 2000,
        });
        setModalOpen(false);
        fetchExpense();
      } else if (response.status === 404) {
        toast.error("Something went wrong please try again.", {
          position: "top-right",
          autoClose: 2000,
        });
      }
    } catch (error) {
      setModalOpen(false);
      toast.error("Something went wrong please try again.", {
        position: "top-right",
        autoClose: 2000,
      });
    }
    reset();
  };

  return (
    <div className={`modal-background ${modalOpen ? "open-modal" : ""} `}>
      <div className="modal-content">
        <button onClick={handleClose} className="close-modal-button">
          <i class="fa fa-times"></i>
        </button>
        <div className="setting_background_color">
          <div className="d-flex justify-content-between">
            <div className="fs-3">
              <span className="bold black big-top-heading">
                Add your One Off Budgets
              </span>
            </div>
          </div>
          <hr />
          <form onSubmit={handleSubmit(onSubmitHandler)}>
            <div className="row add-expense-wrp">
              <div className="col-md-12 col-sm-12 col-xl-5 mb-3">
                <h5 className="bold black heading-new">
                  One Off Budgets details
                </h5>
                <div className="setting_text_color">
                  This will be displayed on your profile.
                </div>
              </div>
              <div className="col-md-12 col-sm-12 col-xl-7">
                <div className="row">
                  <div className="col-md-12 col-xl-6">
                    <label htmlFor="event-start" className="mb-1 labels-text">
                      Name :
                    </label>
                    <div class="input-group mb-3">
                      <input
                        type="text"
                        name="name"
                        class="form-control input_border"
                        placeholder="Add your name"
                        {...register("name", {
                          onChange: (e) => {
                            handleChange(e);
                          },
                        })}
                      />
                    </div>
                    <p className="error-message">{errors.name?.message}</p>
                  </div>
                  <div className="col-md-12 col-xl-6">
                    <label htmlFor="event-start" className="mb-1 labels-text">
                      Amount :
                    </label>
                    <div class="input-group mb-3">
                      <input
                        type="text"
                        name="amount"
                        class="form-control input_border"
                        placeholder="Add your amount"
                        {...register("amount", {
                          onChange: (e) => {
                            handleChange(e);
                          },
                        })}
                      />
                    </div>
                    <p className="error-message">{errors.amount?.message}</p>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-xl-6 col-md-12">
                    <label htmlFor="event-start" className="mb-1 labels-text">
                      Colour :
                    </label>
                    <div
                      className="color-option"
                      style={{ marginRight: "90px" }}
                    >
                      <div className="dropdown">
                        <button
                          type="button"
                          className={`group-button ${colorCss(
                            oneOffBudget.colour
                          )} popoup-color`}
                          onClick={togglePopupColor}
                          aria-haspopup="true"
                          aria-expanded={isPopupOpenColor}
                          id="colorPickerButton"
                        >
                          {oneOffBudget.colour
                            ? oneOffBudget.colour
                            : "Dark Green"}
                        </button>

                        {isPopupOpenColor && (
                          <PopupColorModal
                            isOpen={isPopupOpenColor}
                            onClose={togglePopupColor}
                            targetId="colorPickerButton"
                            title="Date Range"
                            options={[
                              "Red",
                              "Yellow",
                              "Blue",
                              "Purple",
                              "Orange",
                              "Pink",
                              "Light blue",
                              "Light green",
                              "Green",
                            ]}
                            selectedOption={oneOffBudget.colorOption}
                            handleOptionClick={handleOptionColorClick}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-xl-6">
                    <label htmlFor="event-start" className="mb-1 labels-text">
                      Emoji :
                    </label>
                    <div class="input-group mb-3">
                      <input
                        type="text"
                        name="emoji"
                        class="form-control input_border"
                        placeholder="Add your emoji"
                        {...register("emoji", {
                          onChange: (e) => {
                            handleChange(e);
                          },
                        })}
                      />
                    </div>
                    <p className="error-message">{errors.emoji?.message}</p>
                  </div>
                </div>
                <div className="row">
                  <div class="button modal-popup-btn-wrp">
                    <button type="submit" class="comn-btn common-btn-style">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddOneOffBudget;
