import React from "react";
import CategoryTag from "../../Components/CategoryTag";
import Amount from "../../Components/Amount.jsx";
import moment from "moment";

const MerchantTransactions = ({ transaction, onClick }) => {
  return (
    <div
      className="mer-list-container d-flex align-items-center justify-content-between"
      onClick={() => onClick(transaction)}
    >
      <div className="d-flex align-items-center gap-2">
        <img className="mer-list-img" src={transaction.merchantLogo} />
        <div className="d-flex flex-column">
          <span className="mer-name">{transaction.transactionName}</span>
          <CategoryTag
            emoji={transaction.masterCategoryEmoji}
            name={transaction.masterCategoryName}
            colour={transaction.masterCategoryColour}
          />
        </div>
      </div>
      <div>{moment(transaction.dateTime).format("DD/MM/YYYY")}</div>
      <div
        className={`status ${
          transaction.status === "Completed" ? "mer-complete" : "mer-pending"
        }`}
      >
        <span>{transaction.status}</span>
      </div>
      <div className="mer-amount">
        <Amount amount={transaction.amount} />
      </div>
    </div>
  );
};

export default MerchantTransactions;
