import React from "react";
import { Popover, PopoverBody } from "reactstrap";
import { howOftencolorCss } from "../../../../utilities/Statics";

const HowOften = ({ isOpen, onClose, targetId, handleFrequencySelection }) => {
  return (
    isOpen && (
      <Popover
        placement="bottom"
        isOpen={isOpen}
        target={targetId}
        toggle={onClose}
        className="custom-popover-w-30"
      >
        <PopoverBody>
          <div className="transaction-categories">
            <div className="d-flex justify-content-between">
              <div className="fs-3">
                <span className="bold black popup-top-heading">How often?</span>
              </div>
              <button onClick={onClose} className="close-modal-button">
                <i className="fa fa-times"></i>
              </button>
            </div>

            <div
              className="popover-live mt-3 mb-2"
              style={{ float: "left", fontSize: "14px" }}
            >
              Frequency
            </div>
            <div
              className="categories group-btn green-btn mb-3"
              style={{ float: "left" }}
            >
              {[
                "Weekly",
                "Fortnightly",
                "Monthly",
                "Quarterly",
                "Annual",
                "Custom",
              ].map((frequency) => (
                <div key={frequency} className="button-blck-color mb-2">
                  <a
                    href="#"
                    className="group-button"
                    onClick={() => handleFrequencySelection(frequency)}
                    style={{
                      backgroundColor: howOftencolorCss(frequency),
                      color: "white",
                    }}
                  >
                    {frequency}
                  </a>
                </div>
              ))}
            </div>
          </div>
        </PopoverBody>
      </Popover>
    )
  );
};

export default HowOften;
