import React, { useEffect, useState, useRef, useCallback } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "../AddIncome/addIncome.css";
import "../../ModalForm/ChangePassword/index.css";
import "./index.css";

import moment from "moment";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CategoryTag from "../../Components/CategoryTag/index.jsx";
import weburls from "../../Weburls/weburls.jsx";
import axios from "axios";
import { useParams } from "react-router-dom";
import { ReactComponent as SearchIcon } from "../../assests/images/icons/serch-icon-rule.svg";
import { ReactComponent as Search } from "../../assests/images/icons/Search.svg";
import { ReactComponent as CrossIcon } from "../../assests/images/icons/cross-icon.svg";
import { categoryBackgroundCss } from "../../utilities/Statics/index.jsx";
import UpdateTransactionRuleModel from "./UpdateTransactionRuleModel.jsx";
import { PopUpRuleUpdate } from "../../Components/BudgetDetails/PopUpForUpdateDelete.jsx";
import { toast } from "react-toastify";
import ReactDOM from "react-dom";
import { Loading } from "../../Components/Loading/Loading.jsx";
import { post } from "jquery";

const schema = yup.object().shape({
  name: yup.string().required("Name is required"),
});

const AddTransactionRuleModel = ({
  modalOpen,
  closeModal,
  expenseCategories,
  incomeCategories,
  name = "",
}) => {
  const { uuid } = useParams();
  const token = localStorage.getItem("budget-token");
  const [transactionType, setTransactionType] = useState("Income");
  const [modals, setModals] = useState({
    updateTransactionRules: false,
  });
  const openUpdateModal = (modal, data) => {
    setModals({ ...modals, [modal]: true });
  };
  const closeUpdateModal = (modal) => {
    setModals({ ...modals, [modal]: false });
  };
  const [selectedCategory, setSelectedCategory] = useState({
    id: 0,
    emoji: "",
    name: "Select a category",
    colour: "",
  });
  const [selectedUpdateRule, setSelectedUpdateRule] = useState({});
  const [transactionRuleData, setTransactionRuleData] = useState([]);
  const [search, setSearch] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [showUpdatePopup, setShowUpdatePopup] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: name,
    },
  });

  const onSubmit = (data) => {
    setFormData(data);
    if (selectedCategory.id === 0) {
      toast.error("Please select category", {
        position: "top-right",
        autoClose: 2000,
      });
    } else {
      setShowUpdatePopup(true);
    }
  };

  // Fetch transaction rule data
  const fetchTransactionRule = useCallback(async () => {
    try {
      const response = await axios.get(
        `${weburls.Get_Transaction_rule}${uuid}`,
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );
      setTransactionRuleData(response.data);
    } catch (error) {
      console.error("Error fetching transaction rules:", error);
    }
  }, [uuid]);

  useEffect(() => {
    if (!modalOpen) {
      reset({ name: "" });
    }
  }, [modalOpen, reset]);

  useEffect(() => {
    fetchTransactionRule();
  }, [uuid, fetchTransactionRule]);

  const onCategoryChange = (data) => {
    const { userCategoryId, emoji, colour, name } = data;
    setSelectedCategory({
      id: userCategoryId,
      colour: colour,
      emoji: emoji,
      name: name,
    });
  };

  const onUpdateRuleChange = (rule) => {
    setSelectedUpdateRule(rule);
    openUpdateModal("updateTransactionRules");
  };
  const addTransactionRule = async (bodyData) => {
    setShowUpdatePopup(false);
    setLoading(true);
    try {
      const response = await axios.post(
        `${weburls.Add_Transaction_rule}`,
        bodyData,
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );

      if (response?.data.code === 1) {
        toast.success(response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
      }
      if (response?.data.code === 0) {
        toast.error(response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
      }
      reset({ name: "" });
      fetchTransactionRule();
      setSelectedCategory({
        id: 0,
        colour: "",
        emoji: "",
        name: "Select a category",
      });
      setLoading(false);
    } catch (error) {
      toast.error("Somthing went wrong...");
    }
  };

  const handleUdpateConfirm = async (confirmed) => {
    const bodyData = {
      userUuid: uuid,
      ruleType: transactionType,
      ruleName: formData.name,
      userCategoryId: selectedCategory.id,
      isCategorized: confirmed,
    };
    await addTransactionRule(bodyData);
    // closeModal("addTransactionRules");
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const isHexColor = (color) => /^#([0-9A-Fa-f]{3}){1,2}$/.test(color);

  // Filter the transaction rules based on the search input
  const filteredRules = transactionRuleData.filter((rule) =>
    rule.rule_name.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <>
      {loading &&
        ReactDOM.createPortal(
          <div className="overlay-popup-loading">
            <Loading />
          </div>,
          document.body
        )}
      <div className={`modal-background ${modalOpen ? "open-modal" : ""}`}>
        <div className="modal-content add-rule-width">
          <button
            onClick={() => closeModal("addTransactionRules")}
            className="close-pass-button"
          >
           <CrossIcon/>
          </button>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="">
              <div className="d-flex justify-content-between">
                <div className="fs-3">
                  <span className="bold black password-heading">
                    Transaction rules
                  </span>
                </div>
              </div>

              <div className="add-rules-box shadow mt-4">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <div className="fw-bold">Transaction type</div>
                  <div className="account-input">
                    <div
                      className={`account-select cursor-pointer ${
                        transactionType === "Expense" && "account-select-income"
                      }`}
                      name="accountType"
                      onClick={() => {
                        setTransactionType(
                          transactionType === "Expense" ? "Income" : "Expense"
                        );
                        setSelectedCategory({
                          id: 0,
                          emoji: "",
                          name: "Select a category",
                          colour: "",
                        });
                      }}
                    >
                      <span className="d-flex justify-content-center">
                        {transactionType}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="d-flex justify-content-between align-items-center mb-3">
                  <div className="fw-bold">IF the transaction</div>
                  <div className="account-input">
                    <div className="account-select cursor-pointer Expense">
                      <span className="d-flex justify-content-center">
                        Name
                      </span>
                    </div>
                  </div>
                </div>

                <div className="d-flex justify-content-between mb-3">
                  <div className="fw-bold">Equals</div>
                  <div className="d-flex flex-column">
                    <div className="account-input">
                      <Controller
                        name="name"
                        control={control}
                        render={({ field }) => (
                          <input
                            type="text"
                            {...field}
                            style={{ width: "266px" }}
                          />
                        )}
                      />
                    </div>
                    {errors.name && (
                      <span className="error-message mt-1">
                        {errors?.name?.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="d-flex justify-content-between align-items-center">
                  <div className="fw-bold">Then categorise it to</div>
                  <div className="account-input">
                    <CategoryTag
                      emoji={selectedCategory.emoji}
                      name={selectedCategory.name}
                      colour={selectedCategory.colour}
                      expenseCategories={expenseCategories}
                      incomeCategories={incomeCategories}
                      amount={transactionType === "Expense" ? -1 : 1}
                      uniqueID={1}
                      updateCategory={onCategoryChange}
                      status={""}
                    />
                  </div>
                </div>
              </div>

              {/* Search and Filter Section */}
              <div className="trans-rule-title mt-3 d-flex justify-content-between">
                <div>Your active rules</div>
                <div>
                  <span
                    onClick={() => setIsSearch(!isSearch)}
                    className="cursor-pointer"
                  >
                    <SearchIcon />
                  </span>
                </div>
              </div>

              {isSearch && (
                <div className="search-merchant your-merchant-search">
                  <form>
                    <input
                      type="text"
                      value={search}
                      className="search-box-input py-2 inter add-goal full-width-transaction"
                      onChange={handleSearch}
                    />
                    <Search className="search-merchant" />
                  </form>
                </div>
              )}

              {/* Scrollable Rule List */}
              <div className=" trns-rule-list-box rule-list-scrollable">
                {filteredRules && filteredRules.length > 0 ? (
                  filteredRules.map((rule) => (
                    <div
                      className="trns-rule-box mb-2 cursor-pointer"
                      key={rule.id}
                      onClick={() => onUpdateRuleChange(rule)}
                    >
                      <div className="d-flex justify-content-between align-items-center">
                        <div
                          className="fw-500"
                          style={{ fontSize: "14px", width: "68%" }}
                        >
                          If{" "}
                          <span
                            className={
                              rule.rule_type === "Expense"
                                ? "trns-rule-expense"
                                : "trns-rule-income"
                            }
                          >
                            {rule.rule_type}
                          </span>{" "}
                          transaction{" "}
                          <span className="trns-rule-income"> name </span>equals
                          '{rule.rule_name}'
                        </div>
                        <div className="account-input">
                          <div
                            className="icon-tag"
                            style={{ marginBottom: "0px" }}
                          >
                            {rule.name !== "Uncategorised" &&
                              rule.name !== "Select a category" && (
                                <div
                                  className={`icon-transaction-img ${
                                    isHexColor(rule.colour)
                                      ? ""
                                      : categoryBackgroundCss(rule.colour)
                                  }`}
                                  style={{
                                    background: isHexColor(rule.colour)
                                      ? rule.colour
                                      : {},
                                  }}
                                >
                                  {rule.emoji}
                                </div>
                              )}
                            <div className="content">
                              <h6 className="icon-content">{rule.name}</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="no-rules mt-3"></div>
                )}
              </div>

              <button
                type="submit"
                className="add-transaction-rules-btn cursor-pointer mt-3"
              >
                Add transaction rule
              </button>
            </div>
          </form>
        </div>
        {modals.updateTransactionRules && (
          <UpdateTransactionRuleModel
            modalOpen={modals.updateTransactionRules}
            closeModal={closeUpdateModal}
            expenseCategories={expenseCategories}
            incomeCategories={incomeCategories}
            data={selectedUpdateRule}
            fetchTransactionRule={fetchTransactionRule}
          />
        )}
        {showUpdatePopup && (
          <PopUpRuleUpdate handleChanges={handleUdpateConfirm} />
        )}
      </div>
    </>
  );
};

export default AddTransactionRuleModel;
