import React, { useEffect, useState } from "react";
import "./IncomeExpenseBudgetModal.css";
import HowOften from "./HowOften";
import PopupColorModal from "../../../../ModalForm/Popup/PopupColorModel";
import EmojiPicker from "emoji-picker-react";
import EditDetails from "./EditDetails";
import axios from "axios";
import weburls from "../../../../Weburls/weburls";
import { useParams } from "react-router-dom";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import { ReactComponent as Add_round_cross } from "../../../../assests/images/icons/Add_round_cross.svg";
import OverlayContainer from "../../../OverlayContainer/OverlayContainer";
import { Loading } from "../../../Loading/Loading";
import {
  colorCss,
  colorHexMap,
  getCustomCodeToName,
  hexToColorMap,
  predefinedColors,
  selectionColor,
} from "../../../../utilities/Statics";
import { AutoCategorise, PopUpForCustomBudget } from "./PopUpCreatingBudget";

const IncomeExpenseBudgetModal = ({
  isOpen,
  toggleModal,
  mainHead,
  isIncome,
  fetchBudgetData,
  isModalOpen,
  selectedPeriod,
}) => {
  const { uuid } = useParams();
  const token = localStorage.getItem("budget-token");
  const [selectedFrequency, setSelectedFrequency] = useState("Weekly");
  const [isFrequencyModalOpen, setFrequencyModalOpen] = useState(false);
  const [selectedColorOption, setSelectedColorOption] = useState("Red");
  const [isPopupOpenColor, setPopupOpenColor] = useState(false);

  useEffect(() => {
    if (!isModalOpen) {
      setPopupOpenColor(false);
    }
  }, [isModalOpen]);

  const togglePopupColor = () => {
    if (isModalOpen) {
      setPopupOpenColor(!isPopupOpenColor);
    }
  };

  const [showPicker, setShowPicker] = useState(false);
  const [chosenEmoji, setChosenEmoji] = useState("😁");
  const [amount, setAmount] = useState("$");
  const [isSpreadToggled, setIsSpreadToggled] = useState(false);
  const [needOrWant, setNeedOrWant] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [budgetExpenseResponse, setBudgetExpenseResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showAutoCategorise, setShowAutoCategorise] = useState(false);
  const [showCustomBudget, setShowCustomBudget] = useState(false);
  const [incomeApi, setIncomeApi] = useState({});
  const [expenseApi, setExpenseApi] = useState({});
  const [mainSelectedCategory, setMainSelectedCategory] = useState();
  const checkIncomeOrExpense = mainHead?.trim().toLowerCase();
  const [editName, setEditName] = useState("");
  const [type, setType] = useState("");

  useEffect(() => {
    if (checkIncomeOrExpense === "expense" && budgetExpenseResponse?.data) {
      setAmount(`$${budgetExpenseResponse.data.amount}`);
      setSelectedFrequency(budgetExpenseResponse.data.howOften || "Weekly");
      setSelectedColorOption(
        getCustomCodeToName(budgetExpenseResponse.data.colour) || "Red"
      );
    } else {
      setAmount("$");
      setSelectedFrequency("Weekly");
      setSelectedColorOption("Red");
    }
  }, [checkIncomeOrExpense, budgetExpenseResponse]);

  const handleNeedToggleClick = () => {
    setNeedOrWant(!needOrWant);
  };
  const handleOptionColorClick = (option) => {
    setSelectedColorOption(colorHexMap[option] || option);
    setPopupOpenColor(false);
  };

  const handleToggleClick = () => {
    setIsSpreadToggled(!isSpreadToggled);
  };

  const handleAmountChange = (e) => {
    const value = e.target.value;

    const numericValue = value.replace(/^\$/, "");

    if (/^\d*\.?\d*$/.test(numericValue)) {
      setAmount(`$${numericValue}`);
    }
  };

  const handleNameChange = (e) => {
    setEditName(e.target.value);
  };

  const handleFrequencySelection = (frequency) => {
    if (checkIncomeOrExpense === "expense") {
      if (selectedFrequency === "Custom" && frequency !== "Custom") {
        setExpenseApi({});
        setMainSelectedCategory();
      } else if (selectedFrequency !== "Custom" && frequency === "Custom") {
        setMainSelectedCategory();
        setExpenseApi({});
      }
    }
    setSelectedFrequency(frequency);
    setFrequencyModalOpen(false);
  };

  const toggleFrequencyModal = () => {
    setFrequencyModalOpen((prev) => !prev);
  };

  const getButtonBackgroundColor = (frequency) => {
    switch (frequency) {
      case "Weekly":
        return "#479985";
      case "Fortnightly":
        return "#3de1bc";
      case "Monthly":
        return "#3d6ae2";
      case "Quarterly":
        return "#3eb1e1";
      case "Annual":
        return "#39e16f";
      case "Custom":
        return "#8D83FE";
      default:
        return "#479985";
    }
  };

  if (!isOpen) return null;

  const updateTransactionCategory = (transactionId, userCategoryId, type) => {};

  const transaction = {
    transactionUuid: 1,
    amount: 100,
  };

  const handleEmojiClick = (event) => {
    const { emoji } = event;
    setChosenEmoji(emoji);
    setShowPicker(false);
  };

  function getColorValue(colorOption) {
    if (colorOption?.startsWith("#")) {
      return colorOption;
    }

    return colorOption;
  }

  const addIncomeOrExpenseBudget = async () => {
    try {
      const apiData =
        checkIncomeOrExpense === "expense" ? expenseApi : incomeApi;

      const payload = {
        ...(checkIncomeOrExpense === "one off"
          ? {
              name: editName,
              amount: amount.replace("$", ""),
              colour: selectedColorOption,
              emoji: chosenEmoji,
            }
          : {
              [checkIncomeOrExpense === "expense"
                ? "expenseName"
                : "incomeName"]:
                apiData[
                  checkIncomeOrExpense === "expense"
                    ? "expenseName"
                    : "incomeName"
                ] || "",
              [checkIncomeOrExpense === "expense"
                ? "expenseAmount"
                : "incomeAmount"]: amount.replace("$", ""),
              howOften: selectedFrequency,
              colour: getColorValue(selectedColorOption),
              emoji: chosenEmoji,
              needOrWant: needOrWant ? "Need" : "Want",
              isSpreadAcrossPeriods: isSpreadToggled ? true : false,
              // isAutoCategories: true,
              type: "",
              user_category_id: apiData?.user_category_id || 0,
              ...(selectedFrequency === "Custom" && {
                startDate: startDate || "",
                endDate: endDate || "",
              }),
              ...(checkIncomeOrExpense === "expense" && {
                isDefaultCategory: apiData?.isDefaultCategory,
              }),
            }),
      };
      const url =
        checkIncomeOrExpense === "expense"
          ? `${weburls.Add_Expense_Budget}${uuid}`
          : checkIncomeOrExpense === "income"
          ? `${weburls.Add_Income_Budget}${uuid}`
          : `${weburls.Create_OneOff_Budget}${uuid}`;

      const response = await axios.post(`${url}`, payload, {
        headers: {
          authorization: `Basic ${token}`,
        },
      });

      if (response?.data?.code === 1) {
        toast.success(`${response?.data?.message}`);
      } else if (response?.data?.code === 0) {
        toast.error(`${response?.data?.message}`);
      }
    } catch (error) {
      toast.error(
        `Something went wrong while adding ${checkIncomeOrExpense} category`
      );
    }
  };

  const addExpense = async (isAutoCategories) => {
    try {
      const payload = {
        user_category_id: mainSelectedCategory?.categoryId || 0,
        expenseAmount: amount.replace("$", ""),
        howOften: selectedFrequency,
        colour: getColorValue(selectedColorOption),
        emoji: chosenEmoji,
        expenseName: mainSelectedCategory.categoryName || "",
        isDefaultCategory: true,
        needOrWant: needOrWant ? "Need" : "Want",
        isSpreadAcrossPeriods: isSpreadToggled ? true : false,
        startDate: startDate || "",
        endDate: endDate || "",
        isAutoCategories: isAutoCategories,
        type: type,
      };

      const url = `${weburls.Add_Expense_Budget}${uuid}`;

      const response = await axios.post(`${url}`, payload, {
        headers: {
          authorization: `Basic ${token}`,
        },
      });

      if (response?.data?.code === 1) {
        toggleModal();
        setAmount("$");
        setSelectedFrequency("Weekly");
        setSelectedColorOption("Red");
        setChosenEmoji("😁");
        setStartDate("");
        setEndDate("");
        await fetchBudgetData();
      } else if (response?.data?.code === 0) {
        toast.error(`${response?.data?.message}`);
      }
    } catch (error) {
      toast.error(
        `Something went wrong while adding ${checkIncomeOrExpense} category`
      );
    }
  };

  const addIncome = async () => {
    try {
      const payload = {
        incomeName: "",
        incomeAmount: amount.replace("$", ""),
        howOften: selectedFrequency,
        colour: getColorValue(selectedColorOption),
        isSpreadAcrossPeriods: isSpreadToggled ? true : false,
        emoji: chosenEmoji,
        type: type,
        user_category_id: mainSelectedCategory?.categoryId || 0,
        startDate: startDate || "",
        endDate: endDate || "",
      };
      const url = `${weburls.Add_Income_Budget}${uuid}`;

      const response = await axios.post(`${url}`, payload, {
        headers: {
          authorization: `Basic ${token}`,
        },
      });

      if (response?.data?.code === 1) {
        toggleModal();
        setAmount("$");
        setSelectedFrequency("Weekly");
        setSelectedColorOption("Red");
        setChosenEmoji("😁");
        setStartDate("");
        setEndDate("");
        await fetchBudgetData();
      } else if (response?.data?.code === 0) {
        toast.error(`${response?.data?.message}`);
      }
    } catch (error) {
      toast.error(
        `Something went wrong while adding ${checkIncomeOrExpense} category`
      );
    }
  };

  const autoSingleBudgetExpenses = async () => {
    if (!expenseApi?.user_category_id || !uuid) {
      toast.error("Please select a category first.");
      return;
    }
    if (expenseApi?.isDefaultCategory === false) {
      toast.error("Please select a Default categories for the Auto Expense.");
      return;
    }

    setLoading(true);
    try {
      setSelectedFrequency("Monthly");
      const payload = {
        howOften: "Monthly",
        categoryId: expenseApi.user_category_id,
      };
      const response = await axios.post(
        `${weburls.Auto_Single_Budget_Expenses}${uuid}`,
        payload,
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );

      setBudgetExpenseResponse(response?.data);

      if (response?.data?.code === 1) {
        toast.success(`${response?.data?.message}`);
      } else if (response?.data?.code === 0) {
        toast.error(`${response?.data?.message}`);
      }
    } catch (error) {
      toast.error("Something went wrong while adding expense category");
    } finally {
      setLoading(false);
    }
  };

  const handleNext = async (e) => {
    e.preventDefault();
    if (checkIncomeOrExpense === "one off") {
      if (!amount || amount === "$" || !editName) {
        toast.warn(
          "Amount or Name cannot be empty. Please fill in the required fields.",
          {
            position: "top-right",
            autoClose: 3000,
          }
        );
        return;
      } else {
        await addIncomeOrExpenseBudget();
      }
    } else if (checkIncomeOrExpense === "expense") {
      if (
        !amount ||
        amount === "$" ||
        !expenseApi.expenseName ||
        !expenseApi.user_category_id
      ) {
        toast.warn(
          "Amount or Category cannot be empty. Please fill in the required fields.",
          {
            position: "top-right",
            autoClose: 3000,
          }
        );
        return;
      } else {
        if (selectedFrequency === "Custom") {
          setShowCustomBudget(true);
        } else {
          setShowAutoCategorise(true);
        }
      }
      return;
    } else if (checkIncomeOrExpense === "income") {
      if (
        !amount ||
        amount === "$" ||
        !incomeApi.incomeName ||
        !incomeApi.user_category_id
      ) {
        toast.warn(
          "Amount or Category cannot be empty. Please fill in the required fields.",
          {
            position: "top-right",
            autoClose: 3000,
          }
        );
        return;
      } else {
        if (selectedFrequency === "Custom") {
          setShowCustomBudget(true);
        } else {
          addIncome();
        }
      }
    }

    try {
      if (checkIncomeOrExpense === "one off") {
        setEditName("");
        setAmount("$");
        setSelectedColorOption("Red");
        setChosenEmoji("😁");
      }
    } catch (error) {
      console.error("Error while processing next step:", error);
    }
  };

  const handleSaveAndFinish = async () => {
    try {
      if (checkIncomeOrExpense === "one off") {
        if (!amount || amount === "$" || !editName) {
          toast.warn(
            "Amount or Name cannot be empty. Please fill in the required fields.",
            {
              position: "top-right",
              autoClose: 3000,
            }
          );
          return;
        } else {
          await addIncomeOrExpenseBudget();

          toggleModal();

          await fetchBudgetData();
        }
      } else if (checkIncomeOrExpense === "expense") {
        if (
          !amount ||
          amount === "$" ||
          !expenseApi.expenseName ||
          !expenseApi.user_category_id
        ) {
          toast.warn(
            "Amount or Category cannot be empty. Please fill in the required fields.",
            {
              position: "top-right",
              autoClose: 3000,
            }
          );
          return;
        } else {
          if (selectedFrequency === "Custom") {
            setShowCustomBudget(true);
          } else {
            setShowAutoCategorise(true);
          }
          await fetchBudgetData();
          return;
        }
      } else if (checkIncomeOrExpense === "income") {
        if (
          !amount ||
          amount === "$" ||
          !incomeApi.incomeName ||
          !incomeApi.user_category_id
        ) {
          toast.warn(
            "Amount or Category cannot be empty. Please fill in the required fields.",
            {
              position: "top-right",
              autoClose: 3000,
            }
          );
          return;
        } else {
          if (selectedFrequency === "Custom") {
            setShowCustomBudget(true);
          } else {
            await addIncome();
            await fetchBudgetData();
          }
        }
      }
    } catch (error) {
      console.error("Error while saving and finishing:", error);
    }
  };

  const handleAutoCategoriseYes = async () => {
    await addExpense(true);
    setShowAutoCategorise(false);
  };

  const handleAutoCategoriseNo = async () => {
    await addExpense(false);
    setShowAutoCategorise(false);
  };

  const handleCustomBudgetConfirm = async () => {
    if (checkIncomeOrExpense === "income") {
      setIsSpreadToggled(true);
      await addIncome();
    } else {
      await addExpense(true);
    }
    setShowCustomBudget(false);
  };

  const handleCustomBudgetCancel = () => {
    setShowCustomBudget(false);
  };

  const lowerCaseHeading = mainHead.toLowerCase().replace(/\s+/g, "");

  return (
    <div className="modal-overlay">
      <motion.div
        className="modal-container"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 50 }}
        transition={{ duration: 0.5 }}
      >
        <button className="close-button" onClick={toggleModal}>
          <p style={{ width: "31.13px", height: "31.13px" }}>
            <Add_round_cross />
          </p>
        </button>
        <h2 style={{ marginBottom: "0px" }}>Add {mainHead} budget</h2>
        <p>Enter in your details for your {lowerCaseHeading} budget.</p>
        <div className="modal-budget-content">
          <EditDetails
            mainHead={mainHead}
            transaction={transaction}
            updateTransactionCategory={updateTransactionCategory}
            amount={amount}
            needOrWant={needOrWant}
            handleAmountChange={handleAmountChange}
            selectedFrequency={selectedFrequency}
            toggleFrequencyModal={toggleFrequencyModal}
            isSpreadToggled={isSpreadToggled}
            getButtonBackgroundColor={getButtonBackgroundColor}
            handleToggleClick={handleToggleClick}
            showCategory={
              checkIncomeOrExpense === "expense" ||
              checkIncomeOrExpense === "income"
                ? true
                : false
            }
            incomeExpenseBudgetsName={checkIncomeOrExpense}
            handleNeedToggleClick={handleNeedToggleClick}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            setIncomeApi={setIncomeApi}
            setExpenseApi={setExpenseApi}
            autoExpense={checkIncomeOrExpense === "expense" ? true : false}
            autoSingleBudgetExpenses={autoSingleBudgetExpenses}
            expenseOrIncome={mainHead.toLowerCase().replace(/\s+/g, "")}
            handleNameChange={handleNameChange}
            isFrequencyModalOpen={isFrequencyModalOpen}
            setType={setType}
            setChosenEmoji={setChosenEmoji}
            setSelectedColorOption={setSelectedColorOption}
            setMainSelectedCategory={setMainSelectedCategory}
            mainSelectedCategory={mainSelectedCategory}
            selectedPeriod={selectedPeriod}
            setSelectedFrequency={setSelectedFrequency}
          />

          {lowerCaseHeading !== "oneoff" && lowerCaseHeading !== "income" && (
            <p
              style={{ fontWeight: "500", fontSize: "14px", marginTop: "8px" }}
            >
              Note:{" "}
              <span style={{ fontWeight: "400", fontSize: "14px" }}>
                Auto expense amounts are based on an average of the last 3
                months of transaction data.
              </span>
            </p>
          )}

          <h2 className="mt-3">Personalise</h2>
          <div className="modal-section-card">
            <div className="modal-input-group " style={{ marginBottom: "0px" }}>
              <label>Personalised Color</label>
              <div className="color-option">
                <div className="dropdown">
                  <button
                    className={`group-button ${colorCss(
                      selectedColorOption
                    )} popoup-color`}
                    onClick={togglePopupColor}
                    aria-expanded={isPopupOpenColor}
                    type="button"
                    aria-haspopup="true"
                    id="colorPickerButton"
                    style={{
                      color: "white",
                      backgroundColor: selectedColorOption?.startsWith("#")
                        ? selectedColorOption
                        : selectionColor(selectedColorOption),
                      padding: "4px 32px 5px 32px",
                      fontSize: "15px",
                      fontWeight: "500",
                    }}
                  >
                    {selectedColorOption?.startsWith("#") &&
                    hexToColorMap[selectedColorOption]
                      ? hexToColorMap[selectedColorOption]
                      : selectedColorOption?.startsWith("#")
                      ? "Custom"
                      : selectedColorOption}
                  </button>
                  {isPopupOpenColor && (
                    <PopupColorModal
                      isOpen={isPopupOpenColor}
                      targetId="colorPickerButton"
                      onClose={togglePopupColor}
                      title="Pick your colour"
                      options={[
                        "Red",
                        "Yellow",
                        "Blue",
                        "Purple",
                        "Orange",
                        "Pink",
                        "Light blue",
                        "Light green",
                        "Green",
                      ]}
                      selectedOption={selectedColorOption}
                      handleOptionClick={handleOptionColorClick}
                    />
                  )}
                </div>
              </div>
            </div>

            <div
              className="modal-input-group mt-3"
              style={{ marginBottom: "0px", marginTop: "5px" }}
            >
              <label>Personalised emoji</label>
              <div className="d-flex flex-column">
                <div
                  className="py-2 inter add-goal show-emoji"
                  onClick={() => setShowPicker(!showPicker)}
                  style={{ width: "150px" }}
                >
                  {chosenEmoji}
                </div>
                {showPicker && (
                  <div className="category-emoji-container">
                    <EmojiPicker onEmojiClick={handleEmojiClick} />
                  </div>
                )}
              </div>
            </div>
          </div>

          {lowerCaseHeading === "oneoff" ? (
            <div className="modal-actions">
              <button
                style={{ width: "100%" }}
                type="button"
                className="save-button"
                onClick={handleSaveAndFinish}
              >
                Add one off budget
              </button>
            </div>
          ) : (
            <div className="modal-actions">
              <button
                type="button"
                className="save-button"
                onClick={handleSaveAndFinish}
              >
                Save & Finish
              </button>
              <button
                type="button"
                className="next-button"
                onClick={handleNext}
              >
                Next {mainHead}
              </button>
            </div>
          )}

          {/* Frequency Selection Modal */}
          {isFrequencyModalOpen && (
            <HowOften
              isOpen={isFrequencyModalOpen}
              targetId="frequencyPickerButton"
              onClose={toggleFrequencyModal}
              toggleFrequencyModal={toggleFrequencyModal}
              handleFrequencySelection={handleFrequencySelection}
            />
          )}
          {showCustomBudget && (
            <PopUpForCustomBudget
              handleConfirm={handleCustomBudgetConfirm}
              handleCancel={handleCustomBudgetCancel}
            />
          )}
        </div>
        {loading && (
          <OverlayContainer>
            <div>
              <Loading />
            </div>
          </OverlayContainer>
        )}
      </motion.div>
      {showAutoCategorise && (
        <AutoCategorise
          handleYes={handleAutoCategoriseYes}
          handleNo={handleAutoCategoriseNo}
        />
      )}
    </div>
  );
};

export default IncomeExpenseBudgetModal;
