import React from "react";
import PopupColorModal from "../../ModalForm/Popup/PopupColorModel";

import EmojiPicker from "emoji-picker-react";
import {
  colorCss,
  customColourOrName,
  customColourOrNameColour,
  hexToColorMap,
} from "../../utilities/Statics";

const PersonalisedColorEmoji = ({
  selectedColorOption,
  togglePopupColor,
  isPopupOpenColor,
  handleOptionColorClick,
  setShowPicker,
  showPicker,
  chosenEmoji,
  handleEmojiClick,
}) => {
  return (
    <>
      <div className="budget-breakdown-header mt-3">
        <h4> Personalise</h4>
      </div>
      <div
        className="modal-input-group modal-section-card"
        style={{ marginBottom: "0px" }}
      >
        <label>Personalised colour</label>
        <div className="color-option">
          <div className="dropdown">
            <button
              className={`group-button ${customColourOrNameColour(
                selectedColorOption
              )} popoup-color`}
              onClick={togglePopupColor}
              aria-expanded={isPopupOpenColor}
              type="button"
              aria-haspopup="true"
              id="colorPickerButton"
              style={{
                color: "white",
                backgroundColor: customColourOrNameColour(selectedColorOption),
                padding: "4px 32px 5px 32px",
                fontSize: "15px",
                fontWeight: "500",
              }}
            >
            {customColourOrName(selectedColorOption)}
            </button>
            {isPopupOpenColor && (
              <PopupColorModal
                isOpen={isPopupOpenColor}
                targetId="colorPickerButton"
                onClose={togglePopupColor}
                title="Pick your colour"
                options={[
                  "Red",
                  "Yellow",
                  "Blue",
                  "Purple",
                  "Orange",
                  "Pink",
                  "Light blue",
                  "Light green",
                  "Green",
                ]}
                selectedOption={selectedColorOption}
                handleOptionClick={handleOptionColorClick}
              />
            )}
          </div>
        </div>
      </div>

      <div
        className="modal-input-group modal-section-card"
        style={{ marginBottom: "0px", marginTop: "10px" }}
      >
        <label>Personalised emoji</label>
        <div className="d-flex flex-column">
          <div
            className="py-2 inter add-goal show-emoji"
            onClick={() => setShowPicker((prevState) => !prevState)}
            style={{ width: "150px" }}
          >
            {chosenEmoji}
          </div>
          {showPicker && (
            <div
              className="category-emoji-container"
              style={{ left: "50rem", top: "1rem" }}
            >
              <EmojiPicker onEmojiClick={handleEmojiClick} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PersonalisedColorEmoji;
