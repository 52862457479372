import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { ReactComponent as Add } from "../../assests/images/icons/back-goals.svg";
import Profile from "../../assests/images/icons/image.png";
import { ReactComponent as Search } from "../../assests/images/icons/Search.svg";
import Shape from "../../assests/images/icons/shape.png";
import { ReactComponent as Upload } from "../../assests/images/icons/upload.svg";
import MainSidebar from "../../Components/MainSidebar";
import PopupInfoModal from "../../ModalForm/Popup/PopupInfoModel";
import weburls from "../../Weburls/weburls";

const AddTransaction = () => {
  const sidebarRef = useRef(null);
  const { uuid } = useParams();
  const token = localStorage.getItem("budget-token");
  const navigate = useNavigate();
  const [isPopupOpenInfo, setPopupOpenInfo] = useState(false);
  const [merchant, setMerchants] = useState([]);
  const [value, setValue] = useState("");
  const [transactionAvatar, setTransactionAvatar] = useState();
  const [transName, setTransName] = useState("");
  const [transAmount, setTransAmount] = useState("");
  const [transDate, setTransDate] = useState(null);

  const [merchantName, setMerchantName] = useState("");
  const [merchantImg, setMerchantImg] = useState("");
  const [merchantId, setMerchantId] = useState("");

  const [accounts, setAccounts] = useState([]);
  const [accountName, setAccountName] = useState("");
  const [accountImg, setAccountImg] = useState("");
  const [accountId, setAccountId] = useState("");

  const [transactionType, setTransactionType] = useState("Income");
  const [categoryName, setCategoryName] = useState("Insurance");
  const [categoryIcon, setCategoryIcon] = useState("💰");
  const [categoryColor, setCategoryColor] = useState("insurance-img");

  const togglePopupInfo = () => setPopupOpenInfo(!isPopupOpenInfo);
  const togglecategoryInfo = (name, icon, color) => {
    setCategoryName(name);
    setCategoryIcon(icon);
    setCategoryColor(color);
  };

  const getMerchantsByClientBy = useCallback(async () => {
    try {
      const response = await axios.get(
        `${weburls.Get_Merchats_By_ClientId}${uuid}`,
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );
      setMerchants(response.data?.merchants);
      if (response.data?.merchants.length > 0) {
        setMerchantName(response.data?.merchants[0].name);
        setMerchantImg(response.data?.merchants[0].logo);
        setMerchantId(response.data?.merchants[0].id);
      }
    } catch (err) {
      toast.error(err);
    }
  }, [uuid]);

  const fetchCashAccount = useCallback(async () => {
    try {
      const response = await axios.get(`${weburls.Get_Cash_Account}${uuid}`, {
        headers: {
          authorization: `Basic ${token}`,
        },
      });
      setAccounts(response?.data?.accounts);
      if (response?.data?.accounts.length > 0) {
        setAccountName(response?.data?.accounts[0].accountName);
        setAccountImg(response?.data?.accounts[0].avatar);
        setAccountId(response?.data?.accounts[0].id);
      }
    } catch (err) {
      toast.error(err);
    }
  }, [uuid]);

  useEffect(() => {
    getMerchantsByClientBy();
    fetchCashAccount();
  }, [getMerchantsByClientBy, fetchCashAccount]);

  const updateFilteredMerchants = () => {
    const sortedMerchants =
      merchant &&
      merchant?.length > 0 &&
      merchant.slice().sort((a, b) => b.amount - a.amount);

    return (
      sortedMerchants?.length > 0 &&
      sortedMerchants?.filter((it) => {
        const filterMerchants = it.name
          .toLowerCase()
          .includes(value.toLowerCase());

        return filterMerchants;
      })
    );
  };

  const filteredMerchant = updateFilteredMerchants();

  const toggleMerchantInfo = (id) => {
    const selected = filteredMerchant.filter((it) => it.id === id);
    setMerchantName(selected[0].name);
    setMerchantImg(selected[0].logo);
    setMerchantId(selected[0].id);
  };

  const toggleAccountInfo = (id) => {
    const selected = accounts.filter((it) => it.id === id);
    setAccountName(selected[0].accountName);
    setAccountImg(selected[0].avatar);
    setAccountId(selected[0].id);
  };

  const upload = (file) => {
    setTransactionAvatar(file.target.files[0]);
  };

  const saveTransaction = async () => {
    const formData = new FormData();
    formData.append("userUuid", uuid);
    formData.append("trans_name", transName);
    formData.append("trans_date", transDate);
    formData.append("trans_amount", Number(transAmount));
    formData.append("merchant_id", merchantId);
    formData.append("accound_id", accountId);
    formData.append("avatar", transactionAvatar);
    formData.append("trans_type", transactionType.toUpperCase());
    try {
      const response = await axios.post(weburls.Add_Transaction, formData, {
        headers: {
          authorization: `Basic ${token}`,
        },
      });
      if (response.status === 200) {
        toast.success("Transaction successfully..", {
          position: "top-right",
          autoClose: 2000,
        });
        navigate(`/Transactions/${uuid}`);
      } else if (response.status === 500) {
        toast.error("Transaction details not saved. Please try again.");
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  return (
    <>
      <div className="app-main__outer your-account-balances-container-main">
        <ToastContainer position="top-right" autoClose={5000} closeOnClick />
        <div className="row top-nav">
          <div className="col-md-12 col-xl-4 col-lg-4 col-sm-12 page-heading-title-sub-title">
            <div className="bold big-head-new">Add transaction</div>
            <div className="fst-normal setting_text_color">
              Here’s all of your transactions from all accounts
            </div>
          </div>
          <div className="col-md-12 col-xl-8 col-lg-8 col-sm-12 right-section back-to-portal-top">
            <div className="right-section">
              <div className="profile-dashboard">
                <Link to={`/Transactions/${uuid}`}>
                  <div
                    className="edit-btn"
                    href-="#"
                    style={{ width: "300px", height: "66px" }}
                  >
                    <div className="right">
                      <Link to={`/Transactions/${uuid}`}>
                        <Add />
                      </Link>
                    </div>
                    <div className="left" style={{ marginLeft: "15px" }}>
                      <h4 className="bold">Back to transactions</h4>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="profile-dashboard">
                <img
                  src={Shape}
                  alt="Mortage_img"
                  onClick={togglePopupInfo}
                  style={{ width: "20px", marginLeft: "20px" }}
                />
              </div>
              {isPopupOpenInfo && (
                <PopupInfoModal
                  isOpen={isPopupOpenInfo}
                  onClose={togglePopupInfo}
                  title="Transactions 💳"
                  description={[
                    "Your transactions are all shown here, including the category for each one.",
                    "To view more transactions, you can keep scrolling down. You can filter by clicking the different filter fields up the top. If you want to dive deeper and find specific transactions, you can use the search function by clicking the icon.",
                    "If you’d like to add a transaction that hasn’t been pulled through from your connected account, you can tap the + icon.",
                    "If you’d like to allocate a transaction to a different category, click the category button below the name and then select the category using the ‘Select category’ option field.",
                  ]}
                />
              )}
              <div className="profile-dashboard">
                <div className="profile-img">
                  <img src={Profile} alt="profile" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          {/* Add Transaction */}
          <div className="col-xl-8 col-lg-4 col-md-6 acccount-balances-wrappper-left-side">
            <div className="cash-account-box">
              <form className="row">
                <div className="field-form col-xl-6">
                  <label className="label-text">Name</label>
                  <input
                    type="text"
                    name="transName"
                    value={transName}
                    className="edit-goal-house w-75 py-2 inter add-goal full-width-transaction"
                    style={{ border: "none" }}
                    onChange={(e) => {
                      setTransName(e.target.value);
                    }}
                  />
                </div>
                <div className="field-form col-xl-3">
                  <label className="label-text">Date</label>
                  <input
                    type="date"
                    name="transDate"
                    value={transDate}
                    className="edit-goal-house w-75 py-2 inter add-goal full-width-transaction"
                    style={{ border: "none" }}
                    onChange={(e) => {
                      setTransDate(e.target.value);
                    }}
                  />
                </div>
                <div className="field-form col-xl-3">
                  <label className="label-text">Amount</label>
                  <input
                    type="text"
                    name="transAmount"
                    value={transAmount}
                    className="edit-goal-house w-75 py-2 inter add-goal full-width-transaction"
                    style={{ border: "none" }}
                    onKeyPress={(e) => {
                      const isValidInput = /^[0-9\b.]+$/.test(e.key);

                      if (!isValidInput) {
                        e.preventDefault();
                      }
                    }}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      const hasMultipleDots = inputValue.split(".").length > 2;
                      const isValidInput =
                        /^[0-9]*\.?[0-9]*$/.test(inputValue) &&
                        !hasMultipleDots;

                      if (isValidInput) {
                        setTransAmount(inputValue);
                      }
                    }}
                  />
                </div>
                <div className="field-form col-xl-3">
                  <label className="label-text">Category</label>
                  <div className="button-blck">
                    <div className="icon-tag" style={{ width: "125px" }}>
                      <div className={`icon-transaction-img ${categoryColor}`}>
                        {categoryIcon}
                      </div>
                      <div className="content">
                        <h6 className="icon-content ">{categoryName}</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="field-form col-xl-3">
                  <label className="label-text">Merchant</label>
                  <div className="button-blck">
                    <div
                      className="icon-tag"
                      style={{ width: "217px", textAlign: "center" }}
                    >
                      <div className="icon-transaction-img insurance-img">
                        <img
                          src={merchantImg}
                          alt="Mortage_img"
                          style={{ borderRadius: "50%" }}
                        />
                      </div>
                      <div className="content">
                        <h6 className="icon-content ">{merchantName}</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="field-form col-xl-3">
                  <label className="label-text">Account</label>
                  <div className="button-blck">
                    <button
                      type="button"
                      className={`group-button sky-blue-btn popoup-color`}
                      aria-haspopup="true"
                    >
                      {accountName}
                    </button>
                    <img
                      className="saving-img"
                      src={accountImg}
                      alt="Mortage_img"
                      style={{ borderRadius: "50%" }}
                    />
                  </div>
                </div>
                <div className="field-form col-xl-3">
                  <label className="label-text">Transaction type</label>
                  <button
                    type="button"
                    className={`group-button ${
                      transactionType === "Income"
                        ? "dark-green-color"
                        : "red-color"
                    } popoup-color`}
                    aria-haspopup="true"
                  >
                    {transactionType}
                  </button>
                </div>
                <div className="field-form col-xl-8">
                  <label className="label-text">Images</label>
                  <input
                    type="file"
                    class="edit-goal-house w-75 py-2 inter add-goal full-width-transaction"
                    placeholder="Assets Image"
                    id="trans-file"
                    name="file-input"
                    onChange={upload}
                  />
                  <div className="upload">
                    <Upload
                      className="upload-logo"
                      style={{ marginTop: "10px" }}
                    />
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-popup-btn-wrp" style={{ marginTop: "20px" }}>
              <button
                className="comn-btn"
                type="button"
                onClick={saveTransaction}
              >
                Add Transaction
              </button>
            </div>
          </div>

          <div className="col-lg-4 search-spacing your-account-bal-add-cash">
            {/* Select Category */}
            <div className="cash-account-box">
              <div className="group-backgroud-color" style={{ padding: 0 }}>
                <div className="bold black">Select Category</div>
                <div className="group-btn green-btn mt-4">
                  <div
                    className="button-blck"
                    onClick={() =>
                      togglecategoryInfo("Travel", "✈️", "pink-img")
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <div className="icon-tag">
                      <div className="icon-transaction-img pink-img ">✈️</div>
                      <div className="content">
                        <h6 className="icon-content ">Travel</h6>
                      </div>
                    </div>
                  </div>
                  <div
                    className="button-blck"
                    onClick={() =>
                      togglecategoryInfo("Insurance", "💰", "insurance-img")
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <div className="icon-tag" style={{ width: "125px" }}>
                      <div className="icon-transaction-img insurance-img">
                        💰
                      </div>
                      <div className="content">
                        <h6 className="icon-content ">Insurance</h6>
                      </div>
                    </div>
                  </div>
                  <div
                    className="button-blck"
                    onClick={() =>
                      togglecategoryInfo("Utilities", "🔌", "plug-img")
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <div className="icon-tag">
                      <div className="icon-transaction-img plug-img">🔌</div>
                      <div className="content">
                        <h6 className="icon-content ">Utilities</h6>
                      </div>
                    </div>
                  </div>
                  <div
                    className="button-blck"
                    onClick={() =>
                      togglecategoryInfo("Eating Out", "🌯", "eating-img")
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <div className="icon-tag" style={{ width: "125px" }}>
                      <div className="icon-transaction-img eating-img">🌯</div>
                      <div className="content">
                        <h6 className="icon-content ">Eating Out</h6>
                      </div>
                    </div>
                  </div>
                  <div
                    className="button-blck"
                    onClick={() =>
                      togglecategoryInfo("Groceries", "🛒", "groceries-img")
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <div className="icon-tag" style={{ width: "125px" }}>
                      <div className="icon-transaction-img groceries-img">
                        🛒
                      </div>
                      <div className="content">
                        <h6 className="icon-content ">Groceries</h6>
                      </div>
                    </div>
                  </div>
                  <div
                    className="button-blck"
                    onClick={() =>
                      togglecategoryInfo("Shopping", "🛍️", "shopping-img")
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <div className="icon-tag" style={{ width: "125px" }}>
                      <div className="icon-transaction-img shopping-img">
                        🛍️
                      </div>
                      <div className="content">
                        <h6 className="icon-content ">Shopping</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Select Merchant */}
            <div className="cash-account-box mt-3">
              <div className="group-backgroud-color" style={{ padding: 0 }}>
                <div className="bold black">Select Merchant</div>
                <div className="col-md-11 col-lg-11 mt-3">
                  <div
                    className="bold heading-new"
                    style={{ fontSize: "16px" }}
                  >
                    Search
                  </div>
                  <div>
                    <div className="search-merchant your-merchant-search">
                      <form>
                        <input
                          type="text"
                          value={value}
                          className="search search-color-white"
                          onChange={(e) => setValue(e.target.value)}
                          style={{
                            boxShadow: "0px 5px 30px 0px rgba(0, 0, 0, 0.1)",
                          }}
                        />
                        <Search className="search-merchant" />
                      </form>
                    </div>
                  </div>
                </div>
                <div className="group-btn green-btn mt-4">
                  {merchant &&
                    filteredMerchant.length > 0 &&
                    filteredMerchant.map((it, index) => (
                      <div
                        className="button-blck"
                        onClick={() => toggleMerchantInfo(it.id)}
                        style={{ cursor: "pointer" }}
                      >
                        <div
                          className="icon-tag-merchant"
                          style={{ width: "217px" }}
                        >
                          <div className="icon-transaction-img insurance-img">
                            <img
                              src={it.logo}
                              alt="Mortgage_img"
                              style={{ borderRadius: "50%" }}
                            />
                          </div>
                          <div className="content">
                            <h6 className="icon-content">{it.name}</h6>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            {/* Select Account */}
            <div className="cash-account-box mt-3">
              <div className="group-backgroud-color" style={{ padding: 0 }}>
                <div className="bold black">Select Account</div>
                <div className="group-btn green-btn mt-4">
                  {accounts &&
                    accounts.length > 0 &&
                    accounts.map((it, index) => (
                      <div
                        className="button-blck saving-mrgbt"
                        onClick={() => toggleAccountInfo(it.id)}
                        style={{ cursor: "pointer" }}
                      >
                        <button
                          type="button"
                          className="group-button popoup-color saving-outline-btn"
                          aria-haspopup="true"
                        >
                          {it.accountName}
                        </button>
                        <img
                          className="saving-img"
                          src={it.avatar}
                          alt="Mortage_img"
                          style={{ borderRadius: "50%" }}
                        />
                      </div>
                    ))}
                </div>
              </div>
            </div>
            {/* Select transaction type */}
            <div className="cash-account-box mt-3">
              <div className="group-backgroud-color" style={{ padding: 0 }}>
                <div className="bold black">Select transaction type</div>
                <div className="group-btn green-btn mt-4">
                  <div className="button-blck">
                    <button
                      type="button"
                      className={`group-button dark-green-color popoup-color`}
                      aria-haspopup="true"
                      onClick={() => setTransactionType("Income")}
                    >
                      Income
                    </button>
                  </div>
                  <div className="button-blck">
                    <button
                      type="button"
                      className={`group-button red-color popoup-color`}
                      aria-haspopup="true"
                      onClick={() => setTransactionType("Expense")}
                    >
                      Expense
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddTransaction;
