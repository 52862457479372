import React from "react";

const PendingTransactionModel = ({ modalOpen, closeModal }) => {
    const handleBackgroundClick = (event) => {
        // Close modal when clicking on background
        closeModal();
      };
  return (
    <div className={`modal-background ${modalOpen ? "open-modal" : ""} `} onClick={handleBackgroundClick}>
      <div className="modal-content pending-modal-width">
        {/* <button onClick={closeModal} className="close-pass-button">
          <i className="fa fa-times"></i>
        </button> */}
        <form>
          <div className="">
            <>
              <div className="d-flex justify-content-between">
                <div className="fs-3">
                  <p className="black pending-heading">
                    Pending transaction ⏳
                  </p>
                  <p className="pending-modal-para mt-3">
                  This is a pending transaction, pending transactions are transactions that haven’t been processed and cleared by your bank yet.
                  </p>
                  <p className="pending-modal-para">
                  Pending transactions can’t be categorised until they’re cleared.
                  </p>
                  <p className="pending-modal-para">
                  Hold tight, pending transactions can take 1-3 days to process.
                  </p>
                </div>
              </div>
            </>
          </div>
          <div className="modal-popup-btn-wrp">
            <button
              className="pending-btn-model cursor-pointer"
              type="submit"
                onClick={closeModal}
            >
              Acknowledge
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PendingTransactionModel;
