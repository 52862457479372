import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BudgetBuddie } from '../../Weburls/weburls';

const OAuthCallbackHandler = () => {
  const [accessToken, setAccessToken] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const getAccessToken = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');

      if (code) {
        try {
          const response = await axios.get(
            `${BudgetBuddie}api/auth/akahu/307975a3-a439-4ddd-9013-85ae5df5aa6e/?code=${code}`,
          );
          if (response.data) {
            setAccessToken(response.data);
          } else {
            console.error('Failed to get access token.');
          }
        } catch (error) {
          console.error('error', error);
        }
      }
    };

    getAccessToken();
    // If the access token is successfully retrieved, redirect to another page.
    if (accessToken) {
      navigate('/connectaccounts/307975a3-a439-4ddd-9013-85ae5df5aa6e');
    }
  }, [accessToken, navigate]);

  return <div>{/* Display a message or loading indicator while getting the access token. */}</div>;
};

export default OAuthCallbackHandler;
