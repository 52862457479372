import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Mortage from '../../assests/images/icons/Mortgage.png';
import weburls from '../../Weburls/weburls';
import { Linecart } from './Linecart';

const Networthprogress = ({ uuid, data, cashAccount }) => {
  const [netWorth, setNetWorth] = useState();
  const fetchNetWorth = useCallback(async () => {
    try {
      const response = await axios.get(`${weburls.Get_NetWorth_Progress}${uuid}`);
      setNetWorth(response.data);
    } catch (error) {
      console.error('error', error);
    }
  }, [setNetWorth, uuid]);

  useEffect(() => {
    fetchNetWorth();
  }, [fetchNetWorth]);

  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
  });
  const networthAmount = netWorth?.length > 0 ? netWorth[netWorth?.length - 1].toLocaleString() : 0;
  const networthStyle = {
    color: networthAmount < 0 ? 'red' : '#4ec376',
  };
  return (
    <>
      <div className="row mt-3">
        <div className="col-md-12 bar-cart col-xl-3 col-sm-12 col-lg-12 net-worth-progress-side">
          <div className="col-md-12 left">
            <h5>Net worth Progress</h5>
          </div>
          <div className="line-cart-net">
            <div className="bar-cart-heading-wrp col-lg-12 col-md-12">
              <div className="top-heading-canvas-price">
                <h4 className="bold black">Net Worth</h4>
                <h5 style={networthStyle}>${networthAmount}</h5>
              </div>
              <h6>{formattedDate}</h6>
            </div>
            <div className="linecartbottom">
              <Linecart netWorth={netWorth} />
            </div>
          </div>
        </div>
        <div className="col-md-12 col-lg-12 col-xl-9 col-sm-12 bar-cart account-balances-side">
          <div className="recent-see-all">
            <div className="col-md-6 mb-4 left title-heading">
              <h5>Account Balances</h5>
            </div>
            <div className="col-md-6 right-section right btn-hover">
              <Link className="group-button green" to={`/AccountBalances/${uuid}`} style={{ fontSize: '13px' }}>
                See All
              </Link>
            </div>
          </div>

          <div className="col-md-12 col-lg-12">
            <div className="saving-acc-wrp row">
              {cashAccount &&
                cashAccount?.length > 0 &&
                cashAccount.map((it) => (
                  <div className="col-md-4 col-lg-4 col-sm-12 col-xl-3 cmn-blck mt-3">
                    <div className="blkc" style={{ height: '130px', width: '300px' }}>
                      <div className="left-section">
                        <div className="saving-head">
                          <h4>{it?.accountName}</h4>
                        </div>
                        <div className="saving-amt">
                          <h3 className="number-heading">${it.value.toFixed(2)}</h3>
                        </div>
                      </div>
                      <div className="right-section col-lg-4 col-xl-4">
                        <div class="img">
                          <img src={it.avatar === '' || it.avatar === null ? Mortage : it.avatar} alt="Mortage_img" />
                        </div>
                      </div>
                    </div>
                    <div className="bottom-wrp">
                      <div className="saving-head">
                        <h5>Name</h5>
                      </div>
                      <h4>{`${data.firstName} ${data.lastName}`}</h4>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Networthprogress;
