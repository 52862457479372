import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "../AddIncome/addIncome.css";
import "../../ModalForm/ChangePassword/index.css";
import "./index.css";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ReactComponent as CrossIcon } from "../../assests/images/icons/cross-icon.svg";
import { ReactComponent as DeleteIcon } from "../../assests/images/custom-trns-delete.svg";
import PopupColorModal from "../Popup/PopupColorModel";
import { toast } from "react-toastify";
import EmojiPicker from "emoji-picker-react";
import {
  colorCss,
  getCustomCodeToName,
  selectionColor,
  categoryColorForAll,
  categoryBackgroundCss,
} from "../../utilities/Statics";
import { PopUpDeleteCategory } from "../../Components/BudgetDetails/PopUpForUpdateDelete";
import axios from "axios";
import weburls from "../../Weburls/weburls";
import { useParams } from "react-router-dom";

const schema = yup.object().shape({
  name: yup.string().required("Name is required"),
});

const EditCategoriesModal = ({
  modalOpen,
  closeModal,
  editCategory,
  transactionType,
  selectedCategory,
  setEditAddCatogory,
  deleteCategoryConfirm,
}) => {
  const isHexColor = (color) => /^#([0-9A-Fa-f]{3}){1,2}$/.test(color);

  const [colour, setColour] = useState("Red");
  const [isPopupOpenColor, setPopupOpenColor] = useState(false);
  const [chosenEmoji, setChosenEmoji] = useState("😁");
  const [showPicker, setShowPicker] = useState(false);
  const [isDefault, setisDefault] = useState(false);
  const [deleteCategoryPopup, setDeleteCategoryPopup] = useState(false);

  const togglePopupColor = () => setPopupOpenColor(!isPopupOpenColor);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: selectedCategory.name,
    },
  });

  const onSubmit = (data) => {
    let formatedData;
    if (transactionType !== "Expense") {
      formatedData = {
        name: data?.name,
        emoji: chosenEmoji,
        colour: colour,
        type: "Income",
      };
    } else {
      formatedData = {
        name: data?.name,
        emoji: chosenEmoji,
        colour: colour,
        type: "Expense",
      };
    }

    editCategory(formatedData, selectedCategory.userCategoryId);
    closeModal();
  };

  const deleteCategoryPopUpHandle = () => {
    setDeleteCategoryPopup(true);
  };

  const deleteCategory = () => {
    setDeleteCategoryPopup(false);
    setEditAddCatogory(false);
    deleteCategoryConfirm(selectedCategory);
  };

  const handleOptionColorClick = (option) => {
    setColour(option);
    setPopupOpenColor(false);
  };

  const handleEmojiClick = (event) => {
    const { emoji } = event;
    setChosenEmoji(emoji);
    setShowPicker(false);
  };

  useEffect(() => {
    setisDefault(selectedCategory?.isDefalutCategory ?? false);
    setChosenEmoji(selectedCategory.emoji);
    setColour(getCustomCodeToName(selectedCategory.colour));
  }, [modalOpen]);

  return (
    <div className={`modal-background ${modalOpen ? "open-modal" : ""} `}>
      <div className="edit-modal-content add-modal-width">
        {
          <button
            onClick={deleteCategoryPopUpHandle}
            className={"delete-edit-category-button"}
          >
            <DeleteIcon />
          </button>
        }
        <button onClick={closeModal} className={"close-edit-category-button"}>
          <CrossIcon />
        </button>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="">
            <>
              <div className="d-flex justify-content-between">
                <div className="fs-3">
                  <span className="bold black password-heading">
                    Edit {transactionType.toLowerCase()} category
                  </span>
                </div>
              </div>
              <div
                className="cash-account-box mt-3"
                style={{ boxShadow: "0px 5px 30px 0px rgba(0, 0, 0, 0.10)" }}
              >
                {isDefault && (
                  <>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <div className="" style={{ fontWeight: "500" }}>
                        Use default category?
                      </div>
                      <div className="color-option">
                        <div
                          className={`group-button ${colorCss(
                            colour
                          )} popoup-color`}
                          style={{
                            color: "white",
                            backgroundColor: "#469B88",
                            padding: "4px 32px 5px 32px",
                            fontSize: "15px",
                            fontWeight: "500",
                          }}
                        >
                          Yes
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {!isDefault && (
                  <>
                    <div className="d-flex align-items-center justify-content-between  mb-3">
                      <div className="" style={{ fontWeight: "500" }}>
                        Name
                      </div>
                      <div className="d-flex flex-column">
                        <div className="account-input">
                          <Controller
                            name="name"
                            control={control}
                            render={({ field }) => (
                              <input
                                type="text"
                                {...field}
                                style={{
                                  width: "270px",
                                  padding: "0px",
                                  height: "35px",
                                  textAlign: "center",
                                }}
                              />
                            )}
                          />
                        </div>
                        {errors.name && (
                          <span className="error-message mt-1">
                            {errors?.name?.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </>
                )}
                {isDefault && (
                  <>
                    <div className="d-flex align-items-center justify-content-between mb-3">
                      <div className="" style={{ fontWeight: "500" }}>
                        Category
                      </div>
                      <div className="single-catefory-icon-tag">
                        {
                          <div
                            className={`icon-transaction-img ${
                              isHexColor(selectedCategory.colour)
                                ? ""
                                : categoryBackgroundCss(selectedCategory.colour)
                            }`}
                            style={{
                              background: categoryColorForAll(
                                selectedCategory.colour
                              ),
                            }}
                          >
                            {selectedCategory.emoji}
                          </div>
                        }
                        <div className="content">
                          <h6 className="icon-content">
                            {selectedCategory.name}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <div className="d-flex justify-content-between align-items-center mt-3 mb-3">
                  <div className="" style={{ fontWeight: "500" }}>
                    Personalised colour
                  </div>
                  <div className="color-option">
                    <div className="dropdown">
                      <button
                        className={`group-button ${colorCss(
                          colour
                        )} popoup-color`}
                        onClick={togglePopupColor}
                        aria-haspopup="true"
                        aria-expanded={isPopupOpenColor}
                        type="button"
                        id="colorPickerButton"
                        style={{
                          color: "white",
                          backgroundColor: getCustomCodeToName(
                            colour
                          ).startsWith("#")
                            ? colour
                            : selectionColor(colour),
                          padding: "4px 32px 5px 32px",
                          fontSize: "15px",
                          fontWeight: "500",
                        }}
                      >
                        {getCustomCodeToName(colour)?.startsWith("#")
                          ? "Custom"
                          : getCustomCodeToName(colour)}
                      </button>

                      {isPopupOpenColor && (
                        <PopupColorModal
                          isOpen={isPopupOpenColor}
                          targetId="colorPickerButton"
                          onClose={togglePopupColor}
                          title="Pick your colour"
                          options={[
                            "Red",
                            "Light green",
                            "Blue",
                            "Green",
                            "Light blue",
                            "Orange",
                            "Yellow",
                            "Purple",
                            "Pink",
                          ]}
                          selectedOption={colour}
                          handleOptionClick={handleOptionColorClick}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="" style={{ fontWeight: "500" }}>
                    Personalised emoji
                  </div>
                  <div className="d-flex flex-column">
                    <div
                      className="py-2 inter add-goal show-emoji"
                      onClick={() => setShowPicker(!showPicker)}
                      style={{ width: "125px", padding: "0px", height: "35px" }}
                    >
                      {chosenEmoji}
                    </div>
                    {showPicker && (
                      <div className="category-emoji-container">
                        <EmojiPicker onEmojiClick={handleEmojiClick} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          </div>
          {isDefault && (
            <>
              <div
                className="trans-udpade-rule-note mt-3 justify-content-between"
                style={{ padding: "0px 1px 0px 4px !important" }}
              >
                <span className="trans-500">Note:</span> This is a default
                category so only the colour and emoji can be edited. If you
                delete the category, you can only add it back by reconnecting
                your accounts.
              </div>
            </>
          )}
          <div className="modal-popup-btn-wrp">
            <button
              className="change-password-btn cursor-pointer"
              type="submit"
              style={{ width: "100%", marginTop: "14px" }}
            >
              Confirm changes
            </button>
          </div>
        </form>
        {deleteCategoryPopup && (
          <PopUpDeleteCategory
            categoryColor={selectedCategory.colour}
            categoryEmoji={selectedCategory.emoji}
            categoryName={selectedCategory.name}
            handleCancel={() => {
              setDeleteCategoryPopup(false);
            }}
            handleDelete={() => {
              deleteCategory();
            }}
          />
        )}
      </div>
    </div>
  );
};

export default EditCategoriesModal;
