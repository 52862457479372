import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import PopupInfoModal from "../../ModalForm/Popup/PopupInfoModel";
import Profile from "../../assests/images/icons/image.png";
import { Link, useParams } from "react-router-dom";
import weburls from "../../Weburls/weburls"; // Make sure this path is correct

const UserImageInfoToggle = ({
  isPopupOpenInfo,
  togglePopupInfo,
  title,
  description,
  customStyle,
}) => {
  const { uuid } = useParams();
  const [userData, setUserData] = useState({});

  const fetchUserData = useCallback(async () => {
    try {
      const response = await axios.get(`${weburls.Get_clients_by_uuid}${uuid}`);
      if (response?.data) {
        setUserData(response.data); // Adjust this if the structure is different
      } else {
        console.error("No user data found");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  }, [uuid]);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  return (
    <div
      className={`${
        customStyle ? "col-lg-0" : "col-lg-6 "
      } right-section search-spacing your-merchants-back-to-portal`}
    >
      <div
        className={`${
          customStyle ? "pe-0 " : "setting_background_color"
        }  right-section`}
      >
        {/* <div
          className="profile-dashboard cursor-pointer"
          onClick={togglePopupInfo}
        >
          <InfoIcon />
        </div> */}

        {isPopupOpenInfo && (
          <PopupInfoModal
            isOpen={isPopupOpenInfo}
            onClose={togglePopupInfo}
            title={title}
            description={description}
          />
        )}
        <Link
          className="profile-dashboard"
          to={`/settings/${uuid}`}
          style={{
            marginLeft: customStyle ? "0px" : "66px",
            width: customStyle ? "63px" : "95px",
          }}
        >
          <div
            className="profile-img"
            style={{ marginLeft: customStyle ? "0px" : "35px" }}
          >
            <img
              src={
                userData?.avatar === "" || userData?.avatar === null
                  ? Profile
                  : userData?.avatar
              }
              alt="Profile"
              style={{
                height: "59px",
                width: "59px",
                borderRadius: "50%",
                objectFit: "cover",
              }}
            />
          </div>
        </Link>
      </div>
    </div>
  );
};

export default UserImageInfoToggle;
