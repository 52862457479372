import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ReactComponent as Bigplus } from '../../../assests/images/icons/Add_round.svg';
import PopupOneOffBudgetModal from '../../../ModalForm/Popup/PopupDeleteOneOffBudget';

import weburls from '../../../Weburls/weburls';
import AddOneOffBudget from './AddOneOffBudget';
import { colorCss } from '../../../utilities/Statics';
export const formatDate = (datetimeString) => {
  const date = new Date(datetimeString);
  return date.toLocaleDateString();
};
const OneOffBudget = ({ uuid }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [oneOffBudgetApiData, setOneOffBudgetApiData] = useState([]);
  const [editOneOffBudget, setEditOneOffBudget] = useState(false);
  const [deleteOneOffBudgetModal, setDeleteOneOffBudgetModal] = useState(false);
  const [oneOffBudgetUuid, setOneOffBudgetUuid] = useState(null);

  const handleModalForm = () => {
    setModalOpen(true);
    setEditOneOffBudget(false);
  };

  const handleOneOffBudgetDelete = (id) => {
    setOneOffBudgetUuid(id);
    setDeleteOneOffBudgetModal(true);
  };
  const fetchOneOffBudget = useCallback(async () => {
    try {
      const response = await axios.get(`${weburls.Get_OneOffBudget}${uuid}`);
      if (response?.data) {
        setOneOffBudgetApiData(response?.data?.oneOffBudget);
      }
    } catch (error) {
      toast.error('Something went wrong please try again.', {
        position: 'top-right',
        autoClose: 2000,
      });
    }
  }, [uuid, setOneOffBudgetApiData]);

  useEffect(() => {
    fetchOneOffBudget();
  }, [fetchOneOffBudget]);

  return (
    <>
      <div className="row reoccuring-wrp">
        <div className="col-xl-6">
          <div className="col-xl-12 top-head-section">
            <h3 className="semi-heading-edit-budget">One off Budgets</h3>
            <p>Your one off budget is allocated towards any expenses that don’t fit into your regular budget. </p>
          </div>
        </div>
        <div className="col-xl-2 right-section">
          <button
            className="new-btn"
            style={{
              width: '201px',
              height: '43px',
              padding: '0px',
            }}
            onClick={handleModalForm}
          >
            Add one off budget <Bigplus />
          </button>
        </div>
      </div>
      <div className="mt-2">
        <div className="col-lg-8 new-table second-table-edit-budget">
          <div>
            <div className="table-horizontal">
              <div className="col-12 table-horizontal">
                <table className="table-image new-table">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Colour</th>
                      <th scope="col">Emoji</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody className="space-table">
                    {oneOffBudgetApiData?.monthly?.length > 0 &&
                      oneOffBudgetApiData &&
                      oneOffBudgetApiData?.monthly.map((item, index) => {
                        return (
                          <tr className="cursor-pointer">
                            <td className="table-text-color">
                              <div
                                className="planner"
                                style={{
                                  marginLeft: '10px',
                                }}
                              >
                                {item.name}
                              </div>
                            </td>
                            <td className="table-text-color">
                              <div className="planner">{item.amount}</div>
                            </td>
                            <td className={`table-text-color `}>
                              <div
                                className={`oneOffBudgetColour ${colorCss(item.colour)}`}
                                style={{
                                  width: '133px',
                                }}
                              >
                                {item.colour}
                              </div>
                            </td>
                            <td className={`table-text-color `}>
                              <div className="expenseLastPaymentDate planner">{item.emoji}</div>
                            </td>
                            <td className="table-text-color">
                              <i
                                className="fa fa-times"
                                aria-hidden="true"
                                onClick={() => handleOneOffBudgetDelete(item.oneOffBudgetUuid)}
                                style={{
                                  fontSize: '24px',
                                }}
                              ></i>
                              {deleteOneOffBudgetModal && (
                                <PopupOneOffBudgetModal
                                  isOpen={deleteOneOffBudgetModal}
                                  setDeleteOneOffBudgetModal={setDeleteOneOffBudgetModal}
                                  oneOffBudgetUuid={oneOffBudgetUuid}
                                  uuid={uuid}
                                  fetchOneOffBudget={fetchOneOffBudget}
                                />
                              )}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      {modalOpen === true && (
        <div>
          <AddOneOffBudget
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            fetchExpense={fetchOneOffBudget}
            uuid={uuid}
          />
        </div>
      )}
    </>
  );
};
export default OneOffBudget;
