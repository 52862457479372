import {
  categoryBackgroundCss,
  categoryColorForAll,
} from "../../utilities/Statics";

export const PopUpForUpdateDelete = ({
  handleUpdateCashflowYes,
  handleUpdateCashflowNo,
  updateOrDeleteText,
}) => {
  return (
    <div
      className="popup-container modal-background open-modal"
      id="popup_Update"
    >
      <div className="delete-popup-content">
        <h2 className="delete-popup-text">{updateOrDeleteText} cashflow?</h2>
        <p className="delete-popup-sub-text">
          Would you like to {updateOrDeleteText} your cashflow as well?
        </p>

        <div className="delete-popup-buttons">
          <button className="btn-delete" onClick={handleUpdateCashflowNo}>
            No
          </button>
        </div>
        <div className="delete-popup-buttons mt-2">
          <button className="btn-confirm" onClick={handleUpdateCashflowYes}>
            Yes
          </button>
        </div>
      </div>
    </div>
  );
};

export const PopUpForDeleteWithName = ({
  deleteButtonName,
  handleDeleteConfirm,
  handleDeleteCancel,
}) => {
  return (
    <div
      className="popup-container modal-background open-modal"
      id="popup_Delete"
    >
      <div className="delete-popup-content">
        <h2 className="delete-popup-text">Are you sure?</h2>
        <p className="delete-popup-sub-text">This cannot be un-done.</p>
        <div className="delete-popup-buttons">
          <button className="btn-confirm" onClick={handleDeleteCancel}>
            Go back
          </button>
        </div>
        <div className="delete-popup-buttons mt-2">
          <button className="btn-delete" onClick={handleDeleteConfirm}>
            {deleteButtonName}
          </button>
        </div>
      </div>
    </div>
  );
};

export const PopUpDelete = ({ handleDeleteConfirm, handleDeleteCancel }) => {
  return (
    <div
      className="popup-container modal-background open-modal"
      id="popup_Delete"
    >
      <div className="delete-popup-content">
        <h2 className="delete-popup-text">Are you sure?</h2>
        <p className="delete-popup-sub-text">This cannot be un-done.</p>
        <div className="delete-popup-buttons">
          <button className="btn-confirm" onClick={handleDeleteCancel}>
            Go back
          </button>
        </div>
        <div className="delete-popup-buttons mt-2">
          <button className="btn-delete" onClick={handleDeleteConfirm}>
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export const PopUpNoYes = ({ title, subtitle, handleChange }) => {
  return (
    <div
      className="popup-container modal-background open-modal"
      id="popup_Delete"
    >
      <div className="delete-popup-content">
        <h2 className="delete-popup-text">{title}</h2>
        <p className="delete-popup-sub-text">{subtitle}</p>
        <div className="delete-popup-buttons">
          <button className="btn-delete" onClick={() => handleChange(false)}>
            No
          </button>
        </div>
        <div className="delete-popup-buttons mt-2">
          <button className="btn-confirm" onClick={() => handleChange(true)}>
            Yes
          </button>
        </div>
      </div>
    </div>
  );
};

export const PopUpYesNoRow = ({ title, subtitle, handleChange }) => {
  return (
    <div
      className="popup-container modal-background open-modal"
      id="popup_Delete"
    >
      <div className="delete-popup-content">
        <h2 className="delete-popup-text">{title}</h2>
        <p className="delete-popup-sub-text">{subtitle}</p>
        <div className="popup-buttons">
          <button className="btn-confirm" onClick={() => handleChange(true)}>
            Yes
          </button>
          <button className="btn-delete" onClick={() => handleChange(false)}>
            No
          </button>
        </div>
      </div>
    </div>
  );
};

export const PopUpRuleUpdate = ({ handleChanges }) => {
  return (
    <div
      className="popup-container modal-background open-modal"
      id="popup_Delete"
    >
      <div className="delete-popup-content">
        <h2 className="delete-popup-text">Apply to current transactions?</h2>
        <p className="delete-popup-sub-text">
          Would you like to apply this rule to all current transactions?
        </p>
        <div className="delete-popup-buttons">
          <button className="btn-confirm" onClick={() => handleChanges(true)}>
            Yes
          </button>
        </div>
        <div className="delete-popup-buttons mt-2">
          <button className="btn-delete" onClick={() => handleChanges(false)}>
            No
          </button>
        </div>
      </div>
    </div>
  );
};

export const PopUpDeleteCategory = ({
  categoryEmoji,
  categoryName,
  categoryColor,
  handleDelete,
  handleCancel,
}) => {
  const isHexColor = (color) => /^#([0-9A-Fa-f]{3}){1,2}$/.test(color);

  return (
    <div
      className="popup-container modal-background open-modal"
      id="popup_Delete"
    >
      <div className="delete-popup-content">
        <h2 className="delete-popup-text">Delete category?</h2>
        <p className="delete-popup-sub-text" style={{ marginBottom: "10px" }}>
          This will remove the category from all merchants and transactions and
          also delete the budget created for it.
        </p>
        <div className="button-blck">
          <div
            className="icon-tag"
            id={`Popover`}
            style={{ marginBottom: "16px" }}
          >
            {categoryName !== "Uncategorised" && (
              <div
                className={`icon-transaction-img ${
                  isHexColor(categoryColor)
                    ? ""
                    : categoryBackgroundCss(categoryColor)
                }`}
                style={{
                  background: categoryColorForAll(categoryColor),
                }}
              >
                {categoryEmoji}
              </div>
            )}
            <div className="content">
              <h6 className="icon-content">{categoryName}</h6>
            </div>
          </div>
        </div>
        <div className="delete-popup-buttons">
          <button className="btn-confirm" onClick={() => handleCancel()}>
            Go back
          </button>
        </div>
        <div className="delete-popup-buttons mt-2">
          <button className="btn-delete" onClick={() => handleDelete()}>
            Delete category
          </button>
        </div>
      </div>
    </div>
  );
};
