import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ReactComponent as Backarrow } from '../../../assests/images/icons/Arrow_rleft.svg';
import Profile from '../../../assests/images/icons/image.png';
import Shape from '../../../assests/images/icons/shape.png';
import Arrowright from '../../../assests/images/icons/white-right.svg';
import MainSidebar from '../../MainSidebar';
import { calculatePercentageCompletion } from '../SpendvsBudget/Spendbudget';
import { colorCss, progressbarColor } from '../../../utilities/Statics';
import PopupOneOffBudgetModal from '../../../ModalForm/Popup/PopupDeleteOneOffBudget';
import PopupInfoModal from '../../../ModalForm/Popup/PopupInfoModel';
import weburls from '../../../Weburls/weburls';

const EditOneOffBudget = () => {
  const [oneOfBudgetData, setOneOfBudgetData] = useState();
  const [isPopupOpenInfo, setPopupOpenInfo] = useState(false);
  const [deleteOneOffBudgetModal, setDeleteOneOffBudgetModal] = useState(false);
  const [oneOffBudgetUuid, setOneOffBudgetUuid] = useState(null);

  const handleOneOffBudgetDelete = (id) => {
    setOneOffBudgetUuid(id);
    setDeleteOneOffBudgetModal(true);
  };
  const togglePopupInfo = () => setPopupOpenInfo(!isPopupOpenInfo);
  const { oneOfBudgetUuid, uuid } = useParams();

  const fetchOneOffBudget = useCallback(async () => {
    try {
      const response = await axios.get(`${weburls.Get_OneOffBudget_ById}${oneOfBudgetUuid}/${uuid}`);
      setOneOfBudgetData(response?.data.oneOffBudget);
    } catch (error) {}
  }, [oneOfBudgetUuid, uuid]);

  useEffect(() => {
    fetchOneOffBudget();
  }, [fetchOneOffBudget]);

  return (
    <>
      <div className="container-scroller">
        <div className="container-fluid">
          <div className="appmain mainsidebar main-wrapper">
            <MainSidebar uuid={oneOfBudgetData?.user_uuid} />
            <div className="app-main__outer edit-your-budget-main-wrapper">
              <div className="row top-nav">
                <div className="col-md-4 col-lg-3 col-xl-4 edit-your-budget-heading-wrp">
                  <div className="fs-3 bold top-head big-head-new">Edit your one off Budget</div>
                  <div className="fst-normal setting_text_color">
                    Make any changes required for your one off budget.
                  </div>
                </div>

                <div className="col-md-8 col-lg-8 col-xl-8 right-section edit-your-budget-back-portal-wrp">
                  <div className="setting_background_color right-section">
                    <div className="profile-dashboard">
                      <Link to={`/BudgetSummary/${oneOfBudgetData?.user_uuid}`}>
                        <div
                          className="edit-btn"
                          href-="#"
                          style={{
                            width: '327px',
                            height: '60px',
                          }}
                        >
                          <div className="left-arrow">
                            <Link to={`/BudgetSummary/${oneOfBudgetData?.user_uuid}`}>
                              <Backarrow />
                            </Link>
                          </div>
                          <div className="right">
                            <h4 className="back-to-budget-summ bold">Back to budget summary</h4>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="profile-dashboard">
                      <img
                        src={Shape}
                        alt="Mortage_img"
                        onClick={togglePopupInfo}
                        style={{ width: '20px', marginLeft: '20px' }}
                      />
                    </div>
                    {isPopupOpenInfo && (
                      <PopupInfoModal
                        isOpen={isPopupOpenInfo}
                        onClose={togglePopupInfo}
                        title="One off budgets 💸"
                        description={[
                          'One-off budgets are different from your regular income and expense budgets. ',
                          'A one-off budget, just like it mentions in the name, is ideal for one-off expenses. These can be things like holidays, weddings, and other things that don’t happen on a regular basis.',
                          'You’ll be able to tag transactions to one-off budgets so they stay separate, and you can track your spending separately as well.',
                        ]}
                      />
                    )}
                    <div className="profile-dashboard">
                      <div className="profile-img">
                        <img src={Profile} alt="profile" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row middle-seciton">
                <div className="mt-2">
                  <div className="col-lg-10 new-table edit-goal-table">
                    <div>
                      <div className="table-horizontal">
                        <div class="col-12 table-horizontal edit-goal-budget-wrapper">
                          <table class="table-image new-table">
                            <thead>
                              <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Colour</th>
                                <th scope="col">Emoji</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody className="space-table">
                              <tr className="cursor-pointer">
                                <td className="table-text-color">
                                  <div
                                    className="planner"
                                    style={{
                                      marginLeft: '10px',
                                    }}
                                  >
                                    {oneOfBudgetData?.name}
                                  </div>
                                </td>
                                <td className="table-text-color">
                                  <div className="planner">{oneOfBudgetData?.amount}</div>
                                </td>
                                <td className={`table-text-color `}>
                                  <div
                                    className={`oneOffBudgetColour ${colorCss(oneOfBudgetData?.colour)}`}
                                    style={{
                                      width: '133px',
                                    }}
                                  >
                                    {oneOfBudgetData?.colour}
                                  </div>
                                </td>
                                <td className={`table-text-color `}>
                                  <div className="expenseLastPaymentDate planner">{oneOfBudgetData?.emoji}</div>
                                </td>
                                <td className="table-text-color">
                                  <i
                                    class="fa fa-times"
                                    aria-hidden="true"
                                    onClick={() => handleOneOffBudgetDelete(oneOfBudgetData?.uuid)}
                                    style={{
                                      fontSize: '24px',
                                    }}
                                  ></i>
                                  {deleteOneOffBudgetModal && (
                                    <PopupOneOffBudgetModal
                                      isOpen={deleteOneOffBudgetModal}
                                      setDeleteOneOffBudgetModal={setDeleteOneOffBudgetModal}
                                      oneOffBudgetUuid={oneOffBudgetUuid}
                                      uuid={uuid}
                                      fetchOneOffBudget={fetchOneOffBudget}
                                      editOneOffBudgetScreen={true}
                                    />
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 col-xl-7  row groceries-edit-second-section"></div>
                <div className="col-md-6 col-xl-6"></div>
              </div>
              <div className="col-xl-8 mt-6-new">
                <div
                  className={`income-merchant col-lg-5 col-xl-5 col-sm-12  white ${colorCss(oneOfBudgetData?.colour)}`}
                >
                  <div className="inner-blck">
                    <div className="merchantSummary">
                      <div className="icon-img red-img goal-1-progress-color">{oneOfBudgetData?.emoji}</div>
                      <div className="content">
                        <h6 className="small-content white">{oneOfBudgetData?.name}</h6>
                        <h5 className="bold">${oneOfBudgetData?.total_transaction_amount}</h5>
                      </div>
                    </div>
                    <div className="arrow">
                      <img src={Arrowright} />
                    </div>
                  </div>
                  <div className="left-block name-merchant">
                    <h6 className="white mb-2">
                      ${(oneOfBudgetData?.amount - oneOfBudgetData?.total_transaction_amount).toFixed(2)} remaining
                    </h6>
                  </div>
                  <div class="progress goal-1-progress-color" style={{ height: '12px' }}>
                    <div
                      className={`progress-bar ${progressbarColor(oneOfBudgetData?.colour)}`}
                      role="progressbar"
                      aria-valuenow={calculatePercentageCompletion(
                        oneOfBudgetData?.total_transaction_amount,
                        oneOfBudgetData?.amount,
                      ).toFixed(2)}
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{
                        width: `${calculatePercentageCompletion(
                          oneOfBudgetData?.total_transaction_amount,
                          oneOfBudgetData?.amount,
                        ).toFixed(2)}%`,
                        opacity: '2 !important',
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EditOneOffBudget;
