import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import $ from "jquery";
import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import * as yup from "yup";
import Profile from "../../assests/images/icons/image.png";
import Shape from "../../assests/images/icons/shape.png";
import { Loading } from "../../Components/Loading/Loading";
import MainSidebar from "../../Components/MainSidebar";
import OverlayContainer from "../../Components/OverlayContainer/OverlayContainer";
import PopupInfoModal from "../../ModalForm/Popup/PopupInfoModel";
import weburls from "../../Weburls/weburls";
import "./setting.css";

const schema = yup.object().shape({
  email: yup
    .string()
    .required("Email is required")
    .matches(
      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
      "Please enter a valid email"
    ),
});

const Email2FAVerifaction = () => {
  const navigate = useNavigate();
  const { uuid } = useParams();
  const token = localStorage.getItem("budget-token");
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const sidebarRef = useRef(null);
  const [isPopupOpenInfo, setPopupOpenInfo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    const trimmedValue = value.trim();
    setFormData({
      ...formData,
      [name]: trimmedValue,
    });
  };

  const togglePopupInfo = () => setPopupOpenInfo(!isPopupOpenInfo);
  const onSubmitHandler = async (formData) => {
    setLoading(true);
    try {
      const response = await axios.post(
        weburls.Email_2FA_Verification,
        formData,
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );
      setLoading(false);
      if (response.data.code == 1) {
        setTimeout(() => {
          navigate(`/Email2FAVerifactionOTP/${uuid}`);
        }, 1000);
        toast.success("Reset Password link send into email successfully", {
          position: "top-right",
          autoClose: 2000,
        });
      }

      if (response.data.code == 0) {
        toast.error("Your email address in-vaild. Please try again", {
          position: "top-right",
          autoClose: 2000,
        });
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Please try again.");
      }
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const toggleSidebar = () => {
    $(sidebarRef.current).toggleClass("sidebarnew");
  };

  return (
    <>
      <div className="app-main__outer your-setting-page-container">
        <ToastContainer position="top-right" autoClose={5000} closeOnClick />
        <div className="row">
          <div className="col-md-6">
            <div className="fs-3 bold big-head-new">
              Set up 2-factor authentication
            </div>
            <div className="fst-normal setting_text_color mt-2">
              2FA adds an extra wall of defence, ensuring that only you can
              access your BudgetBuddie account, even if someone gets hold of
              your password.
            </div>
          </div>
          <div className="col-md-6 right-section your-setting-page-back-portal">
            <div className="setting_background_color right-section">
              <div className="profile-dashboard">
                <img
                  src={Shape}
                  alt="Mortage_img"
                  onClick={togglePopupInfo}
                  style={{ width: "20px", marginLeft: "20px" }}
                />
              </div>

              {isPopupOpenInfo && (
                <PopupInfoModal
                  isOpen={isPopupOpenInfo}
                  onClose={togglePopupInfo}
                  title="Settings ⚙️"
                  description={[
                    "Manage all your details, preferences, and security settings here.",
                    "Update your details by clicking the name, phone, or email text boxes. Turn certain notifications off and on to suit your needs.",
                    "Set up extra layers of security like Face 2FA, and change any details at any time. Change the appearance of BudgetBuddie by turning it to dark mode. ",
                    "View when your next payment is being processed and what card you’re using.",
                  ]}
                />
              )}
              <div className="profile-dashboard">
                <div className="profile-img">
                  <img src={Profile} alt="profile" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row setting-page mt-5">
          <div className="row d-flex justify-content-center align-items-center h-100 mt-5">
            <div className="col-xl-3 setting-left-section mt-4">
              <div className="main-2fa-left">
                <div className="heading-2fa bold black mt-5">
                  <h3>Enter your email</h3>
                </div>
                <div className="fst-normal setting_2fa_text_color mb-4">
                  Enter your email below and we’ll send you a secure 6-digit
                  verification code.
                </div>
                <form onSubmit={handleSubmit(onSubmitHandler)}>
                  <div className="form-outline mb-4 email-box-padding ">
                    <label className="form-label" for="form3Example3">
                      Email
                    </label>
                    <input
                      {...register("email", {
                        onChange: (e) => {
                          handleChange(e);
                        },
                      })}
                      value={formData.email}
                      name="email"
                      type="text"
                      id="form3Example3"
                      className="form-control form-control-lg-email"
                    />
                    <p className="mt-1 error-message">
                      {errors.email?.message}
                    </p>
                  </div>
                  {loading === true ? (
                    <OverlayContainer>
                      <div>
                        <Loading />
                      </div>
                    </OverlayContainer>
                  ) : (
                    ""
                  )}
                  <div className="mt-5 text-center pt-2 mb-5">
                    <button type="submit" className="comn-btn verify-btn">
                      Get verification code
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Email2FAVerifaction;
