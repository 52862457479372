import React from "react";
import Content from "../../Views/Content.jsx";
import { useParams } from "react-router-dom";
import MainSidebar from "../../Components/MainSidebar/index.jsx";

const AdminLayout = () => {
    const uuid=  localStorage.getItem("userUuid")
  return (
    <div className="container-scroller">
      <div className="container-fluid">
        <div className="appmain mainsidebar main-wrapper">
          <MainSidebar uuid={uuid} />
          <Content />
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;
