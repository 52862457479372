import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Edit } from '../../../assests/images/icons/edit.svg';
import Tableimg from '../../../assests/images/icons/image.png';
import { ReactComponent as Search } from '../../../assests/images/icons/Search.svg';
import LoadingTxt from '../../Loading/LoadingTxt';
import weburls from '../../../Weburls/weburls';
import SuperAdminHeader from '../SuperAdminHeader';
import SuperAdminSidebar from '../SuperAdminSidebar';

function CompaniesList() {
  const [active, setActive] = useState(false);
  const [apiData, setApiData] = useState([]);

  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [noFound, setNoFound] = useState('');

  const activeChange = () => {
    setActive(true);
  };

  // Api Fetch

  useEffect(() => {
    axios
      .get(weburls.Super_admin_company_list)
      .then((res) => {
        if (res?.data?.length > 0) {
          setApiData(res?.data);
        } else {
          setNoFound(res?.data?.message);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  // Search bar
  const filteredData =
    apiData?.length > 0 &&
    apiData?.filter(
      (item) =>
        item?.companyName?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        item?.firstName?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        item?.lastName?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        item?.companyWebsite?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        item?.phoneNumber?.toLowerCase().includes(searchTerm?.toLowerCase()),
    );
  return (
    <>
      <div className="container-fluid">
        <SuperAdminHeader />
        <div className="appmain">
          <SuperAdminSidebar />

          <div className="app-main__outer main-front-dashboard-container">
            <div className="row ">
              <div className="col-md-6 col-xl-6 top-head-wrp-company">
                <div className="fs-3 big-head-new ">
                  Good Morning,{' '}
                  <span className="text-jeremy">
                    Admin
                    <div className="hand-img">👋</div>
                  </span>
                </div>
                <div className="fst-normal light-grey">Here's your dashboard of super admin using BudgetBuddie.</div>
              </div>
              <div className="col-xl-6 col-md-6 super-btn">
                <div className="search-wrp-company col-xl-7">
                  <div className="col-lg-12 search-spacing">
                    <div className="search-block">
                      <form>
                        <Search />
                        <input
                          type="text"
                          className="search"
                          placeholder="Search for a company here"
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                      </form>
                    </div>
                  </div>
                </div>
                <div className="button-grp-super col-xl-4">
                  <Link type="button" className="comn-btn-super" to="/Companies/NewCompany">
                    New company
                    <i class="fa-solid fa-plus plus-icon-style"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div className="bold mt-4 mb-3 heading-new">Filter Companies</div>
            <div className="row">
              <div className="col-lg-6 mb-3">
                <div className="group-backgroud-color">
                  <div className="bold black ms-3 mb-3">Companies</div>
                  <div className="group-btn">
                    <div className="button-blck">
                      <a href="#" className="group-button purple">
                        All
                      </a>
                    </div>
                    <div className="button-blck">
                      <a href="#" className="group-button">
                        Individual
                      </a>
                    </div>
                    <div className="button-blck">
                      <a href="#" className="group-button">
                        Company 1
                      </a>
                    </div>
                    <div className="button-blck">
                      <a href="#" className="group-button">
                        Company 2
                      </a>
                    </div>
                    <div className="button-blck">
                      <a href="#" className="group-button">
                        Company 3
                      </a>
                    </div>
                    <div className="button-blck">
                      <a href="#" className="group-button">
                        Company 4
                      </a>
                    </div>
                    <div className="button-blck">
                      <a href="#" className="group-button">
                        Company 5
                      </a>
                    </div>
                    <div className="button-blck">
                      <a href="#" className="group-button">
                        Company 6
                      </a>
                    </div>
                    <div className="button-blck">
                      <a href="#" className="group-button">
                        Company 7
                      </a>
                    </div>
                    <div className="button-blck">
                      <a href="#" className="group-button">
                        Company 8
                      </a>
                    </div>
                  </div>
                  <div className="group-btn"></div>
                </div>
              </div>
              {/* <div className="col-lg-3 mb-3 plan-section-area">
                <div className="group-backgroud-color">
                  <div className="bold black ms-3 mb-3">Plan</div>
                  <div className="group-btn yellow-btn">
                    <div className="button-blck">
                      <a href="#" className="group-button dark-yellow">
                        All
                      </a>
                    </div>
                    <div className="button-blck">
                      <a href="#" className="group-button">
                        Standard
                      </a>
                    </div>
                    <div className="button-blck">
                      <a href="#" className="group-button">
                        Pro
                      </a>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="col-lg-3 mb-3">
                <div className="group-backgroud-color">
                  <div className="bold black ms-3 mb-3">Status</div>
                  <div className="group-btn green-btn">
                    <div className="button-blck">
                      <a href="#" className="group-button green">
                        All
                      </a>
                    </div>
                    <div className="button-blck">
                      <a href="#" className="group-button">
                        Active
                      </a>
                    </div>
                    <div className="button-blck">
                      <a href="#" className="group-button">
                        Inactive
                      </a>
                    </div>
                    <div className="button-blck">
                      <a href="#" className="group-button">
                        Not-Activated
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="table-wrp companies-list-wrapper" id="#down">
              <div className="bold black heading-main mt-4">Companies list</div>
              <div class="">
                <div class="col-12 table-horizontal">
                  <table class="table-image main-table">
                    <thead>
                      <tr className="green">
                        <th scope="col">Company Name</th>
                        <th scope="col">Company Website</th>
                        <th scope="col">Contact Person</th>
                        <th scope="col">Email</th>
                        <th scope="col">Phone Number</th>
                        <th scope="col">Status</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    {filteredData?.length > 0 ? (
                      <tbody className="space-table">
                        {filteredData &&
                          filteredData.length > 0 &&
                          filteredData.map((item) => {
                            return (
                              <tr onClick={activeChange} className="cursor-pointer">
                                <th>
                                  <div className="air-image">
                                    <img src={Tableimg} alt="table-img" />
                                    <h3 className="table-content display">{item.companyName}</h3>
                                  </div>
                                </th>
                                <a href="https://www.proaxiom.com/" target="_blank">
                                  <td className="table-text-color">{item.companyWebsite}</td>
                                </a>
                                <td className="table-text-color">{`${item.firstName}${' '}${item.lastName}`}</td>

                                <td className="table-text-color">{item.email}</td>
                                <td className="table-text-color">{item.phoneNumber}</td>
                                <td>
                                  {item.status === 1 ? (
                                    <div className="active-status">
                                      <button className="active-btn table-text-color">
                                        <i class="fa-solid fa-circle"></i> Active
                                      </button>
                                    </div>
                                  ) : (
                                    <div className="in-active-status-div">
                                      <button className="inactive-button table-text-color">
                                        <i class="fa-solid fa-circle"></i> Inactive
                                      </button>
                                    </div>
                                  )}
                                </td>
                                <td>
                                  <Link to="/Companies/EditCompany">
                                    <Edit />
                                  </Link>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    ) : loading ? (
                      <div className="no-data-found-td">
                        <LoadingTxt />
                      </div>
                    ) : (
                      <div className="no-data-found-td">No company found</div>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CompaniesList;
