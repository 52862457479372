import React, { useState } from "react";
import PopOver from "./Popover";
import { categoryBackgroundCss, categoryColorForAll } from "../../utilities/Statics";
import PendingTransactionModel from "../../ModalForm/TransactionModals/PendingTransactionModel";

const CategoryWithPopover = ({
  emoji,
  name,
  colour,
  style,
  uniqueID,
  amount,
  updateCategory,
  expenseCategories,
  incomeCategories,
  oneOffCategory,
  updatingForSplitTransaction=false,
  onCategoryChangeForSplitting,
  showAddCategory,
  handleAddCategory,
  updateCategoriesInBulk,
  isSelectionMode,
  isPendingTransaction=false,
  status = '',
  categoryTitle,
  categorySubTitle,
}) => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [showPendingTransaction, setShowPendingTransaction] = useState(false);
  const togglePopover = () => {
    setPopoverOpen((prev) => !prev);
  };
   const handlePendingTransaction = () => {
    setShowPendingTransaction(!showPendingTransaction);
  };
  const isHexColor = (color) => /^#([0-9A-Fa-f]{3}){1,2}$/.test(color);
  return (
    <div>
      <div
        className="button-blck"
        id={`Popover-${uniqueID}`}
        onClick={() => status === "Pending" ? handlePendingTransaction() :togglePopover(uniqueID)}
      >
        <div className="icon-tag" style={style}>
          {name !== "Uncategorised" && name !== "Select a category" && (
          <div
            className={`icon-transaction-img ${isHexColor(colour) ? '' : categoryBackgroundCss(colour)}`}
            style={{
              background: status === "Pending"
                ? "white"
                : categoryColorForAll(colour),
            }}
            >
              {status === "Pending" ? "⏳" :emoji}
            </div>
                   )} 
          <div className="content">
            <h6 className="icon-content">{status === 'Pending' ? 'Pending': name}</h6>
          </div>
        </div>
      </div>

      {showPendingTransaction && (
        <PendingTransactionModel
          modalOpen={showPendingTransaction}
          closeModal={handlePendingTransaction}
        />
      )}

      {popoverOpen && (
        <PopOver
          categories={amount < 0 ? expenseCategories : incomeCategories}
          oneOffCategory = {amount < 0 ? oneOffCategory: []}
          transactionId={uniqueID}
          togglePopover={togglePopover}
          popoverOpen={popoverOpen}
          transactionType={amount < 0 ? "Expense" : "Income"}
          updateCategory={updateCategory}
          updatingForSplitTransaction={updatingForSplitTransaction}
          onCategoryChangeForSplitting={onCategoryChangeForSplitting}
          categoryTitle={categoryTitle}
          categorySubTitle={categorySubTitle}
        />
      )}
    </div>
  );
};

export default CategoryWithPopover;
