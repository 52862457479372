import React, { useState } from "react";
import { Link } from "react-router-dom";
import Arrowright from "../../../assests/images/Expand_right.png";
import Countdown from "../../../assests/images/icons/Countdown.png";
import Profile from "../../../assests/images/icons/image.png";
import { ReactComponent as Edit } from "../../../assests/images/icons/more.svg";
import Paksave from "../../../assests/images/icons/Paksave.png";
import Shape from "../../../assests/images/icons/shape.png";
import MainSidebar from "../../MainSidebar";

import { colorCss, hexToColorMap } from "../../../utilities/Statics";
import PopupColorModal from "../../../ModalForm/Popup/PopupColorModel";
import PopupModal from "../../../ModalForm/Popup/PopupModel";

const Groceriesedit = () => {
  const [active, setActive] = useState(false);
  const [isPopupOpenColor, setPopupOpenColor] = useState(false);
  const [isPopupOpenBudget, setPopupOpenBudget] = useState(false);
  const [isPopupOpenTransactions, setPopupOpenTransactions] = useState(false);

  const [selectedColorOption, setSelectedColorOption] = useState("Red");
  const [selectedBudgetOption, setSelectedBudgetOption] = useState("This week");
  const [selectedTransactionsOption, setSelectedTransactionsOption] =
    useState("This week");

  const togglePopupColor = () => setPopupOpenColor(!isPopupOpenColor);
  const togglePopupBudget = () => setPopupOpenBudget(!isPopupOpenBudget);
  const togglePopupTransactions = () =>
    setPopupOpenTransactions(!isPopupOpenTransactions);

  const handleOptionColorClick = (option) => {
    setSelectedColorOption(option);
    setPopupOpenColor(false);
  };

  const handleOptionBudgetClick = (option) => {
    setSelectedBudgetOption(option);
    setPopupOpenBudget(false);
  };

  const handleOptionTransactionsClick = (option) => {
    setSelectedTransactionsOption(option);
    setPopupOpenTransactions(false);
  };

  const activeChange = () => {
    setActive(true);
  };
  return (
    <>
      <div className="container-scroller">
        <div className="container-fluid">
          <div className="appmain mainsidebar main-wrapper grocery-edit-main">
            <MainSidebar />
            <div className="app-main__outer">
              <div className="row top-nav grocery-edit-nav">
                <div className="col-md-4 main-title-heading">
                  <div className="big-head-new bold">Groceries - Edit</div>
                  <div className="fst-normal setting_text_color">
                    Make any changes to personalise your budget.
                  </div>
                </div>

                <div className="col-lg-8 col-sm-12 right-section back-to-portal-financial">
                  <div className="setting_background_color right-section">
                    <div class="buttonEditBudget modal-popup-btn-wrp">
                      <button
                        type="button"
                        class="comn-btn common-btn-style"
                        // onClick={onSubmitData}
                      >
                        Save Changes
                      </button>
                      <button
                        type="button"
                        class="comn-btn delete-btn"
                        // onClick={() => handleDeleteIncome(incomeUuid)}
                      >
                        Delete Budget
                      </button>
                    </div>
                    <div className="profile-dashboard">
                      <img
                        src={Shape}
                        alt="Mortage_img"
                        style={{ width: "20px", marginLeft: "20px" }}
                      />
                    </div>

                    <div className="profile-dashboard">
                      <div className="profile-img">
                        <img src={Profile} alt="profile" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row middle-seciton groceries-edit-table">
                <div className="col-lg-12 new-table">
                  <div>
                    <div className="table-horizontal">
                      <div class="col-12 table-horizontal">
                        <table class="table-image new-table">
                          <thead>
                            <tr>
                              <th scope="col">Expense name</th>
                              <th scope="col">Expense amount</th>
                              <th scope="col">Last payment date</th>
                              <th scope="col">How Often?</th>
                              <th scope="col">Account associated</th>
                            </tr>
                          </thead>
                          <tbody className="space-table">
                            <tr
                              onClick={activeChange}
                              className="cursor-pointer"
                            >
                              <th>
                                <div className="air-image">
                                  <h3 className="table-content display inter">
                                    Groceries
                                  </h3>
                                </div>
                              </th>
                              <td className="table-text-color">
                                <div className="new-planner planner"></div>
                              </td>
                              <td className="table-text-color">
                                <div className="new-planner planner"></div>
                              </td>
                              <td className="table-text-color">
                                <select className="new-planner planner">
                                  <option>Weekly</option>
                                  <option>Monthly</option>
                                </select>
                              </td>
                              <td className="table-text-color">
                                <div className="planner big-planner"></div>
                              </td>
                              <td className="table-text-color">
                                <Link to="/Clients/Edit">
                                  <Edit />
                                </Link>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-xl-12 mt-4">
                  <h3 className="air-new fw-bold">Personalise</h3>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-xl-7  row groceries-edit-second-section">
                  <div className="col-md-6 col-xl-6 d-flex">
                    <div className="back-section">
                      <div className="personalised inter">
                        <h3 className="bold">Personalised colour</h3>
                      </div>
                      <div className="color-option">
                        <div className="dropdown">
                          <button
                            className={`group-button ${colorCss(
                              selectedColorOption
                            )} popoup-color`}
                            onClick={togglePopupColor}
                            aria-haspopup="true"
                            aria-expanded={isPopupOpenColor}
                            id="colorPickerButton"
                            style={{
                              color: "white",
                              backgroundColor: selectedColorOption?.startsWith(
                                "#"
                              )
                                ? selectedColorOption
                                : hexToColorMap[selectedColorOption] ||
                                  "#FF0000",
                            }}
                          >
                            {selectedColorOption?.startsWith("#") &&
                            hexToColorMap[selectedColorOption]
                              ? hexToColorMap[selectedColorOption]
                              : selectedColorOption?.startsWith("#")
                              ? "Custom"
                              : selectedColorOption}
                          </button>
                          {isPopupOpenColor && (
                            <PopupColorModal
                              isOpen={isPopupOpenColor}
                              targetId="colorPickerButton"
                              onClose={togglePopupColor}
                              title="Date Range"
                              options={[
                                "Red",
                                "Yellow",
                                "Blue",
                                "Purple",
                                "Orange",
                                "Pink",
                                "Light blue",
                                "Light green",
                                "Green",
                              ]}
                              selectedOption={selectedColorOption}
                              handleOptionClick={handleOptionColorClick}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-xl-6 d-flex">
                    <div className="back-section">
                      <div className="personalised inter">
                        <h3 className="bold">Personalised Emoji</h3>
                      </div>
                      <div className="color-option">
                        <div className="planner">💰</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-xl-6"></div>
              </div>
              <div className="row mt-5">
                <div class="col-md-8 col-xl-8 col-sm-12 top-section mb-4">
                  <div class="col-md-6">
                    <h5 class="title main-title">Spend vs Budget</h5>
                  </div>
                  <div class="col-md-6 right-section">
                    <div className="dropdown">
                      <button
                        className="group-button green"
                        onClick={togglePopupBudget}
                        aria-haspopup="true"
                        aria-expanded={isPopupOpenBudget}
                      >
                        {selectedBudgetOption}
                      </button>
                      {isPopupOpenBudget && (
                        <PopupModal
                          isOpen={isPopupOpenBudget}
                          onClose={togglePopupBudget}
                          title="Date Range"
                          options={[
                            "This week",
                            "This month",
                            "This quater",
                            "This year",
                          ]}
                          selectedOption={selectedBudgetOption}
                          handleOptionClick={handleOptionBudgetClick}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-xl-8 col-sm-12 big-section-block">
                  <Link className="groceries-main" to="/Groceriesedit">
                    <div className="income-merchant col-lg-5 col-xl-5 col-sm-12 grocer-edit">
                      <div className="inner-blck">
                        <div className="merchantSummary">
                          <div className="icon-img red-img">🛒</div>
                          <div className="content">
                            <h6 className="small-content">Groceries</h6>
                            <h5 className="bold black">$699</h5>
                          </div>
                        </div>
                        <div className="arrow">
                          <div className="icon-eclamation">
                            <i class="fa-solid fa-exclamation"></i>
                          </div>
                          <img src={Arrowright} />
                        </div>
                      </div>
                      <div className="left-block name-merchant mb-2">
                        <h6>Overspent by $120</h6>
                      </div>
                      <div
                        class="progress dark-red-color-progress"
                        style={{ height: "17px" }}
                      >
                        <div
                          class="progress-bar w-100 dark-red-color-progress"
                          role="progressbar"
                          aria-valuenow="75"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-12 col-xl-8 col-sm-12 mt-5 bar-cart grocer-edit">
                  <div className="grocer-edit-bar-heading">
                    <div className="col-md-6 mb-4 left col-sm-6">
                      <h5 className="smal-trans">Recent Transactions</h5>
                    </div>
                    <div className="col-md-6 right-section right col-sm-6">
                      <div className="dropdown">
                        <button
                          className="group-button green"
                          onClick={togglePopupTransactions}
                          aria-haspopup="true"
                          aria-expanded={isPopupOpenTransactions}
                        >
                          {selectedTransactionsOption}
                        </button>
                        {isPopupOpenTransactions && (
                          <PopupModal
                            isOpen={isPopupOpenTransactions}
                            onClose={togglePopupTransactions}
                            title="Date Range"
                            options={[
                              "This week",
                              "This month",
                              "This quater",
                              "This year",
                            ]}
                            selectedOption={selectedTransactionsOption}
                            handleOptionClick={handleOptionTransactionsClick}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="grocery-edit-recent-trans">
                    <div className="sideblck">
                      <div className="merchnat-img">
                        <img src={Countdown} alt="CountDown-img" />
                        <div className="name-merchant bar-chart inter">
                          <h5>Countdown</h5>
                          <h6>Groceries</h6>
                        </div>
                      </div>
                      <div className="date-time-blck inter">
                        <h4>19/04/2022</h4>
                        <span>|</span>
                        <h4>10: 50am</h4>
                      </div>
                      <div className="status-btn">
                        <div class="percentage bold red inter">Pending</div>
                      </div>
                      <div className="transaction-amt inter">
                        <h4>-$32.00</h4>
                      </div>
                    </div>
                    <div className="sideblck">
                      <div className="merchnat-img">
                        <img src={Countdown} alt="CountDown-img" />
                        <div className="name-merchant bar-chart inter">
                          <h5>Countdown</h5>
                          <h6>Groceries</h6>
                        </div>
                      </div>
                      <div className="date-time-blck inter">
                        <h4>19/04/2022</h4>
                        <span>|</span>
                        <h4>10: 50am</h4>
                      </div>
                      <div className="status-btn">
                        <div class="percentage bold inter">Completed</div>
                      </div>
                      <div className="transaction-amt inter">
                        <h4>-$15.00</h4>
                      </div>
                    </div>
                    <div className="sideblck">
                      <div className="merchnat-img">
                        <img src={Paksave} alt="CountDown-img" />
                        <div className="name-merchant bar-chart inter">
                          <h5>Paknsave</h5>
                          <h6>Groceries</h6>
                        </div>
                      </div>
                      <div className="date-time-blck inter">
                        <h4>19/04/2022</h4>
                        <span>|</span>
                        <h4>10: 50am</h4>
                      </div>
                      <div className="status-btn">
                        <div class="percentage bold inter">Completed</div>
                      </div>
                      <div className="transaction-amt inter">
                        <h4>-$200.00</h4>
                      </div>
                    </div>
                    <div className="sideblck">
                      <div className="merchnat-img">
                        <img src={Paksave} alt="CountDown-img" />
                        <div className="name-merchant bar-chart inter">
                          <h5>Paknsave</h5>
                          <h6>Groceries</h6>
                        </div>
                      </div>
                      <div className="date-time-blck inter">
                        <h4>19/04/2022</h4>
                        <span>|</span>
                        <h4>10: 50am</h4>
                      </div>
                      <div className="status-btn">
                        <div class="percentage bold inter">Completed</div>
                      </div>
                      <div className="transaction-amt inter">
                        <h4>-$200.00</h4>
                      </div>
                    </div>
                    <div className="sideblck">
                      <div className="merchnat-img">
                        <img src={Countdown} alt="CountDown-img" />
                        <div className="name-merchant bar-chart inter">
                          <h5>Countdown</h5>
                          <h6>Groceries</h6>
                        </div>
                      </div>
                      <div className="date-time-blck inter">
                        <h4>19/04/2022</h4>
                        <span>|</span>
                        <h4>10: 50am</h4>
                      </div>
                      <div className="status-btn">
                        <div class="percentage bold inter">Completed</div>
                      </div>
                      <div className="transaction-amt inter">
                        <h4>-$15.00</h4>
                      </div>
                    </div>
                    <div className="sideblck">
                      <div className="merchnat-img">
                        <img src={Countdown} alt="CountDown-img" />
                        <div className="name-merchant bar-chart inter">
                          <h5>Countdown</h5>
                          <h6>Groceries</h6>
                        </div>
                      </div>
                      <div className="date-time-blck inter">
                        <h4>19/04/2022</h4>
                        <span>|</span>
                        <h4>10: 50am</h4>
                      </div>
                      <div className="status-btn">
                        <div class="percentage bold inter">Completed</div>
                      </div>
                      <div className="transaction-amt inter">
                        <h4>-$15.00</h4>
                      </div>
                    </div>
                    <div className="sideblck">
                      <div className="merchnat-img">
                        <img src={Paksave} alt="CountDown-img" />
                        <div className="name-merchant bar-chart inter">
                          <h5>Paknsave</h5>
                          <h6>Groceries</h6>
                        </div>
                      </div>
                      <div className="date-time-blck inter">
                        <h4>19/04/2022</h4>
                        <span>|</span>
                        <h4>10: 50am</h4>
                      </div>
                      <div className="status-btn">
                        <div class="percentage bold inter">Completed</div>
                      </div>
                      <div className="transaction-amt inter">
                        <h4>-$200.00</h4>
                      </div>
                    </div>
                    <div className="sideblck">
                      <div className="merchnat-img">
                        <img src={Countdown} alt="CountDown-img" />
                        <div className="name-merchant bar-chart inter">
                          <h5>Countdown</h5>
                          <h6>Groceries</h6>
                        </div>
                      </div>
                      <div className="date-time-blck inter">
                        <h4>19/04/2022</h4>
                        <span>|</span>
                        <h4>10: 50am</h4>
                      </div>
                      <div className="status-btn">
                        <div class="percentage bold inter">Completed</div>
                      </div>
                      <div className="transaction-amt inter">
                        <h4 className="positive-color">+$3052.00</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Groceriesedit;
