import React from "react";
import PopupModal from "../../ModalForm/Popup/PopupModel";
import "./Moneyinsights.css";

const ExpenseHistorySection = ({
  togglePopupMonthly,
  isPopupOpenMonthly,
  selectedMonthlyOption,
  handleOptionMonthlyClick,
}) => {
  return (
    <div className="row mt-4">
      <div
        className="expense-summary-section"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <h2 style={{ margin: 0, fontSize: "22px", fontweight: "500" }}>
            Expense history
          </h2>
        </div>
        <div className="col-md-6 right-section">
          <div className="dropdown">
            <button
              className="group-button green"
              onClick={togglePopupMonthly}
              aria-haspopup="true"
              aria-expanded={isPopupOpenMonthly}
            >
              {selectedMonthlyOption}
            </button>
            {isPopupOpenMonthly && (
              <PopupModal
                isOpen={isPopupOpenMonthly}
                onClose={togglePopupMonthly}
                title="Date Range"
                options={["Last 12 months", "Last 6 months", "Last 3 months"]}
                selectedOption={selectedMonthlyOption}
                handleOptionClick={handleOptionMonthlyClick}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpenseHistorySection;
