import React from "react";
import { Popover, PopoverBody } from "reactstrap";
import { categoryColorForAll, getCustomCodeToName } from "../../../../utilities/Statics/index.jsx";

const IncomeExpenseCategories = ({
  togglePopover,
  isOpen,
  mainHead,
  handleCategoryClick,
  defaultCategory,
  yourCreatedCategory,
  handleChange,
  targetId,
  onClose,
  isHowOftenCustom,
  expenseOrIncome,
  setType,
  setChosenEmoji,
  setSelectedColorOption,
}) => {
  return (
    isOpen && (
      <Popover
        placement="bottom"
        isOpen={isOpen}
        target={targetId}
        toggle={onClose}
        className="custom-popover-w-30"
      >
        <PopoverBody>
          <div className="transaction-categories mt-2">
            <div className="bold popover-live">{mainHead} Categories</div>
            <button
              onClick={() => togglePopover()}
              className="close-pass-button"
            >
              <i className="fa fa-times"></i>
            </button>

            {expenseOrIncome === "income" && (
              <>
                {isHowOftenCustom === "Custom" ? (
                  <>
                    <div
                      className="bold popover-live mb-1 mt-2"
                      style={{ fontSize: "14px" }}
                    >
                      Your created categories
                    </div>
                    <div className="categories group-btn green-btn">
                      {yourCreatedCategory?.customCategory &&
                      yourCreatedCategory?.customCategory?.length > 0 ? (
                        yourCreatedCategory?.customCategory?.map((category) => (
                          <div
                            style={{ margin: "0px 8px 0px 0px" }}
                            key={category.categoryId}
                            className="button-blck"
                            onClick={() => {
                              setChosenEmoji(category.categoryEmoji);
                              setSelectedColorOption(
                                getCustomCodeToName(category.categoryColour)
                              );
                              return handleCategoryClick(category);
                            }}
                          >
                            <div className="icon-tag">
                              <div
                                className="icon-transaction-img"
                                style={{
                                  backgroundColor: categoryColorForAll(category.categoryColour),
                                }}
                              >
                                {category.categoryEmoji}
                              </div>
                              <div className="content">
                                <h6 className="icon-content">
                                  {category.categoryName}
                                </h6>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <p>There are no categories available.</p>
                      )}
                      <button
                        className="new-category-btn green"
                        onClick={handleChange}
                      >
                        + New category
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className="bold popover-live mt-3 mb-1"
                      style={{ fontSize: "14px" }}
                    >
                     Your created categories
                    </div>
                    <div className="categories group-btn green-btn">
                      {defaultCategory?.income &&
                      defaultCategory.income.length > 0 ? (
                        defaultCategory.income.map((category) => (
                          <div
                            style={{ margin: "0px 8px 0px 0px" }}
                            key={category.categoryId}
                            className="button-blck"
                            onClick={() => {
                              setChosenEmoji(category.categoryEmoji);
                              setSelectedColorOption(
                                getCustomCodeToName(category.categoryColour)
                              );
                              return handleCategoryClick(category);
                            }}
                          >
                            <div className="icon-tag">
                              <div
                                className="icon-transaction-img"
                                style={{
                                  backgroundColor: categoryColorForAll(category.categoryColour),
                                }}
                              >
                                {category.categoryEmoji}
                              </div>
                              <div className="content">
                                <h6 className="icon-content">
                                  {category.categoryName}
                                </h6>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <p>There are no categories available.</p>
                      )}
                        <button
                        className="new-category-btn green"
                        onClick={handleChange}
                      >
                        + New category
                      </button>
                    </div>
                  </>
                )}
              </>
            )}

            {expenseOrIncome === "expense" && (
              <>
                {isHowOftenCustom === "Custom" ? (
                  <>
                    <div>
                      <div
                        className="bold popover-live mb-1 mt-3"
                        style={{ fontSize: "14px" }}
                      >
                        Default categories
                      </div>
                      <div className="categories group-btn green-btn">
                        {yourCreatedCategory?.customCategory &&
                        yourCreatedCategory?.customCategory?.length > 0 ? (
                          yourCreatedCategory?.customCategory?.map(
                            (category) => (
                              <div
                                style={{ margin: "0px 8px 0px 0px" }}
                                key={category.categoryId}
                                className="button-blck"
                                onClick={() => {
                                  setType("");
                                  setChosenEmoji(category.categoryEmoji);
                                  setSelectedColorOption(
                                    getCustomCodeToName(category.categoryColour)
                                  );
                                  return handleCategoryClick(category);
                                }}
                              >
                                <div className="icon-tag">
                                  <div
                                    className="icon-transaction-img"
                                    style={{
                                      backgroundColor: categoryColorForAll(category.categoryColour),
                                    }}
                                  >
                                    {category.categoryEmoji}
                                  </div>
                                  <div className="content">
                                    <h6 className="icon-content">
                                      {category.categoryName}
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            )
                          )
                        ) : (
                          <p>There are no categories available.</p>
                        )}
                      </div>
                    </div>
                    <div>
                      <div
                        className="bold popover-live mb-1 mt-2"
                        style={{ fontSize: "14px" }}
                      >
                        Your created categories
                      </div>
                      <div className="categories group-btn green-btn">
                        {yourCreatedCategory?.yourCustomCategory &&
                        yourCreatedCategory?.yourCustomCategory?.length > 0 ? (
                          yourCreatedCategory?.yourCustomCategory?.map(
                            (category) => (
                              <div
                                style={{ margin: "0px 8px 0px 0px" }}
                                key={category.categoryId}
                                className="button-blck"
                                onClick={() => {
                                  setType("CUSTOM");
                                  setChosenEmoji(category.categoryEmoji);
                                  setSelectedColorOption(
                                    getCustomCodeToName(category.categoryColour)
                                  );
                                  return handleCategoryClick(category);
                                }}
                              >
                                <div className="icon-tag">
                                  <div
                                    className="icon-transaction-img"
                                    style={{
                                      backgroundColor: categoryColorForAll(category.categoryColour),
                                    }}
                                  >
                                    {category.categoryEmoji}
                                  </div>
                                  <div className="content">
                                    <h6 className="icon-content">
                                      {category.categoryName}
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            )
                          )
                        ) : (
                          <p>There are no categories available.</p>
                        )}
                        <button
                          className="new-category-btn green"
                          onClick={handleChange}
                        >
                          + New category
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div>
                      <div
                        className="bold popover-live mt-3 mb-1"
                        style={{ fontSize: "14px" }}
                      >
                        Default categories
                      </div>
                      <div className="categories group-btn green-btn">
                        {defaultCategory?.expense?.map((category) => (
                          <div
                            style={{ margin: "0px 8px 0px 0px" }}
                            key={category.categoryId}
                            className="button-blck"
                            onClick={() => {
                              setType("");
                              setChosenEmoji(category.categoryEmoji);
                              setSelectedColorOption(
                                getCustomCodeToName(category.categoryColour)
                              );
                              return handleCategoryClick(category);
                            }}
                          >
                            <div className="icon-tag">
                              <div
                                className="icon-transaction-img"
                                style={{
                                  backgroundColor: categoryColorForAll(category.categoryColour),
                                }}
                              >
                                {category.categoryEmoji}
                              </div>
                              <div className="content">
                                <h6 className="icon-content">
                                  {category.categoryName}
                                </h6>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div>
                      <div
                        className="bold popover-live mt-2 mb-1"
                        style={{ fontSize: "14px" }}
                      >
                        Your created categories
                      </div>
                      <div className="categories group-btn green-btn">
                        {defaultCategory?.yourExpense?.map((category) => (
                          <div
                            style={{ margin: "0px 8px 0px 0px" }}
                            key={category.categoryId}
                            className="button-blck"
                            onClick={() => {
                              setType("CUSTOM");
                              setChosenEmoji(category.categoryEmoji);
                              setSelectedColorOption(
                                getCustomCodeToName(category.categoryColour)
                              );
                              return handleCategoryClick(category);
                            }}
                          >
                            <div className="icon-tag">
                              <div
                                className="icon-transaction-img"
                                style={{
                                  backgroundColor: categoryColorForAll(category.categoryColour),
                                }}
                              >
                                {category.categoryEmoji}
                              </div>
                              <div className="content">
                                <h6 className="icon-content">
                                  {category.categoryName}
                                </h6>
                              </div>
                            </div>
                          </div>
                        ))}
                        <button
                          className="new-category-btn green"
                          onClick={handleChange}
                        >
                          + New category
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </PopoverBody>
      </Popover>
    )
  );
};

export default IncomeExpenseCategories;
