import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

const NotFoundPage = () => {
  const uuid = localStorage.getItem("userUuid");
  const logoRef = useRef(null);
  const formRef = useRef(null);
  const navigate = useNavigate();
  const onSubmitHandler = async () => {
    navigate(`/budget/${uuid}`);
  };
  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };
    const observerCallback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("slide-up");
          observer.unobserve(entry.target);
        }
      });
    };
    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );
    if (logoRef.current) observer.observe(logoRef.current);
    if (formRef.current) observer.observe(formRef.current);
    return () => {
      if (logoRef.current) observer.unobserve(logoRef.current);
      if (formRef.current) observer.unobserve(formRef.current);
    };
  }, []);
  return (
    <>
      <style>{`
        .slide-up {
          animation: slide-up 0.5s ease-out forwards;
        }
        @keyframes slide-up {
          0% {
            transform: translateY(80px);
            opacity: 0;
          }
          100% {
            transform: translateY(0);
            opacity: 1;
          }
        }
      `}</style>
      <div className="not-found-container">
        <section style={{ width: "100%" }} className="login-section">
          <div className="container-fluid h-custom">
            <div
              className="row d-flex justify-content-center align-items-center"
              style={{ height: "90%" }}
            >
              <div
                className="col-md-8 col-lg-6 col-xl-4"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="loginFormWrp" ref={formRef}>
                  <p className="text-center mb-1 mt-4 LoginText">Whoops 😅</p>
                  <div className="d-flex flex-row align-items-center justify-content-center mt-3">
                    <span className="notFoundContainer">
                      It looks like you’ve stumbled upon a page that doesn’t
                      exist. Simply use the button below to go back.
                    </span>
                  </div>
                  <form>
                    <div className="text-center mt-4 pt-2">
                      <button
                        type="submit"
                        className="comn-btn sign-btn"
                        style={{ marginBottom: "20px" }}
                        onClick={() => onSubmitHandler()}
                      >
                        Go back
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default NotFoundPage;
