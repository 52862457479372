import React  from "react";
// import "./index.css"
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer } from "react-toastify";
import { ReactComponent as AllSet } from "../../assests/images/icons/all-set-otp.svg";
import EmailOtp from "../../assests/images/email-gif.gif";
import OtpBox from "../../Components/pages/OtpBox";


const OtpModal = ({
  modalOpen,
  closeModal,
  handleOtpSubmit,
  submitOtp,
  modalActionCompleted,
  SetModalActionCompleted,
}) => {
  return (
    <div className={`modal-background ${modalOpen ? "open-modal" : ""} `}>
      <ToastContainer position="top-right" autoClose={5000} closeOnClick />
      <div className="modal-content delete-modal-width">
        {!modalActionCompleted && (
          <button
            onClick={() => closeModal("Otp")}
            className="close-pass-button"
          >
            <i class="fa fa-times"></i>
          </button>
        )}
        <div className="">
          {!modalActionCompleted ? (
            <>
              <div className="sucess-container transition-delay">
                <div className="success-img">
                  <div className="text-center">
                    <img src={EmailOtp} className="email-gif" alt="Email OTP" />
                  </div>
                </div>
                <div className="otp-modal-title">
                  <span className="bold black password-heading">
                    We have sent a verification code to your email
                  </span>
                </div>
                <p className="sucess-p">
                  Check your email and enter the code below to set up 2FA.
                </p>
                <OtpBox length={6} onSubmit={handleOtpSubmit} />

                <div class="modal-popup-btn-wrp">
                  <button
                    className="change-password-btn"
                    type="button"
                    onClick={submitOtp}
                  >
                    Submit verification code
                  </button>
                </div>
              </div>
            </>
          ) : (
            <div className="sucess-container transition-delay">
              <div className="fs-3">
                <span className="bold black opt-success-title">
                 All set up!
                </span>
              </div>
              <p className="sucess-p">
                2FA adds an extra wall of defence, ensuring that only you can
                access your BudgetBuddie account, even if someone gets hold of
                your password.
              </p>
              <div className="success-img">
                <AllSet />
              </div>
              <div class="modal-popup-btn-wrp">
                <button
                  className={`change-password-btn`}
                  type="button"
                  onClick={()=>closeModal("Otp")}
                >
                 Back to settings
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OtpModal;
