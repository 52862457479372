import React from "react";
import PopupModal from "../../ModalForm/Popup/PopupModel";

import "./Moneyinsights.css";
import { getDisplayOption } from "../../utilities/Statics";

const CategoryBreakdownSection = ({
  togglePopupTopExpenses,
  isPopupOpenTopExpenses,
  selectedTopExpensesOption,
  handleOptionTopExpensesClick,
  heading,
  period,
  howOften,
  isSpreadAcrossPeriods,
}) => {
  const fullOptions = [
    "Last week",
    "This week",
    "Last fortnight",
    "This fortnight",
    "Last month",
    "This month",
    "Last quarter",
    "This quarter",
    "This year",
    "Custom - last period",
    "Custom",
  ];

  let options = [];

  if (howOften !== "Custom" && (isSpreadAcrossPeriods ?? true)) {
    options = fullOptions.filter(
      (option) => option !== "Custom" && option !== "Custom - last period"
    );
  } else {
    switch (howOften) {
      case "Weekly":
        options = ["Last week", "This week"];
        break;
      case "Fortnightly":
        options = ["Last fortnight", "This fortnight"];
        break;
      case "Monthly":
        options = ["Last month", "This month"];
        break;
      case "Quarterly":
        options = ["Last quarter", "This quarter"];
        break;
      case "Annual":
        options = ["This year"];
        break;
      case "Custom":
        options = ["Custom", "Custom - last period"];
        break;
      default:
        options = [];
        break;
    }
  }

  return (
    <div className="row ">
      <div
        className="expense-summary-section"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "10px",
          marginTop: "2px",
        }}
      >
        <div>
          <h2
            style={{
              margin: 0,
              fontSize: "20px",
              fontweight: "500",
              marginBottom: "4px",
            }}
          >
            {heading}
          </h2>
        </div>
        <div className="col-md-6 right-section">
          <div className="">
            <button
              className="group-button green"
              onClick={togglePopupTopExpenses}
              aria-haspopup="true"
              aria-expanded={isPopupOpenTopExpenses}
              id="Open_Popup_Modal"
            >
              {getDisplayOption(selectedTopExpensesOption)}
            </button>
            {isPopupOpenTopExpenses && (
              <PopupModal
                targetId="Open_Popup_Modal"
                isOpen={isPopupOpenTopExpenses}
                onClose={togglePopupTopExpenses}
                title="Date Range"
                options={options}
                selectedOption={getDisplayOption(selectedTopExpensesOption)}
                handleOptionClick={handleOptionTopExpensesClick}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryBreakdownSection;
