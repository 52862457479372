import { BrowserRouter, Route, Routes } from "react-router-dom";
import "../src/assests/bootstrap/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./assests/css/style.css";
import AdminLayout from "./Layout/AdminLayout.jsx";
import Login from "./Components/pages/Login.jsx";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />}></Route>
          <Route path="*" element={<AdminLayout />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}
export default App;
