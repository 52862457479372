import React from "react";
import Amount from "../Amount.jsx/index";
import Logo from "../../assests/images/icons/Black Logo 1.png";
import { useNavigate, useParams } from "react-router-dom";
import { isEmpty } from "lodash";
import { amountMinusWithSign } from "../../utilities/helper";
export const AkahuAccountCard = ({
  avatar,
  accountName,
  value,
  accountNumber,
  accountId,
  isAkahu,
  type,
  currency,
  convertedAmount,
}) => {
  const navigate = useNavigate();
  const { uuid } = useParams();

  const handleClick = () => {
    navigate(`/accountdetails/${uuid}`, {
      state: {
        avatar,
        accountName,
        value,
        accountNumber,
        accountId,
        isAkahu,
        type,
        currency,
        convertedAmount,
      },
    });
  };
  const currencyCode = localStorage.getItem("currencyCode");
  return (
    <div
      className="merchant-box account-box"
      onClick={handleClick}
      style={{ width: "49%" }}
    >
      <div className="d-flex align-items-center" style={{ gap: "12px" }}>
        <div>
          <img
            src={!isEmpty(avatar) ? avatar : Logo}
            alt=""
            className="account-img"
          />
        </div>
        <div className="lcs-inner account-text ">
          <div className="live-connection" style={{ fontSize: "16px" }}>
            {accountName}
          </div>
          <div
            className="setting_text_color-2"
            style={{
              color: "#9B9B9B",
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: "145%",
            }}
          >
            {accountNumber}
          </div>
        </div>
      </div>
      <>
        {currencyCode !== currency ? (
          <div className="">
            <>
              <div className="account-number">
                <span className={value < 0 ? "amount-red" : "amount-green"}>
                  {type == "INVESTMENT" || type == "WALLET" || type == "FOREIGN"
                    ? `${amountMinusWithSign(
                        convertedAmount,
                        currencyCode
                      )} ${currencyCode}`
                    : amountMinusWithSign(convertedAmount, currencyCode)}
                </span>
              </div>
            </>
            <div
              className="setting_text_color-2"
              style={{
                color: "#B8B8B8",
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "145%",
              }}
            >
              {amountMinusWithSign(value, currency)} {currency}
            </div>
          </div>
        ) : (
          <>
            <div className={`account-number`}>
              <span className={value < 0 ? "amount-red" : "amount-green"}>
                {type == "INVESTMENT" || type == "WALLET" || type == "FOREIGN"
                  ? `${amountMinusWithSign(value, currency)} ${currency}`
                  : amountMinusWithSign(value, currency)}
              </span>
            </div>
          </>
        )}
      </>
    </div>
  );
};
