import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "../AddIncome/addIncome.css";
import "../../ModalForm/ChangePassword/index.css";
import "./index.css";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ReactComponent as Add_round_cross } from "../../assests/images/icons/Add_round_cross.svg";
import PopupColorModal from "../../ModalForm/Popup/PopupColorModel";
import EmojiPicker from "emoji-picker-react";
import { colorCss, colorHexMap, getCustomCodeToName, hexToColorMap, selectionColor } from "../../utilities/Statics";

const schema = yup.object().shape({
  name: yup.string().required("Name is required"),
});

const AddCategoriesModal = ({
  modalOpen,
  closeModal,
  addNewCategory,
  transactionType,
}) => {
  const [colour, setColour] = useState("Red");
  const [isPopupOpenColor, setPopupOpenColor] = useState(false);
  const [chosenEmoji, setChosenEmoji] = useState("😁");
  const [showPicker, setShowPicker] = useState(false);

  const togglePopupColor = () => setPopupOpenColor(!isPopupOpenColor);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
    },
  });

  const onSubmit = (data) => {
    const formatedData = {
      ...(transactionType === "Expense"
        ? { expenseName: data?.name }
        : { incomeName: data?.name }),
      emoji: chosenEmoji,
      colour: colour,
    };
    addNewCategory(formatedData, transactionType);
    closeModal();
  };

  // const handleOptionColorClick = (option) => {
  //   setColour(option);
  //   setPopupOpenColor(false);
  // };

  const handleOptionColorClick = (option) => {
    setColour(option);
    setPopupOpenColor(false);
  };

  const handleEmojiClick = (event) => {
    const { emoji } = event;
    setChosenEmoji(emoji);
    setShowPicker(false);
  };

  useEffect(() => {
    if (!modalOpen) {
      reset({
        name: "",
      });
    }
  }, [modalOpen, reset]);

  return (
    <div className={`modal-background ${modalOpen ? "open-modal" : ""} `}>
      <div className="modal-content add-modal-width add-modal-padding-category">

      <button className="close-button" onClick={closeModal}>
          <p style={{ width: "31.13px", height: "31.13px" }}>
            <Add_round_cross />
          </p>
        </button>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="">
            <>
              <div className="d-flex justify-content-between">
                <div className="fs-3">
                  <span className="bold black password-heading">
                    New {transactionType.toLowerCase()} category
                  </span>
                  <p className="trans-modal-para" style={{marginBottom:"20px"}}>
                    Enter your details for your new custom{" "}
                    {transactionType.toLowerCase()} category.
                  </p>
                </div>
              </div>
              <div className="cash-account-box mt-2" style={{boxShadow: "0px 5px 30px 0px rgba(0, 0, 0, 0.10)"}}>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="" style={{fontWeight:"500"}}>Name</div>
                  <div className="d-flex flex-column">
                    <div className="account-input">
                      <Controller
                        name="name"
                        control={control}
                        render={({ field }) => (
                          <input
                            type="text"
                            {...field}
                            style={{ width: "270px", padding:"0px", height:"35px", textAlign:"center" }}
                          />
                        )}
                      />
                    </div>
                    {errors.name && (
                      <span className="error-message mt-1">
                        {errors?.name?.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center mt-3 mb-3">
                  <div className=""style={{fontWeight:"500"}}>Personalised colour</div>
                  <div className="color-option">
                    <div className="dropdown">
                      <button
                        className={`group-button ${colorCss(
                          colour
                        )} popoup-color`}
                        onClick={togglePopupColor}
                        aria-haspopup="true"
                        aria-expanded={isPopupOpenColor}
                        type="button"
                        id="colorPickerButton"
                        style={{
                          color: "white",
                          backgroundColor: getCustomCodeToName(
                            colour
                          ).startsWith("#")
                            ? colour
                            : selectionColor(colour),
                            padding: "4px 32px 5px 32px",
                      fontSize: "15px",
                      fontWeight: "500",
                        }}
                      >
                        {getCustomCodeToName(colour)?.startsWith("#")
                          ? "Custom"
                          : getCustomCodeToName(colour)}
                      </button>

                      {isPopupOpenColor && (
                        <PopupColorModal
                          isOpen={isPopupOpenColor}
                          targetId="colorPickerButton"
                          onClose={togglePopupColor}
                          title="Pick your colour"
                          options={[
                            "Red",
                            "Light green",
                            "Blue",
                            "Green",
                            "Light blue",
                            "Orange",
                            "Yellow",
                            "Purple",
                            "Pink",
                          ]}
                          selectedOption={colour}
                          handleOptionClick={handleOptionColorClick}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="" style={{fontWeight:"500"}}>Personalised emoji</div>
                  <div className="d-flex flex-column">
                    <div
                      className="py-2 inter add-goal show-emoji"
                      onClick={() => setShowPicker(!showPicker)}
                      style={{ width: "125px", padding:"0px", height:"35px" }}
                    >
                      {chosenEmoji}
                    </div>
                    {showPicker && (
                      <div className="category-emoji-container">
                        <EmojiPicker onEmojiClick={handleEmojiClick} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          </div>
          <div className="modal-popup-btn-wrp">
            <button
              className="change-password-btn cursor-pointer"
              type="submit"
              style={{width:"100%", marginTop:"14px"}}
              //   onClick={submitOtp}
            >
              Add category
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddCategoriesModal;